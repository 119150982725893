// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.loading-wave {
    margin: auto;
    width: 300px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: flex-end;
  }
  
  .loading-bar {
    width: 20px;
    height: 10px;
    margin: 0 5px;
    background-color: #EA580C;
    border-radius: 5px;
    animation: loading-wave-animation 1s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  @keyframes loading-wave-animation {
    0% {
      height: 10px;
    }
  
    50% {
      height: 50px;
    }
  
    100% {
      height: 10px;
    }
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/component/style.css"],"names":[],"mappings":";AACA;IACI,YAAY;IACZ,YAAY;IACZ,aAAa;IACb,aAAa;IACb,uBAAuB;IACvB,qBAAqB;EACvB;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,aAAa;IACb,yBAAyB;IACzB,kBAAkB;IAClB,yDAAyD;EAC3D;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE,qBAAqB;EACvB;;EAEA;IACE;MACE,YAAY;IACd;;IAEA;MACE,YAAY;IACd;;IAEA;MACE,YAAY;IACd;EACF","sourcesContent":["\n.loading-wave {\n    margin: auto;\n    width: 300px;\n    height: 100px;\n    display: flex;\n    justify-content: center;\n    align-items: flex-end;\n  }\n  \n  .loading-bar {\n    width: 20px;\n    height: 10px;\n    margin: 0 5px;\n    background-color: #EA580C;\n    border-radius: 5px;\n    animation: loading-wave-animation 1s ease-in-out infinite;\n  }\n  \n  .loading-bar:nth-child(2) {\n    animation-delay: 0.1s;\n  }\n  \n  .loading-bar:nth-child(3) {\n    animation-delay: 0.2s;\n  }\n  \n  .loading-bar:nth-child(4) {\n    animation-delay: 0.3s;\n  }\n  \n  @keyframes loading-wave-animation {\n    0% {\n      height: 10px;\n    }\n  \n    50% {\n      height: 50px;\n    }\n  \n    100% {\n      height: 10px;\n    }\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
