import { useEffect, useState } from "react";
import axios from "axios";
import { An_Error, Error_specific, Success } from "../../../../../component/alert";



export default function ImportFile() {
  const [fileType, setFileType] = useState()
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [modalConfiermers, setModalConfiermers] = useState(false)
  const [uploadMessage, setUploadMessage] = useState();
  const [confirmers, setConfirmers] = useState()
  const [tempConfirmers, setTempConfirmers] = useState()
  const [listOfSelectedConfirmers, setListOfSelectedConfirmers] = useState([])
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')

  // get List of confirmers
  async function getConfirmers() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/get-confirmers`, { headers: { 'x-access-token': token } });
      const activeConfirmers = response.data.Data.filter(confirmer => confirmer.status === "Active");
      setConfirmers(activeConfirmers); setTempConfirmers(activeConfirmers);
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  useEffect(() => {
    getConfirmers()
  }, [])

  const handlerSearchConfirmers = (text) => {
    const lowercaseText = text.toLowerCase().replace(/\s/g, '');
    const filteredData = confirmers.filter((item) => {
      const firstNameMatch = item.firstName.toLowerCase();
      const lastNameMatch = item.lastName.toLowerCase();
      return (firstNameMatch + lastNameMatch).includes(lowercaseText);
    });
    setTempConfirmers(filteredData);
  }

  const UploadFile = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (!fileType) {
        e.target.value = "";
        return setUploadMessage({ type: 400, message: 'Please select a file type first.' });
      }
      if (listOfSelectedConfirmers.length < 1) {
        e.target.value = "";
        return setUploadMessage({ type: 400, message: 'Please proceed with Step 2 of the process for selecting confirmers.' });
      }
      if (!selectedFile) {
        return setUploadMessage({ type: 400, message: 'Please select a file first.' });
      }
      // Check the file extension
      const allowedExtensions = ['.xlsx', '.csv', '.json'];
      const fileExtension = selectedFile.name.slice(((selectedFile.name.lastIndexOf(".") - 1) >>> 0) + 2);

      if (!allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
        return setUploadMessage({ type: 400, message: 'Please select a valid file type (xlsx, csv, or json).' });
      }
      setSelectedFile(selectedFile)
      const formData = new FormData();
      formData.append('file', selectedFile);
      formData.append('listOfconfirmers', JSON.stringify(listOfSelectedConfirmers));
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/orders/upload/${fileType}`,
        formData,
        {
          headers: {
            'x-access-token': token
          },
          onUploadProgress: (progressEvent) => {
            const totalLength = progressEvent.lengthComputable ? progressEvent.total : selectedFile.size;
            if (totalLength) {
              const currentProgress = Math.round((progressEvent.loaded * 100) / totalLength);
              setProgress(currentProgress);
            }
          },
        }
      );
      setUploadMessage({ type: 200, message: response.data.message });
      setSelectedFile(''); setFileType('')
      e.target.value = "";
    } catch (error) {
      if (error.response?.status === 401) {
        setUploadMessage({ type: 400, message: error.response.data.message });
        setSelectedFile(''); setFileType('')
        e.target.value = "";
        setProgress(0)
      } else {
        setUploadMessage({ type: 400, message: 'An error occurred while uploading the file.' });
        setSelectedFile(''); setFileType('')
        e.target.value = "";
        setProgress(0)
      }
    }
  };

  return (
    <section class="text-gray-600 body-font mb-4" >
      <div class="container px-5 mx-auto flex flex-wrap">
        <div class="flex flex-wrap w-full">
          <div class="w-full md:pr-10 mt-5 relative">
            <div class="flex relative pb-5">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-10">
                1
              </div>
              <div class="flex-grow pl-4">
                <h2 class="text-base text-gray-900 dark:text-gray-100 font-bold flex">Select File Type</h2>
                <div class="mt-3 flex select-none flex-wrap items-center gap-3 items-center justify-center dark:text-gray-300 ">
                  <label onClick={() => setFileType('XLSX')} >
                    <p class={`rounded-lg border border-gray-700  px-6 py-2 font-bold cursor-pointer ${fileType === 'XLSX' && 'bg-black text-white'}`}>Xlsx File</p>
                  </label>
                  <label onClick={() => setFileType('CSV')}>
                    <p class={`rounded-lg border border-gray-700 px-6 py-2 font-bold cursor-pointer ${fileType === 'CSV' && 'bg-black text-white'}`}>CSV File</p>
                  </label>
                  <label onClick={() => setFileType('JSON')}>
                    <p class={`rounded-lg border border-gray-700 px-6 py-2 font-bold cursor-pointer ${fileType === 'JSON' && 'bg-black text-white'}`}>JSON File</p>
                  </label>
                </div>
              </div>
            </div>
            <div class="flex relative pb-5">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-10">
                2
              </div>
              <div class="flex-grow pl-4">
                <h2 class="text-base text-gray-900 font-bold flex dark:text-gray-100 ">Select Confirmers</h2>
                <p class="leading-relaxed mt-3 dark:text-gray-300 ">For effective oversight of file management, please <span onClick={() => setModalConfiermers(true)} className="underline cursor-pointer font-bold text-gray-800 dark:text-white"> click here </span> to either select specific order confirmers or
                  use the default option to distribute to all confirmers by <span onClick={() => {
                    Success("You have selected all Confirmers.");
                    setListOfSelectedConfirmers('all');
                  }} className="underline cursor-pointer font-bold text-gray-800 dark:text-white">clicking here.</span>
                </p>
              </div>
            </div>
            {modalConfiermers &&
              <div className="transition  absolute z-30 top-[42%] md:top-[18%] md:left-[38%]  duration-150 ease-in-out  w-max">
                <div className="w-full bg-white rounded shadow-2xl">
                  <div className="relative bg-gray-200 rounded-t py-4 px-6">
                    <svg className="hidden md:block absolute -ml-5 -mb-10 left-0 bottom-0" width="30px" height="30px" viewBox="0 0 9 16" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
                      <g id="Page-1" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
                        <g id="Tooltips-" transform="translate(-874.000000, -1029.000000)" fill="#FFFFFF">
                          <g id="Group-3-Copy-16" transform="translate(850.000000, 975.000000)">
                            <g id="Group-2" transform="translate(24.000000, 0.000000)">
                              <polygon id="Triangle" transform="translate(4.500000, 62.000000) rotate(-90.000000) translate(-4.500000, -62.000000) " points="4.5 57.5 12.5 66.5 -3.5 66.5" />
                            </g>
                          </g>
                        </g>
                      </g>
                    </svg>
                    <svg className="absolute top-0 -mt-5 ml-20 md:ml-0 block md:hidden" xmlns="http://www.w3.org/2000/svg" width={26} height={23} viewBox="0 0 26 23" fill="none">
                      <path id="Polygon 2" d="M13 0L25.9904 22.5H0.00961876L13 0Z" fill="#e5e7eb" />
                    </svg>
                    <input onChange={(e) => handlerSearchConfirmers(e.target.value)} className="px-7 w-3/4 py-2 bg-gray-100 text-base placeholder-gray-700 text-gray-900 font-normal leading-normal tracking-normal opacity-50" placeholder="Full Name...." />
                    <svg onClick={() => setModalConfiermers(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6 text-gray-500 cursor-pointer inline-flex float-right mt-2 hover:scale-125">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                    </svg>

                  </div>
                  <div className="w-full px-4 xl:px-8 pt-3 pb-5 max-h-[350px] overflow-auto">
                    {confirmers.length > 0 ? tempConfirmers.map(item => {
                      return (
                        <div onClick={() => {
                          if (listOfSelectedConfirmers.includes(item._id)) {
                            // Item is already in the list, so remove it
                            const updatedList = listOfSelectedConfirmers.filter(id => id !== item._id);
                            setListOfSelectedConfirmers(updatedList);
                          } else {
                            // Item is not in the list, so add it
                            if (listOfSelectedConfirmers.includes('all')) {
                              setListOfSelectedConfirmers([]);
                            }
                            setListOfSelectedConfirmers(prevList => [...prevList, item._id]);
                          }
                        }}
                          key={item._id} className={`flex justify-between mb-1 items-center p-2 cursor-pointer hover:bg-blue-100 ${listOfSelectedConfirmers.includes(item._id) || listOfSelectedConfirmers === 'all' ? 'bg-blue-100 border-l-4 border-blue-700' : ''}`}>
                          <div className="flex items-center">
                            <div className="mr-4 w-12 h-12 rounded shadow">
                              <img loading="lazy" className="w-full h-full overflow-hidden object-cover object-center rounded"
                                src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                                alt="avatar" />
                            </div>
                            <div>
                              <h3 className="mb-2 sm:mb-1 text-gray-800 text-base font-normal leading-4">{item.firstName} {item.lastName}</h3>
                              <p className="text-gray-600 text-xs leading-3">
                                Confirmer
                              </p>
                            </div>
                          </div>
                        </div>
                      );
                    })
                      : <h1 className="text-gray-900 opacity-50">Before importing the file, please ensure that there are active confirmers available, as currently, there are no confirmers with an 'Active' status.</h1>
                    }




                  </div>
                </div>
              </div>
            }
            <div class="flex relative pb-5">
              <div class="h-full w-10 absolute inset-0 flex items-center justify-center">
                <div class="h-full w-1 bg-gray-200 pointer-events-none"></div>
              </div>
              <div class="flex-shrink-0 w-10 h-10 rounded-full bg-orange-500 inline-flex items-center justify-center text-white relative z-10">
                3
              </div>
              <div class="flex-grow pl-4">
                <h2 class="text-base text-gray-900 font-bold flex dark:text-gray-100 ">Upload File</h2>
                {progress > 0 &&
                  <div className="mt-2">
                    <span className="text-gray-600">{uploadMessage && uploadMessage.type === 200 ? selectedFile.name : 'Downloading...'}</span>
                    <div className="h-2 overflow-hidden rounded-full bg-gray-300">
                      <div className="h-full bg-blue-500" style={{ width: `${progress}%` }} />
                    </div>
                  </div>}
                <div className="flex flex-col mt-4 items-center justify-center rounded-lg border-4 border-dashed px-4 py-10">
                  <svg className="h-14 -rotate-90" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                    <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="326.4185" y1="382.5204" x2="326.4185" y2="128.8734" className="gradient-element">
                      <stop offset={0} className="primary-color-gradient" style={{ stopColor: '#ABDCFF' }} />
                      <stop offset={1} className="secondary-color-gradient" style={{ stopColor: '#0396FF' }} />
                    </linearGradient>
                    <path fill="url(#SVGID_1_)" d="M466.1,249.8l-122-122c-4.6-4.6-12.4-1.3-12.4,5.1v95.2c0,29.3-23.8,53.1-53.1,53.1H193	c-4.9,0-8.8,4-8.8,8.8v16.8c0,4.8,3.9,8.7,8.7,8.7h91.7c4.8,0,8.7,3.9,8.7,8.7v89.6c0,7.8,9.4,11.6,14.9,6.2l157.9-157.9	C469.5,258.8,469.5,253.2,466.1,249.8z" />
                    <path d="M302,430.6c-2.2,0-4.3-0.4-6.4-1.3c-6.3-2.6-10.3-8.7-10.3-15.4v-89.6c0-0.4-0.3-0.7-0.7-0.7H123.1	c-9.2,0-16.7-7.5-16.7-16.7v-25.7c0-4.4,3.6-8,8-8s8,3.6,8,8v25.7c0,0.4,0.3,0.7,0.7,0.7h161.5c9.2,0,16.7,7.5,16.7,16.7v89.6	c0,0.2,0,0.5,0.4,0.7c0.4,0.2,0.6,0,0.8-0.2l157.9-157.9c0.3-0.3,0.3-0.7,0-1L302.5,97.6c-0.1-0.1-0.3-0.3-0.8-0.2	c-0.4,0.2-0.4,0.5-0.4,0.7v89.6c0,9.2-7.5,16.7-16.7,16.7h-197c-4.4,0-8-3.6-8-8s3.6-8,8-8h197c0.4,0,0.7-0.3,0.7-0.7V98.1	c0-6.8,4-12.8,10.3-15.4c6.3-2.6,13.4-1.2,18.2,3.6l157.9,157.9c6.5,6.5,6.5,17.1,0,23.6L313.8,425.7	C310.6,428.9,306.4,430.6,302,430.6z" />
                    <path fill="#0396FF" className="secondary-color" d="M29.6,128.1V25.5h21.8v102.6H29.6z M51.4,249V146.4H29.6V249H51.4z" />
                    <path fill="#ABDCFF" className="primary-color" d="M484.2,374.2v102.6h-91.4L484.2,374.2z" />
                    <path d="M35.4,281.3c0-4.4,3.6-8,8-8h112.8c4.4,0,8,3.6,8,8s-3.6,8-8,8H43.4C38.9,289.3,35.4,285.7,35.4,281.3z M235.4,372.2	c4.4,0,8-3.6,8-8s-3.6-8-8-8H43.4c-4.4,0-8,3.6-8,8s3.6,8,8,8H235.4z M417.3,45.3c-4.4,0-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8	c-4.4,0-8,3.6-8,8s3.6,8,8,8c20.3,0,36.8-16.5,36.8-36.8C425.3,48.8,421.7,45.3,417.3,45.3z M467.3,90c4.4,0,8-3.6,8-8s-3.6-8-8-8	c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8C430.5,73.5,447,90,467.3,90z M467.3,95.3c-20.3,0-36.8,16.5-36.8,36.8	c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S471.7,95.3,467.3,95.3z M388.5,95.3c-4.4,0-8,3.6-8,8	s3.6,8,8,8c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8s8-3.6,8-8C425.3,111.7,408.8,95.3,388.5,95.3z M132.4,105.4c0,4.4,3.6,8,8,8	h112.8c4.4,0,8-3.6,8-8s-3.6-8-8-8H140.4C136,97.4,132.4,101,132.4,105.4z M261.2,154c0,5-4.1,9-9,9s-9-4.1-9-9s4.1-9,9-9	S261.2,149.1,261.2,154z M222.2,145c5,0,9,4.1,9,9s-4.1,9-9,9s-9-4.1-9-9S217.2,145,222.2,145z M192.2,145c5,0,9,4.1,9,9s-4.1,9-9,9	s-9-4.1-9-9S187.2,145,192.2,145z M95,452.9c0,14.6-11.8,26.4-26.4,26.4s-26.4-11.8-26.4-26.4s11.8-26.4,26.4-26.4	S95,438.3,95,452.9z M79,452.9c0-5.7-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4s4.7,10.4,10.4,10.4S79,458.6,79,452.9z M353.7,459.9	H240.8c-4.4,0-8,3.6-8,8s3.6,8,8,8h112.8c4.4,0,8-3.6,8-8S358.1,459.9,353.7,459.9z M92.7,52.1h112.8c4.4,0,8-3.6,8-8s-3.6-8-8-8	H92.7c-4.4,0-8,3.6-8,8S88.2,52.1,92.7,52.1z" />
                  </svg>
                  <p className="mt-4 text-center text-xl font-medium text-gray-800 dark:text-gray-200 ">
                    Drop Files here or
                    <label className="shadow-orange-100 mt-2 block rounded-full border bg-white px-4 py-0.5 font-normal text-gray-500 shadow hover:bg-blue-50">
                      <input onChange={(e) => UploadFile(e)} className="hidden" type="file" name="file" id="fileInput" />
                      browse</label>
                  </p>
                </div>
                {uploadMessage && <p class={`mt-2 text-lg  ${uploadMessage.type === 200 ? 'text-green-600 dark:text-green-500' : 'text-red-600 dark:text-red-500'}`}>
                  {uploadMessage.message}</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}