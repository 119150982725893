import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { An_Error, Error_specific, Success } from "../../../../component/alert";
import { v4 } from 'uuid'
import { DotSpinner } from '@uiball/loaders'

export default function Users() {
  const [users, setUsers] = useState([])
  const [tempData, setTempData] = useState([])
  const [loading, setLoading] = useState(true)
  const [ModalAddNew, setModalAddNew] = useState(false)
  const [ModalDelete, setModalDelete] = useState(false)
  const [ModalEdit, setModalEdit] = useState(false)
  const [LoadingBtn, setLoadingBtn] = useState(false)
  const [data, setData] = useState({ password: "" })
  const [togglePass, settogglePass] = useState(false)
  const [tabs, setTabs] = useState('confirmer')
  const ref = useRef(null)
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')
  const role = localStorage.getItem('role') || sessionStorage.getItem('role')


  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setModalAddNew(false); setModalEdit(false); setModalDelete(false)
    }
  };
  document.addEventListener('mousedown', handleClickOutside);


  function handlerInputsChange(e) {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const [selectedImage, setSelectedImage] = useState(null);

  function handleImageChange(e) {
    const file = e.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imageUrl = e.target.result;
      document.querySelector('.profile-image').src = imageUrl;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  async function HandlerAddNew() {
    const allInputs = document.querySelectorAll('.inputs');
    const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
    if (!allNotEmpty) {
      return Error_specific('All fields are required.');
    }
    setLoadingBtn(true)
    try {
      const formData = new FormData();
      formData.append('image', selectedImage);
      for (const key in data) {
        if (data.hasOwnProperty(key)) {
          formData.append(key, data[key]);
        }
      }
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/save-user/${tabs}`, formData, { headers: { 'x-access-token': token } });
      Success(response.data.message); setLoadingBtn(false); setModalAddNew(false); getData(); setSelectedImage()
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message); setLoadingBtn(false);
      } else {
        An_Error(); setLoadingBtn(false);
      }
    }
  }

  async function getData() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/get-users/${tabs}`, { headers: { 'x-access-token': token } });
      setUsers(response.data.Data); setTempData(response.data.Data); setLoading(false)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  useEffect(() => {
    getData()
  }, [tabs])

  const Delete = async (e) => {
    setLoadingBtn(true)
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/users/delete-user/${ModalDelete}`, { headers: { 'x-access-token': token } });
      Success(response.data.message); setLoadingBtn(false); setModalDelete(false); getData()
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  const [dataUpdate, setDataUpdate] = useState({});
  function Edit(id) {
    const data = users.find(item => item._id === id);
    const { _id, pending_or_rejected_Status, confirmed_Status, nbr_orders, status, password, ...rest } = data;
    setDataUpdate(rest);
    setModalEdit(id);
  }
  function handlerInputUpdateChange(e) {
    setDataUpdate({ ...dataUpdate, [e.target.name]: e.target.value })
  }

  const UpdateUser = async (e) => {
    const allInputs = document.querySelectorAll('.inputsUpdate');
    const allNotEmpty = Array.from(allInputs).every((input) => input.value.trim() !== '');
    if (!allNotEmpty) {
      return Error_specific('All fields are required.');
    }
    setLoadingBtn(true)
    try {
      const formData = new FormData();
      if (selectedImage) {
        formData.append('image', selectedImage);
      }
      for (const key in dataUpdate) {
        if (dataUpdate.hasOwnProperty(key) && key !== 'companyName' && key !== 'image') {
          formData.append(key, dataUpdate[key]);
        }
      }
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/users/update-user/${ModalEdit}`, formData, { headers: { 'x-access-token': token } });
      Success(response.data.message); setLoadingBtn(false); setModalEdit(false); getData(); setSelectedImage()
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message); setLoadingBtn(false)
      } else {
        An_Error();setLoadingBtn(false)
      }
    }
  }

  const handlerFilterSearch = (text) => {
    const lowercaseText = text.toLowerCase().replace(/\s/g, '');
    let filteredData = users.filter((item) =>
    (
      (item['firstName']?.toLowerCase() + item['lastName']?.toLowerCase()).includes(lowercaseText) ||
      item['email']?.toLowerCase()?.includes(lowercaseText)
    )
    );
    setTempData([...filteredData]);
  }

  const UpdateStatus = async (id, status) => {
    try {
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/users/update-status/${id}`, { status }, { headers: { 'x-access-token': token } });
      response?.status === 200 ? Success(response.data.message) : Error_specific(response.data.message)
      getData()
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  return (
    <main class="h-max pb-16 mb-4 ml-5 mr-5 mt-4">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ duration: 0.3 }}
      >
        <div className="">
          <h2 className="font-bold text-2xl mb-4 text-gray-800 dark:text-gray-200 ml-2">Users</h2>
          <div class="bg-white pb-2 rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
            <div class="py-2 px-3">
              <nav class="flex flex-wrap gap-4  text-gray-600 dark:text-gray-200">
                <div onClick={() => { setTabs('confirmer') }} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${tabs === "confirmer" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'} `}> Confirmer Users </div>
                {role === "Super_Admin" && <div onClick={() => { setTabs('admin') }} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${tabs === "admin" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'}  `}> Admin Users </div>}
                <button onClick={() => setModalAddNew(true)} type="button" class="relative mr-auto uppercase mr-2 inline-flex  cursor-pointer items-center rounded-full border border-gray-200 bg-transparent px-5 py-2 text-center text-sm font-medium text-gray-800 dark:text-gray-200 hover:scale-95  focus:shadow  lg:ml-0 ">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="mr-2 h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  ADD New {tabs}
                </button>
              </nav>
            </div>
            <div class="flex w-full flex-row items-center justify-between space-y-2 sm:flex-row sm:space-y-0">
              <div class="relative flex flex-col md:flex-row w-full ml-4 max-w-2xl items-center">
                <svg class="absolute left-2 block h-5 w-5 mt-3 md:mt-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                  <circle cx="11" cy="11" r="8" class=""></circle>
                  <line x1="21" y1="21" x2="16.65" y2="16.65" class=""></line>
                </svg>
                <input type="name" name="search" onChange={(e) => handlerFilterSearch(e.target.value)} class="h-12 w-full dark:text-white  border-b-gray-400 bg-transparent py-4 pl-12 text-sm outline-none focus:border-b-2" placeholder={`Search by full Name, email`} />
              </div>
            </div>
          </div>

          <div className="p-6 mt-5 bg-white rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
            <div className="overflow-x-auto ">
              {loading ? <div className="h-[calc(70vh_-_10.75rem)] px-4  overflow-y-auto  flex justify-center items-center">
                <DotSpinner
                  size={80}
                  speed={0.9}
                  color="gray"
                />            </div> :
                tempData.length > 0 ?
                  <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                    <thead className="font-bold  border-b border-gray-200 dark:border-gray-700 lg:table-header-group">
                      <motion.tr initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.5, delay: 0.1 }} className="text-gray-600 dark:text-gray-100">
                        <th className={`whitespace-normal py-4 text-sm px-3 text-left`} >
                          User
                        </th>
                        <th className={`whitespace-normal py-4 text-sm px-3 text-left`} >
                          Phone Number
                        </th>
                        {tabs === 'confirmer' &&
                          <>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                              Total Orders
                            </th>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                              Processing/Rejected Requests
                            </th>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                              Confirmed Requests
                            </th>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                            pay Type
                            </th>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                            Pay Amount
                            </th>
                            <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                              Status
                            </th>
                          </>
                        }
                        <th className=""></th>
                      </motion.tr>
                    </thead>
                    <tbody class="lg:border-gray-300 ">
                      {tempData.map((item, index) => {
                        return (
                          <motion.tr initial={{ opacity: 0, x: -50 }}
                            animate={{ opacity: 1, x: 0 }}
                            exit={{ opacity: 0, x: -50 }}
                            transition={{ duration: 0.5, delay: index * 0.3 }} class="w-full" key={index}>
                            <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 sm:px-3">
                              <div class="flex items-center">
                                <img loading="lazy" class="mr-2 h-10 w-10 rounded-full object-cover" src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                  onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                                  alt="profile" />
                                <div className="">
                                  <h4 class="text-base font-meduim text-gray-900  dark:text-gray-100">{item.firstName}&nbsp;{item.lastName}</h4>
                                  <span class="block text-xs font-normal text-gray-500  dark:text-gray-400">{item.email}</span>
                                </div>
                              </div>
                            </td>
                            <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item.phone}</td>
                            {tabs === 'confirmer' &&
                              <>
                                <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item?.nbr_orders || 0}</td>
                                <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item?.pending_or_rejected_Status || 0}</td>
                                <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item?.confirmed_Status || 0}</td>
                                <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item?.payType || ''}</td>
                                <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3 ">{item?.payAmount || ''}</td>

                                <td onClick={() => UpdateStatus(item._id, item.status)} class="whitespace-no-wrap text-sm font-normal text-gray-500 sm:px-3 cursor-pointer">
                                  <span class={`py-2 px-6 text-sm focus:outline-none leading-none ${item.status === 'Active' ? 'bg-green-100 text-green-700' : 'bg-red-100 text-red-700'} rounded-md`}>{item.status}</span>
                                </td>
                              </>
                            }
                            <td className='flex py-4 px-9 gap-4 float-right'>
                              <button onClick={() => Edit(item._id)} class="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 dark:bg-gray-900 dark:text-gray-100  rounded hover:bg-gray-200 focus:outline-none">Edit</button>
                              <button onClick={() => setModalDelete(item._id)} class="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 dark:bg-gray-900 dark:text-gray-100  rounded hover:bg-gray-200 focus:outline-none">Delete</button>
                            </td>
                          </motion.tr>
                        )
                      })}


                    </tbody>
                  </table>
                  :
                  <div className="py-20 animate-pulse flex flex-col items-center justify-center text-gray-400 dark:text-gray-700">
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-44 h-44" fill="currentColor" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 406.8"><path d="M29.27 0h373.81c16.11 0 29.27 13.16 29.27 29.27v90.8c-2.23-1.1-4.48-2.14-6.77-3.13l-2.02-.92c-3.57-1.47-7.18-2.82-10.84-4.02V81.94h.14H20.83v221.61c0 4.5 3.59 8.09 8.09 8.09h193.04c.54 1.42 1.1 2.83 1.69 4.24 1.97 4.78 4.23 9.49 6.73 14.09H29.27C13.16 329.97 0 316.78 0 300.7V29.27C0 13.16 13.16 0 29.27 0zm335.71 140.37c31.07 0 60.75 12.29 82.72 34.27 30.33 30.33 41.62 75.06 29.4 116.14-2.83 9.53-6.85 18.55-11.88 26.89l45.3 49.38c2.09 2.27 1.95 5.82-.33 7.91l-33.26 30.38c-2.27 2.08-5.8 1.92-7.89-.35l-43.34-47.67c-18.35 11.18-39.22 17.02-60.72 17.02-31.06 0-60.75-12.3-82.71-34.27a117.18 117.18 0 0 1-25.39-37.99c-18.13-43.67-7.82-94.22 25.4-127.43a116.814 116.814 0 0 1 37.96-25.4c13.8-5.73 28.92-8.88 44.74-8.88zm70.93 46.04c-28.63-28.63-72.03-37.24-109.31-21.8-66.92 27.71-82.3 113.99-32.58 163.67 28.68 28.61 72 37.26 109.33 21.81 37.54-15.59 61.94-52.1 61.94-92.74 0-13.62-2.69-26.59-7.56-38.37a101.013 101.013 0 0 0-21.82-32.57zm-28.22 97.67-16.4 16.31-26.48-26.49-26.34 26.34-15.82-15.86 26.32-26.32-26.85-26.84 16.4-16.31 26.8 26.81 26.51-26.51 15.81 15.85-26.49 26.49 26.54 26.53zM375.21 32.9c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.98 0-14.46-6.48-14.46-14.46.04-7.99 6.51-14.46 14.46-14.46zm-97.95 0c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.99 0-14.46-6.48-14.46-14.46 0-7.99 6.47-14.46 14.46-14.46zm48.98 0c7.98 0 14.45 6.47 14.45 14.46 0 7.98-6.47 14.46-14.45 14.46-7.99 0-14.47-6.48-14.47-14.46 0-7.99 6.48-14.46 14.47-14.46z" /></svg>
                    <p className="font-normal text-3xl mt-4 text-center">Sad no result!</p>
                    <p className="font-normal text-xl text-center">Currently, there are no users available for display...</p>
                  </div>
              }



            </div>

          </div>


        </div>

        <AnimatePresence>

          {ModalAddNew &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ x: 400, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 400, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeOut" }} class="fixed right-0 top-0 z-30 h-screen w-[100%] md:w-1/4  max-w-full bg-white dark:bg-gray-900 shadow-xl transition-all">
                <div ref={ref} class="w-full h-screen overflow-y-auto">
                  <div class="modal-header p-5 bg-orange-600 text-gray-900">
                    <h5 class="text-white text-2xl uppercase">Add New {tabs}</h5>
                  </div>

                  <div className="p-4 mt-3">
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Profile Photo
                    </label>
                    <div class="shrink-0">
                      <img class="h-24 w-24 profile-image mx-auto object-cover border-4 border-gray-700 rounded-full" loading="lazy" src="https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg" alt="Current profile photo" />
                    </div>

                    <label class="block mt-3 mb-3">
                      <input onChange={(e) => handleImageChange(e)} accept="image/*" class="block w-full w-full px-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" id="file_input" type="file" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      First Name<span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="text" name="firstName" onChange={(e) => handlerInputsChange(e)} placeholder="test" class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Last Name<span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="text" name="lastName" onChange={(e) => handlerInputsChange(e)} placeholder="test" class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Phone Number<span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="text" name="phone" onChange={(e) => handlerInputsChange(e)} placeholder="+1 (000) 000-0000" class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Email <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="email" name="email" onChange={(e) => handlerInputsChange(e)} placeholder="test@gmail.com" class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Password <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <div class="relative mt-3 ">
                      <input name="password" type={togglePass ? "text" : "password"} onChange={(e) => handlerInputsChange(e)} value={data.password} placeholder="****************" class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-t-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                      <div class="absolute inset-y-0 right-0 z-20 flex items-center pr-4 text-gray-600 dark:text-gray-200">
                        {!togglePass ? <svg onClick={() => settogglePass(true)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M2.036 12.322a1.012 1.012 0 010-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178z" />
                          <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
                        </svg> :
                          <svg onClick={() => settogglePass(false)} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-4 h-4 cursor-pointer">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M3.98 8.223A10.477 10.477 0 001.934 12C3.226 16.338 7.244 19.5 12 19.5c.993 0 1.953-.138 2.863-.395M6.228 6.228A10.45 10.45 0 0112 4.5c4.756 0 8.773 3.162 10.065 7.498a10.523 10.523 0 01-4.293 5.774M6.228 6.228L3 3m3.228 3.228l3.65 3.65m7.894 7.894L21 21m-3.228-3.228l-3.65-3.65m0 0a3 3 0 10-4.243-4.243m4.242 4.242L9.88 9.88" />
                          </svg>
                        }
                      </div>
                    </div>
                    <button onClick={() => setData({ ...data, password: v4() })} class="bg-orange-500 w-full text-white active:bg-orange-600 font-bold uppercase text-xs py-1 px-4  rounded-b-xl shadow hover:shadow-md outline-none focus:outline-none  ease-linear transition-all duration-150" type="button">
                      <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 12c0-1.232-.046-2.453-.138-3.662a4.006 4.006 0 00-3.7-3.7 48.678 48.678 0 00-7.324 0 4.006 4.006 0 00-3.7 3.7c-.017.22-.032.441-.046.662M19.5 12l3-3m-3 3l-3-3m-12 3c0 1.232.046 2.453.138 3.662a4.006 4.006 0 003.7 3.7 48.656 48.656 0 007.324 0 4.006 4.006 0 003.7-3.7c.017-.22.032-.441.046-.662M4.5 12l3 3m-3-3l-3 3" />
                      </svg>
                      Generate Password
                    </button>
                    {tabs === 'confirmer' && <div className="mt-3">
                      <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                        PayType <span className="text-red-700  font-bold">*</span>
                      </label>
                      <label class="block mt-3 mb-3">
                        <select name="payType" onChange={(e) => handlerInputsChange(e)} class="w-full rounded-xl inputs dark:bg-gray-900 border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-[1px] focus:border-orange-500 focus:ring-orange-500 dark:text-gray-100">
                          <option value="" disabled selected>Select type</option>
                          <option value="salary" >salary pay</option>
                          <option value="commission" >commission pay</option>
                        </select>
                      </label>
                      <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Salary / Commission <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3">
                      <input type="number" name="payAmount" onChange={(e) => handlerInputsChange(e)} placeholder="20.25 , 2500..." class="block inputs w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    </div>}
                  </div>
                  <div class="py-6 px-4 flex flex-col items-center gap-3">
                    <button type="button" onClick={() => setModalAddNew(false)} class="w-full px-4 py-2.5 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-lg sm:w-1/2  dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">
                      Cancel
                    </button>

                    <button onClick={() => !LoadingBtn && HandlerAddNew()} class="w-full px-4 py-2.5  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-600 rounded-lg sm:mt-0 sm:w-1/2 hover:bg-orange-500 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 ">
                      {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                      </div> : "Save"}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          }
          {ModalDelete &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">

                <div ref={ref} class="m-10 flex max-w-lg flex-col items-center border px-8 py-10 text-gray-800 sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 sm:max-w-lg ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 rounded-xl bg-red-50 p-2 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  <p class="mt-4 text-center text-xl font-bold dark:text-gray-200">Deleting User(Confirmer)</p>
                  <p class="mt-2 text-center text-lg dark:text-gray-400">Are you sure you want to delete the User with id  <span class="truncate font-bold">`{ModalDelete}`</span>?</p>
                  <div class="mt-8 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                    <button class="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white" onClick={() => Delete()}>
                      {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                      </div> : "Yes, delete this User"}
                    </button>
                    {!LoadingBtn && <button class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium" onClick={() => setModalDelete(false)}>Cancel, keep this User</button>}
                  </div>
                </div>

              </motion.div>
            </motion.div>
          }
          {ModalEdit &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ x: 400, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 400, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeOut" }} class="fixed right-0 top-0 z-30 h-screen w-[100%] md:w-1/4  max-w-full bg-white dark:bg-gray-900 shadow-xl transition-all">
                <div ref={ref} class="w-full h-screen overflow-y-auto">
                  <div class="modal-header p-5 bg-gray-900 text-gray-900">
                    <h5 class="text-white text-2xl uppercase">Update Confirmer Information</h5>
                  </div>

                  <div className="p-4 mt-3">
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Profile Photo
                    </label>
                    <div class="shrink-0">
                      <img loading="lazy" class="h-24 w-24 profile-image mx-auto object-cover border-4 border-gray-700 rounded-full"
                        src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${dataUpdate.companyName.replace(/\s/g, '_')}/${dataUpdate.image}`}
                        onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                        alt="Current profile photo" />
                    </div>

                    <label class="block mt-3 mb-3">
                      <input onChange={(e) => handleImageChange(e)} accept="image/*" class="block w-full w-full px-4 text-sm text-gray-700 bg-white border border-gray-300 rounded-md focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" id="file_input" type="file" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      First Name <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="text" name="firstName" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.firstName} class="block inputsUpdate w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Last Name <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="text" name="lastName" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.lastName} class="block inputsUpdate w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Email <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3 mb-3">
                      <input type="email" name="email" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.email} class="block inputsUpdate w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Phone Number <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3">
                      <input type="email" name="phone" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.phone} class="block inputsUpdate w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-200 rounded-md focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    {tabs === 'confirmer' && <div className="mt-3">
                      <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                        PayType <span className="text-red-700  font-bold">*</span>
                      </label>
                      <label class="block mt-3 mb-3">
                        <select name="payType" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.payType} class="w-full rounded-xl inputsUpdate dark:bg-gray-900 border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm outline-none focus:z-10 focus:border-[1px] focus:border-orange-500 focus:ring-orange-500 dark:text-gray-100">
                          <option value="salary" >salary pay</option>
                          <option value="commission" >commission pay</option>
                        </select>
                      </label>
                      <label class="text-base font-medium text-gray-700 dark:text-gray-200">
                      Salary / Commission <span className="text-red-700  font-bold"> *</span>
                    </label>
                    <label class="block mt-3">
                      <input type="number" name="payAmount" onChange={(e) => handlerInputUpdateChange(e)} value={dataUpdate.payAmount} class="block inputsUpdate w-full px-4 py-3 text-sm text-gray-700 bg-white border border-gray-300 rounded-xl focus:border-orange-400 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 dark:border-gray-600 dark:bg-gray-900 dark:text-gray-300 dark:focus:border-orange-300" />
                    </label>
                    </div>}
                  </div>
                  <div class="py-6 px-4 flex flex-col items-center gap-3">
                    <button type="button" onClick={() => setModalEdit(false)} class="w-full px-4 py-3 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">
                      Cancel
                    </button>

                    <button onClick={() => !LoadingBtn && UpdateUser()} class="w-full px-4 py-3  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-600 rounded-md sm:mt-0 sm:w-1/2 hover:bg-orange-500 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40 ">
                      {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                      </div> : "Save"}
                    </button>
                  </div>
                </div>
              </motion.div>
            </motion.div>
          }
        </AnimatePresence>
      </motion.div>
    </main>
  )
}