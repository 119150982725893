import React, { useEffect, useState } from 'react'
import { getSecureLocalStorage } from './functions/getSecureLocal';
import { setSecureLocalStorage } from './functions/setSecureLocal';
import { An_Error, Error_specific } from '../../component/alert';
import axios from 'axios';
import Access from './component/Access';
import Information from './component/Information';
import Login from './component/Login';
import DataControl from './component/DataControl';
import UsageKeys from './component/UsageKeys';
import GoogleSheets from './component/GoogleSheets';
import Status from './component/Status';

const item = `
<li class="flex mr-8 ">
             <button
               class="rounded-md focus:outline-none focus:shadow-outline-blue"
               @click="toggleTheme"
               aria-label="Toggle color mode"
             >
               <template x-if="!dark">
                 <svg
                   class="w-8 h-8"
                   aria-hidden="true"
                   fill="currentColor"
                   viewBox="0 0 20 20"
                 >
                   <path
                     d="M17.293 13.293A8 8 0 016.707 2.707a8.001 8.001 0 1010.586 10.586z"
                   ></path>
                 </svg>
               </template>
               <template x-if="dark">
                 <svg
                   class="w-8 h-8 text-white"
                   aria-hidden="true"
                   fill="currentColor"
                   viewBox="0 0 20 20"
                 >
                   <path
                     fill-rule="evenodd"
                     d="M10 2a1 1 0 011 1v1a1 1 0 11-2 0V3a1 1 0 011-1zm4 8a4 4 0 11-8 0 4 4 0 018 0zm-.464 4.95l.707.707a1 1 0 001.414-1.414l-.707-.707a1 1 0 00-1.414 1.414zm2.12-10.607a1 1 0 010 1.414l-.706.707a1 1 0 11-1.414-1.414l.707-.707a1 1 0 011.414 0zM17 11a1 1 0 100-2h-1a1 1 0 100 2h1zm-7 4a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM5.05 6.464A1 1 0 106.465 5.05l-.708-.707a1 1 0 00-1.414 1.414l.707.707zm1.414 8.486l-.707.707a1 1 0 01-1.414-1.414l.707-.707a1 1 0 011.414 1.414zM4 11a1 1 0 100-2H3a1 1 0 000 2h1z"
                     clip-rule="evenodd"
                   ></path>
                 </svg>
               </template>
             </button>
           </li>
`

export default function Setup() {
    const [sheetAction_type, setSheetAction_type] = useState("GET")
    const defaultPages = {
        accessKey: null,
        activeTab : 1
    }

    // Initialize the state with an initial value or fetch from localStorage
    const initialPagesState = getSecureLocalStorage('pagesState', process.env.REACT_APP_SECRET_KEY) || defaultPages;
    const [pages, setPages] = useState(initialPagesState);

    // Save state to localStorage whenever it changes
    useEffect(() => {
        setSecureLocalStorage('pagesState', pages, process.env.REACT_APP_SECRET_KEY);
    }, [pages]);

    //function accsess to setup
    async function CheckAccses(code, type) {
        try {
            await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/check-access-token/${type}`, {
                headers: {
                    'accessKey': code,
                }
            });
            return true
        } catch (error) {
            if (error.response?.status === 429) {
                Error_specific(`Rate limit exceeded: Too many requests from this IP, please try again later.`)
            }
            else if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
                setPages(defaultPages);
            } else {
                An_Error();
            }
            return false
        }
    }

    useEffect(() => {
        if (pages?.activeTab !== 1) {
            CheckAccses(pages?.accessKey, "check")
        }
    }, [])

    return (
        <section className="flex flex-col md:flex-row bg-gray-100 dark:bg-gray-800">
            <div dangerouslySetInnerHTML={{ __html: item }} className="absolute top-6 right-0 md:top-5 md:right-5 z-50" />

            <div className="flex flex-col h-max md:h-screen md:w-1/6 py-5 md:py-10 px-6 bg-black opacity-90 md:items-center justify-center sticky md:top-0 md:buttom-0" >
                <img src='/images/selezy logo complete orange.png' className='w-28 md:w-36 mx-auto md:mb-10' />
                <div className='flex flex-col gap-4 mt-8 md:mt-20 md:mb-32 mx-auto'>
                    {Array.from({ length: 8 }, (_, index) => (
                        <div className='flex' key={index}>
                            {pages?.activeTab  === index + 1 && <div className='w-1 h-3 mt-1 rounded-full bg-orange-600'></div>}
                            <h1 className={`text-sm ${pages?.activeTab === index + 1 ? 'ml-2 text-white font-bold' : 'font-light text-gray-300 ml-3'}`}>
                                {["Setup Access", "Information", "Login", "Data Control","Usage keys"
                                    ,"Google Sheets /orders","Google Sheets /products","Status"][index]}
                            </h1>
                        </div>
                    ))}
                </div>
            </div>
            <div className="w-full flex items-center text-center py-10" >
                {pages?.activeTab  === 1 && <Access CheckAccses={CheckAccses} setPages={setPages} />}
                {pages?.activeTab  === 2 && <Information setPages={setPages} />}
                {pages?.activeTab  === 3 && <Login setPages={setPages} />}
                {pages?.activeTab  === 4 && <DataControl setPages={setPages} />}
                {pages?.activeTab  === 5 && <UsageKeys setPages={setPages} />}
                {pages?.activeTab  === 6 && <GoogleSheets type={"orders"} setPages={setPages} />}
                {pages?.activeTab  === 7 && <GoogleSheets type={"products"} setPages={setPages} />}
                {pages?.activeTab  === 8 && <Status sheetAction_type={setSheetAction_type} />}

            </div>

        </section>
    )
}
