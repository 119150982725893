import { useEffect, useRef, useState } from "react"
import { AnimatePresence, motion } from 'framer-motion'
import axios from 'axios'
import { An_Error, Error_specific } from '../../component/alert'

export function Chat() {
    const [toggleChat, setToggleChat] = useState(false)
    const [toggleSelect, setToggleSelect] = useState(true)
    const [ChangeChat, setChangeChat] = useState({ type: 'System Admin', head: "System Admin", label: "Selezy team" })
    const [Loading, setLoading] = useState(true)
    const [messages, setMessages] = useState([])
    const [users, setUsers] = useState([])
    const [content, setContent] = useState('')
    const ref = useRef(null);
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')
    const role = localStorage.getItem('role') || sessionStorage.getItem('role')

    // for switch chat receiver
    function handlerChatChange() {
        const listOfchat = [
            { type: 'System Admin', head: "System Admin", label: "Selezy team" },
            { type: 'Confirmer', head: "confirmers & delivery users", label: "Select Users" },
        ];
        const currentIndex = listOfchat.findIndex(chat => chat.type === ChangeChat.type);
        const nextIndex = currentIndex !== -1 ? (currentIndex + 1) % listOfchat.length : 0;
        const nextChat = listOfchat[nextIndex];
        setChangeChat(nextChat);
    }

    // handler close modal whene click outside modal
    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setToggleChat(false);
        }
    };
    document.addEventListener('mousedown', handleClickOutside);


    // Function get Confirmers and Delivery_User
    async function getUsers() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/messages/get-confirmers-deliveryUser`, { headers: { 'x-access-token': token } });
            setUsers(response.data.Data);
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    useEffect(() => {
        setSelectedConfirmer(0)
        if (ChangeChat.type === 'Confirmer' && (role === 'Super_Admin' || role === 'admin')) {
            setMessages([])
            setToggleSelect(true)
            getUsers()
        }
    }, [ChangeChat.type])

    useEffect(() => {
        getUsers()
    }, [toggleSelect])
    //get nbr of unread messages 
    const [nbrMessages, setNbrMessages] = useState(0)
    async function getCountUnreadMessages() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/messages/count-unread-messages`, { headers: { 'x-access-token': token, 'x-access-role': role } });
            setNbrMessages(response.data.messageCounts);
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    // change confirmer
    const [selectedConfirmer, setSelectedConfirmer] = useState(0)
    function handlerSelectedConfirmer(id) {
        setSelectedConfirmer(id);
        const name = users.filter(i => i._id == id)
        setChangeChat({ ...ChangeChat, label: `${name[0].firstName} ${name[0].lastName}` })
        setToggleSelect(false)
    }
    // function get type 
    function getType() {
        const typeMapping = {
            'System Admin': 'System',
            'Confirmer': 'Confirmers',
        };
        return typeMapping[ChangeChat.type]
    }

    //function Get messages by Chat type
    const [key, setKey] = useState()
    async function getMessages() {
        const type = getType();
        const receiverId = ChangeChat.type === 'System Admin' ? '27dd55151a4982bddkkik151584fdf8fs83b8bc9c835a55eddsds51cf02a7' : selectedConfirmer
        if ((role === 'Super_Admin' || role === 'admin') && ChangeChat.type === 'Confirmer' && selectedConfirmer === 0) {
            return console.log('pending!!!')
        }
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/messages/get-messages/${type}`, { receiverId }, { headers: { 'x-access-token': token, 'x-access-role': role } });
            setMessages(response.data.Data); setKey(response.data.checkKey); setLoading(false); getCountUnreadMessages()
        } catch (error) {
            if (error.respone?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    useEffect(() => {
        if (toggleChat) {
            getMessages()
        }
    }, [ChangeChat, selectedConfirmer, toggleChat])


    async function sendMessage() {
        if (content !== '') {
            const type = getType();
            const receiverId =
                type === 'System'
                    ? '27dd55151a4982bddkkik151584fdf8fs83b8bc9c835a55eddsds51cf02a7'
                    : selectedConfirmer;
            try {
                const response = await axios.post(
                    `${process.env.REACT_APP_SERVER_URL}/messages/send-message/${type}`,
                    { receiverId, content },
                    { headers: { 'x-access-token': token, 'x-access-role': role } }
                );
                getMessages(); setContent('')
            } catch (error) {
                if (error.response?.status === 401) {
                    Error_specific(error.response.data.message);
                } else {
                    An_Error();
                }
            }
        }
    }

    // get Company Name for Confirmer chat
    const [companyName, setCompanyName] = useState('')
    async function getCompanyName() {
        try {
            const response = await axios.get(
                `${process.env.REACT_APP_SERVER_URL}/messages/get-companyname`,
                { headers: { 'x-access-token': token } }
            );
            setCompanyName(response.data.companyName)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
            } else {
                An_Error();
            }
        }
    }

    useEffect(() => {
        if (role === 'confirmer' || role === 'Delivery_User') {
            getCompanyName()
        }
        getCountUnreadMessages()
    }, [])

    // handler on click entre 
    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };
    //triger get unread message evrey 10s   
    useEffect(() => {
        setInterval(getCountUnreadMessages, 10000);
    }, [])
    return (
        <div className="">

            <button onClick={() => setToggleChat(!toggleChat)} className={`fixed bottom-4 right-4 z-30 inline-flex items-center justify-center text-sm font-medium disabled:pointer-events-none disabled:opacity-50 border rounded-full shadow-xl w-16 h-16  hover:bg-gray-700 m-0 cursor-pointer border-gray-200 bg-none p-0 normal-case leading-5 hover:text-gray-900" type="button" aria-haspopup="dialog" aria-expanded="false" data-state="closed ${toggleChat ? 'bg-gray-700' : 'bg-black'}`}>
                <svg xmlns=" http://www.w3.org/2000/svg" width={30} height={40} viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth={2} strokeLinecap="round" strokeLinejoin="round" className="text-white block border-gray-200 align-middle">
                    <path d="m3 21 1.9-5.7a8.5 8.5 0 1 1 3.8 3.8z" className="border-gray-200">
                    </path>
                </svg>
                {nbrMessages > 0 && <span class="absolute -top-2 left-10 rounded-full bg-red-500 p-0.5 px-2 text-sm text-red-50">{nbrMessages}</span>}
            </button>
            <AnimatePresence>
                {toggleChat &&
                    <motion.div ref={ref} initial={{ x: 400, opacity: 0, scale: 0 }}
                        animate={{ x: 0, opacity: 1, scale: 1 }}
                        exit={{ x: 400, opacity: 0, scale: 0 }}
                        transition={{ duration: 0.3 }} className="fixed  z-30 bottom-[calc(4rem+1.5rem)] right-0 mr-4 bg-gray-100 dark:bg-gray-900 shadow-md p-6 rounded-lg border border-[#e5e7eb] dark:border-gray-700 w-[380px] md:w-[440px] h-[634px] ">
                        <div class="flex sm:items-center justify-between pb-2 border-b-2 border-gray-200">
                            <div class="relative flex items-center">
                                <div class="flex flex-col leading-tight">
                                    <div class="text-2xl flex items-center">
                                        <div className="flex w-full items-center p-2 pl-2 border-l-2 relative border-blue-300">
                                            <div className="w-full items-center flex">
                                                <div className="mx-2 -mt-1 text-sm dark:text-gray-100">{role === 'Super_Admin' || role === 'admin' ? ChangeChat.label : companyName}
                                                    <div className="text-xs w-full max-w-44 normal-case font-normal -mt-1 text-gray-500">{role === 'Super_Admin' || role === 'admin' ? ChangeChat.head : 'Admin'}</div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="flex items-center space-x-2">
                                {role === 'Super_Admin' || role === 'admin' ?
                                    <>
                                        {ChangeChat.type === "Confirmer" &&
                                            <>
                                                <div class="group relative inline-block">
                                                    <button onClick={() => setToggleSelect(!toggleSelect)} type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                                                        {toggleSelect ?
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" width="100%" height="100%" stroke-width="2" stroke="currentColor" class="feather feather-chevron-up w-4 h-4">
                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
                                                            </svg>
                                                            :
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-chevron-up w-4 h-4">
                                                                <polyline points="18 15 12 9 6 15"></polyline>
                                                            </svg>}
                                                    </button>
                                                    <div
                                                        class="border-light text-body-color absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded border bg-white py-[6px] px-4 text-sm font-semibold opacity-0 group-hover:opacity-100"
                                                    >
                                                        <span
                                                            class="border-light absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-l-sm border-b border-r bg-white"
                                                        ></span>
                                                        Select Confirmer
                                                    </div>

                                                </div>
                                                {toggleSelect &&
                                                    <div className="absolute shadow bg-white top-16 mt-5 md:mt-2 z-40 w-4/6 md:w-2/4 left-10 rounded max-h-select overflow-y-auto">
                                                        <div className="flex flex-col w-full">
                                                            <div className="cursor-pointer w-full border-gray-100 rounded-t border-b ">
                                                                {users.length > 0 && users.map(item => {
                                                                    return (
                                                                        <div onClick={() => handlerSelectedConfirmer(item._id)} className="flex w-full items-center p-2 pl-2 border-transparent border-l-2 relative hover:border-blue-300 hover:bg-blue-100">
                                                                            <div className="w-6 flex flex-col items-center">
                                                                                <div className="flex relative bg-orange-500 justify-center items-center m-1 mr-2 w-7 h-7 mt-1 rounded-full ">
                                                                                    <img className="object-cover object-center w-full h-full overflow-hidden rounded-full" alt="avatar"
                                                                                        src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                                                                        onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                            <div className="w-full items-center flex">
                                                                                <div className="w-full flex justify-between items-center">
                                                                                    <div className="mx-2 -mt-1 text-sm w-full">
                                                                                        {item.firstName} {item.lastName}
                                                                                        <div className="text-xs truncate w-full normal-case font-normal -mt-1 text-gray-500">{item.role}</div>
                                                                                    </div>
                                                                                    {item?.unread > 0 && <div className="flex items-center justify-center text-xs text-white bg-red-500 h-4 w-4 rounded leading-none">{item?.unread}</div>}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}
                                                            </div>
                                                        </div>
                                                    </div>
                                                }
                                            </>
                                        }
                                        <div class="group relative inline-block">
                                            <button onClick={() => handlerChatChange()} type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500  hover:bg-gray-300 focus:outline-none">
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M7.5 21L3 16.5m0 0L7.5 12M3 16.5h13.5m0-13.5L21 7.5m0 0L16.5 12M21 7.5H7.5" />
                                                </svg>
                                            </button>
                                            <div
                                                class="border-light text-body-color absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded border bg-white py-[6px] px-4 text-sm font-semibold opacity-0 group-hover:opacity-100"
                                            >
                                                <span
                                                    class="border-light absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-l-sm border-b border-r bg-white"
                                                ></span>
                                                Change Chat
                                            </div>
                                        </div>
                                    </> : ''
                                }
                                <div class="group relative inline-block">
                                    <button onClick={() => getMessages()} type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0l3.181 3.183a8.25 8.25 0 0013.803-3.7M4.031 9.865a8.25 8.25 0 0113.803-3.7l3.181 3.182m0-4.991v4.99" />
                                        </svg>
                                    </button>
                                    <div
                                        class="border-light text-body-color absolute bottom-full left-1/2 z-20 mb-3 -translate-x-1/2 whitespace-nowrap rounded border bg-white py-[6px] px-4 text-sm font-semibold opacity-0 group-hover:opacity-100"
                                    >
                                        <span
                                            class="border-light absolute -bottom-1 left-1/2 -z-10 h-2 w-2 -translate-x-1/2 rotate-45 rounded-l-sm border-b border-r bg-white"
                                        ></span>
                                        Reload Messages
                                    </div>
                                </div>
                                <button onClick={() => setToggleChat(false)} type="button" class="inline-flex items-center justify-center rounded-lg border h-10 w-10 transition duration-500 ease-in-out text-gray-500 hover:bg-gray-300 focus:outline-none">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </button>
                            </div>
                        </div>
                        <div className="pr-4 overflow-y-auto h-[490px] mb-1" >
                            {messages.length > 0 &&
                                messages.map((item, index) => {
                                    return (
                                        <motion.div initial={{ opacity: 0, y: 20 }}
                                            animate={{ opacity: 1, y: 0 }}
                                            exit={{ opacity: 0, y: -20 }}
                                            transition={{ duration: 0.3, delay: 0.2 * (index + 1) }} className="flex  gap-3 my-4 text-gray-600 text-sm flex-1"><span className="relative flex shrink-0 overflow-hidden rounded-full w-8 h-8">
                                                <div className="rounded-full bg-gray-100 border p-1">
                                                    {item.sender_id === key ?
                                                        (<svg stroke="none" fill="black" strokeWidth={0} viewBox="0 0 16 16" height={20} width={20} xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M8 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6Zm2-3a2 2 0 1 1-4 0 2 2 0 0 1 4 0Zm4 8c0 1-1 1-1 1H3s-1 0-1-1 1-4 6-4 6 3 6 4Zm-1-.004c-.001-.246-.154-.986-.832-1.664C11.516 10.68 10.289 10 8 10c-2.29 0-3.516.68-4.168 1.332-.678.678-.83 1.418-.832 1.664h10Z">
                                                            </path>
                                                        </svg>) :
                                                        (<svg stroke="none" fill="black" strokeWidth="1.5" viewBox="0 0 24 24" aria-hidden="true" height={20} width={20} xmlns="http://www.w3.org/2000/svg">
                                                            <path strokeLinecap="round" strokeLinejoin="round" d="M9.813 15.904L9 18.75l-.813-2.846a4.5 4.5 0 00-3.09-3.09L2.25 12l2.846-.813a4.5 4.5 0 003.09-3.09L9 5.25l.813 2.846a4.5 4.5 0 003.09 3.09L15.75 12l-2.846.813a4.5 4.5 0 00-3.09 3.09zM18.259 8.715L18 9.75l-.259-1.035a3.375 3.375 0 00-2.455-2.456L14.25 6l1.036-.259a3.375 3.375 0 002.455-2.456L18 2.25l.259 1.035a3.375 3.375 0 002.456 2.456L21.75 6l-1.035.259a3.375 3.375 0 00-2.456 2.456zM16.894 20.567L16.5 21.75l-.394-1.183a2.25 2.25 0 00-1.423-1.423L13.5 18.75l1.183-.394a2.25 2.25 0 001.423-1.423l.394-1.183.394 1.183a2.25 2.25 0 001.423 1.423l1.183.394-1.183.394a2.25 2.25 0 00-1.423 1.423z">
                                                            </path>
                                                        </svg>)
                                                    }</div>
                                            </span>
                                            <p className="leading-relaxed dark:text-gray-400"><span className="block font-bold text-gray-700 dark:text-gray-200">{item.sender_name}</span>
                                                {item.content}
                                            </p>
                                        </motion.div>
                                    )
                                })
                            }
                        </div>
                        <div className="flex items-center pt-0">
                            <div className="flex items-center justify-center w-full space-x-2">
                                <input onChange={(e) => setContent(e.target.value)} onKeyDown={handleKeyPress} value={content} className="flex h-10 w-full rounded-md border border-[#e5e7eb] px-3 py-2 text-sm placeholder-[#6b7280] focus:outline-none focus:ring-2 focus:ring-[#9ca3af] disabled:cursor-not-allowed disabled:opacity-50 text-[#030712] focus-visible:ring-offset-2 dark:bg-gray-900 dark:text-white" placeholder="Type your message..." />
                                <button onClick={() => sendMessage()} className="inline-flex items-center justify-center rounded-md text-sm font-medium text-[#f9fafb] disabled:pointer-events-none disabled:opacity-50 bg-black hover:bg-[#111827E6] h-10 px-4 py-2 dark:bg-gray-900 border-2">
                                    Send</button>
                            </div>
                        </div>
                    </motion.div>
                }
            </AnimatePresence>
        </div>

    )
}