import axios from "axios"
import { useEffect, useState } from "react"
import { An_Error, Error_specific, Success } from "../..//../component/alert";
import ReactPaginate from 'react-paginate';
import { AnimatePresence, motion } from "framer-motion";



export default function SheetsManage({ collection }) {
    const [SheetData, setSheetData] = useState({ id: '', name: '', key: '' })
    const [SheetError, setSheetError] = useState()
    const [LoadingBtn, setLoadingBtn] = useState(false)
    const [toggleDiv, setToggleDiv] = useState(false)
    const [Data, SetData] = useState([])
    const [editVisibility, setEditVisibility] = useState(new Array(Data.length).fill(false));
    const [editConfiermers, setEditConfiermers] = useState(new Array(Data.length).fill(false));
    const [unlinkSheet, setUnlinkSheet] = useState(new Array(Data.length).fill(false));
    const [_Id, set_ID] = useState()
    const [confirmers, setConfirmers] = useState([])
    const [tempConfirmers, setTempConfirmers] = useState([])
    const [listOfSelectedConfirmers, setListOfSelectedConfirmers] = useState([])
    const [alert, setAlert] = useState(false)
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')

    async function getConfirmers() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/get-confirmers`, { headers: { 'x-access-token': token } });
            const activeConfirmers = response.data.Data.filter(confirmer => confirmer.status === "Active");
            setConfirmers(activeConfirmers); setTempConfirmers(activeConfirmers);
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    const handlerSearchConfirmers = (text) => {
        const lowercaseText = text.toLowerCase().replace(/\s/g, '');
        const filteredData = tempConfirmers.filter((item) => {
            const firstNameMatch = item.firstName.toLowerCase();
            const lastNameMatch = item.lastName.toLowerCase();
            return (firstNameMatch + lastNameMatch).includes(lowercaseText);
        });
        setConfirmers(filteredData);
    }


    useEffect(() => {
        listOfSelectedConfirmers.length === 0 ? setAlert(true) : setAlert(false)
    }, [listOfSelectedConfirmers])

    useEffect(() => {
        getConfirmers()
    }, [])
    const addNewSheet = async () => {
        setLoadingBtn(true)
        if (SheetData.id === '' || SheetData.name === '') {
            setSheetError('All fields are required! please complete all required fields.'); setLoadingBtn(false)
        } else {
            try {
                const dataList = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${SheetData.id}/values/${SheetData.name}?key=${process.env.REACT_APP_API_KEY_GSHEETS}`);
                try {
                    const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/sheets/save-GoogleSheets/${collection}`, { SheetData, header: dataList.data.values[0] }, { headers: { 'x-access-token': token } });
                    setSheetError(''); setLoadingBtn(false); Success(response.data.message); setToggleDiv(false); getData()
                } catch (error) {
                    if (error.response.status === 401) {
                        Error_specific(error.response.data.message); setSheetError(''); setLoadingBtn(false)
                    } else {
                        An_Error(); setSheetError(''); setLoadingBtn(false)
                    }
                }
            } catch (error) {
                setSheetError('Sheet not found !!')
                setLoadingBtn(false)
            }
        }
    };


    async function getData() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/sheets/get-sheets/${collection}`, { headers: { 'x-access-token': token } });
            SetData(response.data.Data)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }
    useEffect(() => {
        getData()
    }, [collection])

    const toggleUnlink = (index) => {
        const temp = new Array(Data.length).fill(false);
        temp[index] = !unlinkSheet[index];
        setUnlinkSheet(temp);
    };

    const toggleEditDiv = (index) => {
        const newVisibility = [...editVisibility];
        newVisibility[index] = !newVisibility[index];
        setEditVisibility(newVisibility);
    };

    const toggleEditConfirmer = (index) => {
        const newEditConfiermers = new Array(Data.length).fill(false);
        newEditConfiermers[index] = !editConfiermers[index];
        setEditConfiermers(newEditConfiermers);
    };

    const [speficSheet, setSpeficSheet] = useState({})
    const Update = async (index, id) => {
        setLoadingBtn(true)
        try {
            const dataList = await axios.get(`https://sheets.googleapis.com/v4/spreadsheets/${speficSheet[index].id}/values/${speficSheet[index].name}?key=${process.env.REACT_APP_API_KEY_GSHEETS}`);
            try {
                const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/sheets/update-sheet/${id}`, { speficSheet: speficSheet[index], header: dataList.data.values[0] }
                    , { headers: { 'x-access-token': token } });
                Success(response.data.message); setLoadingBtn(false); toggleEditDiv(index); getData(); setSheetError('')
            } catch (error) {
                if (error.response.status === 401) {
                    Error_specific(error.response.data.message); setSheetError('')
                    setLoadingBtn(false);
                } else {
                    An_Error(); setSheetError('')
                    setLoadingBtn(false)
                }
            }
        } catch (error) {
            console.log(error);
            setSheetError('Sheet not found !!')
            setLoadingBtn(false)
        }
    }

    const Delete = async (id) => {
        setLoadingBtn(true)
        try {
            const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/sheets/delete-sheet/${id}`, { headers: { 'x-access-token': token } });
            Success(response.data.message); getData()
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
        setLoadingBtn(false)
    }
    const UpdateSpecificConfirmers = async (_id = null) => {
        let updatedList;
        if (_id) {
            if (listOfSelectedConfirmers.includes(_id)) {
                // Item is already in the list, so remove it
                if (listOfSelectedConfirmers.length <= 1) {
                    return Error_specific("indicating that you've reached the minimum required number of confirmers in the sheet.")
                }
                updatedList = listOfSelectedConfirmers.filter(id => id !== _id);
            } else {
                // Item is not in the list, so add it
                updatedList = [...listOfSelectedConfirmers, _id];
            }
            setListOfSelectedConfirmers(updatedList);
        } else {
            updatedList = tempConfirmers.map((confirmer) => confirmer._id)
            setListOfSelectedConfirmers(updatedList)
        }
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/sheets/add-specific-confirmers/${_Id}`, { listOfconfirmers: updatedList }, { headers: { 'x-access-token': token } });
            Success(response.data.message); getData()
        } catch (error) {
            if (error.response.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }


    // ---------------------pagination----------------------------
    const itemsPerPage = 7;
    const [currentPage, setCurrentPage] = useState(0);
    const totalPages = Math.ceil(confirmers.length / itemsPerPage);
    const getCurrentPageData = () => {
        const startIndex = currentPage * itemsPerPage;
        const endIndex = startIndex + itemsPerPage;
        return confirmers.slice(startIndex, endIndex);
    };
    const handlePageChange = ({ selected }) => {
        setCurrentPage(selected);
    };
    const currentPageData = getCurrentPageData();

    return (
        <>
            <div class="pt-2 flex">
                <h1 class="py-2 text-2xl font-semibold dark:text-white">Google Sheets settings</h1>
                <div onClick={() => setToggleDiv(true)} className="ml-auto inline-flex mt-4 text-gray-700 dark:text-white hover:scale-125">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6 cursor-pointer">
                        <path stroke-linecap="round" stroke-linejoin="round" d="M19.5 14.25v-2.625a3.375 3.375 0 00-3.375-3.375h-1.5A1.125 1.125 0 0113.5 7.125v-1.5a3.375 3.375 0 00-3.375-3.375H8.25m3.75 9v6m3-3H9m1.5-12H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 00-9-9z" />
                    </svg></div>
            </div>
            <hr class="mt-2 mb-4" />
            <div className="mb-5">
                <AnimatePresence>
                    {toggleDiv &&
                        <motion.div initial={{ y: -100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: -100, opacity: 0 }}
                            transition={{ duration: 0.4, ease: "easeOut" }} class="flex flex-col space-y-3 px-4 py-1 sm:px-10 rounded-md border bg-transparant mb-4">
                            <h3 className="mx-auto font-bold text-xl dark:text-gray-100">ADD New Sheets</h3>
                            <label class="block">
                                <p class="text-sm dark:text-gray-200">File ID</p>
                                <input onChange={(e) => setSheetData({ ...SheetData, id: e.target.value })} class="w-full rounded-md border py-2 px-2 bg-gray-50 dark:bg-gray-900 dark:text-gray-200 outline-none ring-blue-600 focus:ring-1" type="text" placeholder="1vKx1iPApINzydYZbFEAxLknpR8S6UziC91sAXTrpVVw" />
                                <img loading="lazy" src="/images/fileID.png" alt="id" />
                            </label>
                            <label class="block" >
                                <p class="text-sm dark:text-gray-200">File Name</p>
                                <input onChange={(e) => setSheetData({ ...SheetData, name: e.target.value })} class="w-full rounded-md border py-2 px-2 bg-gray-50 dark:bg-gray-900 dark:text-gray-200 outline-none ring-orange-600 focus:ring-1" type="text" placeholder="Orders" />
                                <img loading="lazy" src="/images/fileName.png" alt="name" />
                            </label>
                            <p class="mt-2 text-md text-red-600 dark:text-red-500">
                                {SheetError ? SheetError : ''}</p>
                            <div className="flex mt-4 flex-col md:flex-row  md:space-x-6">
                                <button onClick={() => setToggleDiv(false)} type="button" className="flex mb-3 items-center justify-center w-full px-6 py-1 font-medium tracking-wide  capitalize rounded-md  bg-transparant text-gray-700 dark:text-white border border-gray-400 dark:border-gray-600 hover:fill-current   focus:outline-none  transition duration-300 transform active:scale-95 ease-in-out dark:text-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                    </svg>
                                    <span className="pl-2 mx-1">Cancel</span>
                                </button>
                                <button class="w-full mb-3 rounded-md bg-orange-600 px-6 py-1 font-medium text-white" onClick={() => addNewSheet()}>
                                    {LoadingBtn ? <div class="flex items-center justify-center ">
                                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <div class="ml-2"> Processing.... </div>
                                    </div> : "Save"}
                                </button>
                            </div>
                        </motion.div>}
                </AnimatePresence>


                {Data.length < 1 ?
                    <div className="text-gray-700 col-span-full dark:text-gray-400  animate-pulse">
                        <p className="font-normal text-xl mt-4 mb-4 text-center text-gray-400 dark:text-gray-700">No Sheets have been added...</p>
                    </div> :
                    Data.map((item, index) => {
                        return (
                            <div class="rounded-md border bg-transparant mt-2 px-1" key={index}>
                                <div class="flex w-full items-center py-2 md:px-6">
                                    <div className="inline-flex"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="mr-2 h-5 w-5 text-gray-500 dark:text-gray-200 hidden md:block">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M13.19 8.688a4.5 4.5 0 011.242 7.244l-4.5 4.5a4.5 4.5 0 01-6.364-6.364l1.757-1.757m13.35-.622l1.757-1.757a4.5 4.5 0 00-6.364-6.364l-4.5 4.5a4.5 4.5 0 001.242 7.244" />
                                    </svg><span className="dark:text-gray-200 w-[29vh] md:w-[70vh] break-words">{item.id} ({item.name})  </span>
                                    </div>
                                    <div className="ml-auto  flex flex-col md:flex-row md:space-x-4 space-y-4 md:space-y-0 mr-2 md:mr-0">
                                        {!editVisibility[index] && !editConfiermers[index]   && !unlinkSheet[index] ? <>
                                            {collection === 'orders' && confirmers.length > 0 ?
                                                <button title="Selct Confirmers" onClick={() => {
                                                    setListOfSelectedConfirmers(item.confirmers)
                                                    set_ID(item._id)
                                                    toggleEditConfirmer(index)
                                                }} type="button" class="relative mr-auto inline-flex  cursor-pointer items-center text-gray-500 dark:text-gray-200 text-center text-sm font-medium hover:scale-110  focus:outline-none  focus:scale-110  lg:ml-0 ">
                                                    <svg fill="currentColor" class="h-5 w-5 cursor-pointer hover:scale-110" height="800px" width="800px" version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 297 297" enable-background="new 0 0 297 297">
                                                        <g>
                                                            <path d="m112.632,185.074l6.88-3.972c2.804-1.619 3.765-5.205 2.146-8.01l-13.036-22.579c-1.086-1.881-3.057-2.933-5.083-2.933-0.995,0-2.003,0.253-2.926,0.787l-6.88,3.972c-0.945,0.545-1.947,0.794-2.923,0.794-3.063,0-5.872-2.449-5.872-5.872v-7.944c0-3.238-2.625-5.864-5.864-5.864h-26.073c-3.238,0-5.864,2.625-5.864,5.864v7.944c0,3.423-2.81,5.872-5.872,5.872-0.976,0-1.978-0.249-2.923-0.794l-6.88-3.972c-0.923-0.533-1.932-0.786-2.926-0.787-2.027,0-3.997,1.052-5.083,2.933l-13.036,22.579c-1.619,2.805-0.658,6.391 2.146,8.01l6.88,3.972c3.909,2.257 3.909,7.899 0,10.156l-6.88,3.972c-2.805,1.619-3.765,5.205-2.146,8.01l13.036,22.579c1.086,1.881 3.057,2.933 5.083,2.933 0.995,0 2.003-0.254 2.926-0.787l6.88-3.972c0.945-0.545 1.947-0.794 2.923-0.794 3.063,0 5.872,2.449 5.872,5.872v7.944c0,3.238 2.625,5.864 5.864,5.864h26.072c3.238,0 5.864-2.625 5.864-5.864v-7.944c0-3.423 2.81-5.872 5.872-5.872 0.976,0 1.978,0.249 2.923,0.794l6.88,3.972c0.923,0.533 1.932,0.787 2.926,0.787 2.027,0 3.997-1.052 5.083-2.933l13.036-22.579c1.619-2.805 0.658-6.391-2.146-8.01l-6.88-3.972c-3.908-2.257-3.908-7.9 0.001-10.156zm-46.594,22.474c-9.608,0-17.396-7.789-17.396-17.396 0-9.608 7.789-17.396 17.396-17.396s17.396,7.789 17.396,17.396c0,9.607-7.789,17.396-17.396,17.396z" />
                                                            <path d="m108.109,23.659c-3.146-3.144-8.243-3.144-11.389,0-3.145,3.146-3.145,8.244 0,11.389l14.39,14.389c-52.889,2.619-95.701,44.162-100.334,96.506l1.19-2.062c3.406-5.9 9.756-9.565 16.57-9.564 0.144,0 0.287,0.013 0.431,0.017 9.074-37.721 41.965-66.251 81.815-68.729l-14.062,14.061c-3.145,3.145-3.145,8.244 0,11.389 1.573,1.572 3.633,2.358 5.694,2.358s4.122-0.786 5.694-2.358l28.004-28.004c1.51-1.511 2.358-3.559 2.358-5.694 0-2.136-0.848-4.184-2.358-5.694l-28.003-28.004z" />
                                                            <path d="m209.868,64.857c17.881,0 32.428-14.547 32.428-32.428 0-17.882-14.547-32.429-32.428-32.429-17.881,0-32.428,14.547-32.428,32.428 0,17.881 14.547,32.429 32.428,32.429z" />
                                                            <path d="m273.039,152.276v-44.58c0-12.34-7.93-23.283-19.657-27.124l-.054-.018-17.152-2.84c-1.46-0.449-3.02,0.324-3.545,1.764l-19.462,53.399c-1.123,3.081-5.48,3.081-6.602,0l-19.462-53.399c-0.424-1.163-1.522-1.892-2.698-1.892-0.279,0-17.999,2.964-17.999,2.964-11.823,3.94-19.723,14.9-19.723,27.294v44.432c0,6.659 5.398,12.056 12.056,12.056h102.241c6.66-2.84217e-14 12.057-5.398 12.057-12.056z" />
                                                            <path d="m287.37,162.933c-0.673,9.215-8.233,14.858-17.45,15.526-7.062,40.425-41.207,71.64-82.979,74.237l14.061-14.061c3.145-3.146 3.145-8.244 0-11.389-3.146-3.144-8.243-3.144-11.389,0l-28.003,28.004c-3.145,3.146-3.145,8.244 0,11.389l28.003,28.003c1.573,1.572 3.633,2.358 5.694,2.358s4.122-0.786 5.694-2.358c3.145-3.145 3.145-8.244 0-11.389l-14.389-14.389c56.028-2.774 100.758-49.227 100.758-105.931z" />
                                                            <path d="m216.936,77.105c-0.747-0.814-1.84-1.224-2.946-1.224h-8.245c-1.105,0-2.198,0.41-2.946,1.224-1.157,1.261-1.325,3.082-0.504,4.505l4.407,6.644-2.063,17.405 4.063,10.808c0.396,1.087 1.933,1.087 2.33,0l4.063-10.808-2.063-17.405 4.407-6.644c0.822-1.423 0.654-3.244-0.503-4.505z" />
                                                        </g>
                                                    </svg>
                                                </button>
                                                : ''
                                            }
                                            <svg onClick={() => { toggleUnlink(index)}} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="h-5 w-5 cursor-pointer text-gray-500 dark:text-gray-200 hover:scale-110">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M14.74 9l-.346 9m-4.788 0L9.26 9m9.968-3.21c.342.052.682.107 1.022.166m-1.022-.165L18.16 19.673a2.25 2.25 0 01-2.244 2.077H8.084a2.25 2.25 0 01-2.244-2.077L4.772 5.79m14.456 0a48.108 48.108 0 00-3.478-.397m-12 .562c.34-.059.68-.114 1.022-.165m0 0a48.11 48.11 0 013.478-.397m7.5 0v-.916c0-1.18-.91-2.164-2.09-2.201a51.964 51.964 0 00-3.32 0c-1.18.037-2.09 1.022-2.09 2.201v.916m7.5 0a48.667 48.667 0 00-7.5 0" />
                                            </svg>
                                            <svg onClick={() => {
                                                toggleEditDiv(index);
                                                const { _id, ...restOfItem } = item;
                                                setSpeficSheet((prevSpeficSheet) => {
                                                    return { ...prevSpeficSheet, [index]: { ...prevSpeficSheet[index], ...restOfItem } };
                                                });
                                            }} xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 cursor-pointer text-gray-500 dark:text-gray-200 hover:scale-110" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M11 5H6a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2v-5m-1.414-9.414a2 2 0 112.828 2.828L11.828 15H9v-2.828l8.586-8.586z" />
                                            </svg></>
                                            : ""
                                        }
                                    </div>
                                </div>
                                <AnimatePresence>
                                    {unlinkSheet[index] && <motion.div initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -100, opacity: 0 }}
                                        transition={{ duration: 0.4, ease: "easeOut" }} class="flex flex-col space-y-3 px-4 py-1">
                                        <div class="flex flex-col items-center justify-center px-8 pb-4 text-gray-800 overflow-hidden rounded-2xl">
                                            <div className="inline-flex">
                                                <svg version="1.1" id="Layer_1" class="h-16 w-16 rounded-xl p-2 text-red-500" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 122.88" style={{ enableBackground: 'new 0 0 122.88 122.88' }} xmlSpace="preserve"><style type="text/css" dangerouslySetInnerHTML={{ __html: ".st0{fill-rule:evenodd;clip-rule:evenodd;}" }} /><g><path className="st0" d="M110.09,6.2l6.58,6.58c8.27,8.27,8.27,21.81,0,30.08L99.24,60.29c-6.52,6.53-16.33,7.89-24.24,4.12 l30.86-30.86c3.05-3.05,3.05-8.03,0-11.07l-5.97-5.97c-3.05-3.05-8.02-3.05-11.07,0L58.15,47.17c-3.37-7.78-1.9-17.2,4.43-23.53 L80.02,6.2C88.29-2.07,101.82-2.07,110.09,6.2L110.09,6.2z M79.93,116.8l6.38-1.32l-4.12-19.89l-6.37,1.34L79.93,116.8L79.93,116.8 L79.93,116.8z M36.38,2.15l6.31-1.56l4.95,19.66l-6.31,1.6L36.38,2.15L36.38,2.15z M102.86,105.79l4.53-4.67L92.82,86.98 l-4.53,4.68L102.86,105.79L102.86,105.79L102.86,105.79z M117.62,84.76l1.68-6.28l-19.6-5.26l-1.69,6.29L117.62,84.76L117.62,84.76 z M2.54,40.47l1.81-6.25l19.5,5.55l-1.77,6.27L2.54,40.47L2.54,40.47z M13.38,17.17l4.61-4.6l14.34,14.35l-4.59,4.61L13.38,17.17 L13.38,17.17L13.38,17.17z M6.2,110.09l6.58,6.58c8.27,8.27,21.8,8.27,30.08,0l17.43-17.43c6.53-6.52,7.89-16.33,4.12-24.24 l-30.86,30.86c-3.05,3.05-8.03,3.05-11.07,0l-5.97-5.97c-3.05-3.05-3.05-8.03,0-11.08l30.67-30.66c-7.79-3.37-17.2-1.9-23.54,4.44 L6.2,80.02C-2.07,88.29-2.07,101.82,6.2,110.09L6.2,110.09L6.2,110.09z" /></g></svg>
                                                <p class="mt-6 ml-3 text-center text-xl font-bold uppercase text-red-500">Confirm Sheet Unlinking</p>
                                            </div>
                                            <p class="mt-2 text-center text-md text-gray-600 dark:text-gray-400">This will unlink the selected sheet from the workbook. Are you sure you want to proceed with unlinking the sheet?"</p>
                                            <div class="mt-4 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                                <button class="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white" onClick={() => { !LoadingBtn && Delete(item._id) }}>
                                                    {LoadingBtn ? <div class="flex items-center justify-center ">
                                                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        <div class="ml-2"> Processing.... </div>
                                                    </div> : "Yes, unlink this Sheet"}
                                                </button>
                                                {!LoadingBtn && <button class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium" onClick={() => toggleUnlink(index) }>Cancel, keep this Sheet</button>}
                                            </div>
                                        </div>
                                    </motion.div>}
                                    {editVisibility[index] &&
                                        <motion.div initial={{ y: -100, opacity: 0 }}
                                            animate={{ y: 0, opacity: 1 }}
                                            exit={{ y: -100, opacity: 0 }}
                                            transition={{ duration: 0.4, ease: "easeOut" }} class="flex flex-col space-y-3 px-4 py-1 sm:px-10">
                                            <label class="block" >
                                                <p class="text-sm dark:text-gray-200">File ID</p>
                                                <input class="w-full rounded-md border py-2 px-2 bg-gray-50 dark:bg-gray-900 dark:text-gray-200 outline-none ring-orange-600 focus:ring-1"
                                                    onChange={(e) => {
                                                        setSpeficSheet((prevSpeficSheet) => {
                                                            const updatedItem = { ...prevSpeficSheet[index], id: e.target.value };
                                                            return { ...prevSpeficSheet, [index]: updatedItem };
                                                        });
                                                    }} type="text" defaultValue={item.id} />
                                            </label>
                                            <label class="block" >
                                                <p class="text-sm dark:text-gray-200">File Name</p>
                                                <input class="w-full rounded-md border py-2 px-2 bg-gray-50 dark:bg-gray-900 dark:text-gray-200 outline-none ring-orange-600 focus:ring-1"
                                                    onChange={(e) => {
                                                        setSpeficSheet((prevSpeficSheet) => {
                                                            const updatedItem = { ...prevSpeficSheet[index], name: e.target.value };
                                                            return { ...prevSpeficSheet, [index]: updatedItem };
                                                        });
                                                    }} type="text" defaultValue={item.name} />
                                            </label>
                                            <p class="mt-2 text-md text-red-600 dark:text-red-500">
                                                {SheetError ? SheetError : ''}</p>
                                            <div className="flex mt-4 ml-auto space-x-5">
                                                <button onClick={() => toggleEditDiv(index)} type="button" className="flex items-center px-5 py-1.5 font-medium tracking-wide text-black capitalize rounded-md  hover:bg-gray-500 hover:text-white hover:fill-current   focus:outline-none  transition duration-300 transform active:scale-95 ease-in-out dark:text-gray-200">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-6 h-6">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="M9.75 9.75l4.5 4.5m0-4.5l-4.5 4.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                    <span className="pl-2 mx-1">Cancel</span>
                                                </button>
                                                <button onClick={() => Update(index, item._id)} type="button" className="flex items-center px-5 py-1.5 font-medium tracking-wide text-white capitalize   bg-black  rounded-md hover:bg-gray-800  focus:outline-none focus:bg-gray-900  transition duration-300 transform active:scale-95 ease-in-out">

                                                    <span className="pl-2 mx-1">{LoadingBtn ? <div class="flex items-center justify-center ">
                                                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        <div class="ml-2"> Processing.... </div>
                                                    </div> : <div className="inline-flex "><svg className="mr-2 w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="#FFFFFF">
                                                        <path d="M0 0h24v24H0V0z" fill="none" />
                                                        <path d="M5 5v14h14V7.83L16.17 5H5zm7 13c-1.66 0-3-1.34-3-3s1.34-3 3-3 3 1.34 3 3-1.34 3-3 3zm3-8H6V6h9v4z" opacity=".3" />
                                                        <path d="M17 3H5c-1.11 0-2 .9-2 2v14c0 1.1.89 2 2 2h14c1.1 0 2-.9 2-2V7l-4-4zm2 16H5V5h11.17L19 7.83V19zm-7-7c-1.66 0-3 1.34-3 3s1.34 3 3 3 3-1.34 3-3-1.34-3-3-3zM6 6h9v4H6z" />
                                                    </svg> Save </div>}</span>
                                                </button>

                                            </div>
                                        </motion.div>}

                                    {editConfiermers[index] && <motion.div initial={{ y: -100, opacity: 0 }}
                                        animate={{ y: 0, opacity: 1 }}
                                        exit={{ y: -100, opacity: 0 }}
                                        transition={{ duration: 0.4, ease: "easeOut" }} class="flex flex-col px-4 mb-5">
                                        {alert &&
                                            <div class="flex justify-center items-center m-1 font-medium px-2 bg-white rounded-xl text-yellow-700 bg-yellow-100 border border-yellow-300 ">
                                                <div slot="avatar">
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-info w-5 h-5 mx-2">
                                                        <circle cx="12" cy="12" r="10"></circle>
                                                        <line x1="12" y1="16" x2="12" y2="12"></line>
                                                        <line x1="12" y1="8" x2="12.01" y2="8"></line>
                                                    </svg>
                                                </div>
                                                <div class="text-xl font-normal  max-w-full flex-initial">
                                                    <div class="py-2">
                                                        <div class="text-sm font-base">By default, all confirmations are selected. If you want to choose specific confirmers, please make your selection below.</div>
                                                    </div>
                                                </div>
                                                <div onClick={() => setAlert(false)} class="flex flex-auto flex-row-reverse">
                                                    <div>
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-x cursor-pointer hover:text-yellow-400 rounded-full w-5 h-5 ml-2">
                                                            <line x1="18" y1="6" x2="6" y2="18"></line>
                                                            <line x1="6" y1="6" x2="18" y2="18"></line>
                                                        </svg>
                                                    </div>
                                                </div>
                                            </div>}
                                        <div className="mt-2 flex flex-col md:flex-row">
                                            <input onChange={(e) => handlerSearchConfirmers(e.target.value)} className="px-7 md:w-96 py-2 bg-gray-100 dark:bg-gray-700 rounded-xl  text-base text-gray-900 dark:text-gray-100 font-normal leading-normal tracking-normal opacity-50" placeholder="Search by full name...." />

                                            <h3 class="text-orange-600 mt-2 md:pt-2 ml-auto underline hover:text-orange-800 float-right cursor-pointer" onClick={() => UpdateSpecificConfirmers()}>Select All</h3><br></br>
                                        </div>
                                        <div class="overflow-hidden mt-0 md:mt-3 mb-2 bg-transparant">
                                            <table class="w-full mb-2">
                                                <thead className="bg-gray-200 dark:bg-gray-700 text-gray-700 dark:text-gray-100 py-1">
                                                    <td class="px-5 font-semibold">Full Name</td>
                                                    <td class="text-center font-semibold">Actions</td>
                                                </thead>
                                                <tbody>
                                                    {currentPageData.length > 0 && currentPageData.map(item => {
                                                        return (
                                                            <tr className="border-b dark:border-gray-600 ">
                                                                <td class="py-2 px-4 text-center text-sm">
                                                                    <div class="flex items-center">
                                                                        <div class="flex-shrink-0 w-6 h-6">
                                                                            <img class="w-full h-full rounded-full"
                                                                                src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                                                                onError={(e) => e.target.src = "https://static.vecteezy.com/system/resources/thumbnails/009/292/244/small/default-avatar-icon-of-social-media-user-vector.jpg"}
                                                                                alt="avatar" />
                                                                        </div>
                                                                        <div class="ml-3">
                                                                            <p class="text-gray-900 dark:text-gray-200 whitespace-no-wrap">
                                                                                {item.firstName} {item.lastName}
                                                                            </p>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                                <td class="py-2 text-center text-sm">
                                                                    <div onClick={() => {
                                                                        UpdateSpecificConfirmers(item._id)
                                                                    }} class="block rounded border border-orange-500 py-1 text-center font-medium text-orange-500 md:inline-block hover:border-2 hover:font-semibold cursor-pointer" >
                                                                        {listOfSelectedConfirmers.includes(item._id) ? "Remove" : "Add"}   </div>
                                                                </td>
                                                            </tr>
                                                        );
                                                    })}
                                                </tbody>
                                            </table>
                                            {confirmers.length >= itemsPerPage &&
                                                <ReactPaginate
                                                    pageCount={totalPages}
                                                    previousLabel="Previous"
                                                    nextLabel="Next"
                                                    breakLabel="..."
                                                    onPageChange={handlePageChange}
                                                    containerClassName="flex items-center mb-3 mt-5 md:mt-1 overflow-y-auto w-full"
                                                    pageClassName="px-2 py-1 text-sm ml-2 font-extrabold rounded-md hover:bg-orange-600 hover:text-white text-gray-600 dark:text-gray-200"
                                                    previousClassName="flex items-center px-5 py-2 text-sm mr-4 capitalize transition-colors duration-200  bg-white dark:bg-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-700 rounded-md gap-x-2 hover:bg-gray-100 cursor-pointer"
                                                    nextClassName="flex items-center px-5 py-2 ml-4 text-sm capitalize transition-colors duration-200 bg-white dark:bg-gray-800 dark:text-gray-200 border border-gray-200 dark:border-gray-700 rounded-md gap-x-2 hover:bg-gray-100 cursor-pointer"
                                                    activeClassName="text-orange-500  bg-orange-100 dark:bg-orange-600 cursor-pointer"
                                                    disabledClassName="text-gray-500 dark:text-gray-200 cursor-pointer"
                                                />}
                                        </div>
                                        <span onClick={() => toggleEditConfirmer(index)} className="text-gray-800 dark:text-gray-100 mx-auto animate-bounce cursor-pointer">
                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-6 h-6">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l7.5-7.5 7.5 7.5m-15 6l7.5-7.5 7.5 7.5" />
                                            </svg>
                                        </span>
                                    </motion.div>}
                                </AnimatePresence>
                            </div>
                        )
                    })
                }
            </div>
        </>
    )
}