import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { An_Error, Error_specific, Success } from "../../../../component/alert";
import { DotSpinner } from "@uiball/loaders";
import SheetsManage from "../../component/SheetsManage";
import { Link, useNavigate } from "react-router-dom";
import Offers from "./component/offers";
import InfiniteScroll from "react-infinite-scroll-component";


export default function Products() {
  const [Products, setProducts] = useState([])
  const [tempProducts, setTempProducts] = useState([]);
  const [loading, setLoading] = useState(true)
  const [LoadingBtn, setLoadingBtn] = useState(false)
  const [dataSourceFilter, setDataSourceFilter] = useState('All')
  const [ModalImport, setModalImport] = useState(false)
  const [ModalDelete, setModalDelete] = useState(false)
  const [ModalSetting, setModalSetting] = useState(false)
  const [ModalEdit, setModalEdit] = useState(false)
  const [modalOffers, setModalOffers] = useState(false)
  const [fileType, setFileType] = useState()
  const [progress, setProgress] = useState(0);
  const [selectedFile, setSelectedFile] = useState();
  const [uploadMessage, setUploadMessage] = useState();
  const ref = useRef(null);
  const [keys, setKeys] = useState({ Search: { 0: "", 1: "", 2: "", hold: false } })
  const token = localStorage.getItem('token') || sessionStorage.getItem('token')
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(false);
  const [searchFilter, setSearchFilter] = useState("")
  const [savedKeys, setSavedKeys] = useState([])
  const navigate = useNavigate()


  const handleClickOutside = (event) => {
    const isOutside = ref.current && !ref.current.contains(event.target);
    if (isOutside) {
      setModalImport(false);
      setModalEdit(false);
      setModalDelete(false);
      setModalSetting(false);
      setModalOffers(false)
    }
  };
  document.addEventListener('mousedown', handleClickOutside);

  async function get_keys_Data() {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/get-product-keys`, { headers: { 'x-access-token': token } });
      setSavedKeys(response.data.keys)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  const getData = async (type, refresh = false) => {
    try {
      setLoading(true)
      if (refresh) {
        setProducts([]);
        setTempProducts([]);
        setPage(1);
      }
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/products/get-Products/${type}/${refresh ? 1 : page}`,
        { headers: { 'x-access-token': token }, params: { search: searchFilter } });
      const newData = response.data.Data;
      if (newData.length === 0) {
        setHasMore(false);
      } else {
        setProducts(prevProducts => [...prevProducts, ...newData]);
        setTempProducts(prevTempProducts => [...prevTempProducts, ...newData]);
        setPage((prev) => prev + 1);
        setHasMore(true)
      }
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    } finally {
      setLoading(false)
    }
  }

  function SwitchData(type) {
    setDataSourceFilter(type)
    getData(type, true)
  }

  useEffect(() => {
    get_keys_Data()
    getData(dataSourceFilter)
  }, [])

  useEffect(() => {
    if (savedKeys) {
      setKeys((prevKeys) => ({
        ...prevKeys,
        Search: {
          ...prevKeys.Search,
          0: savedKeys.id,
          1: savedKeys.product_name,
          2: savedKeys.price,
        },
      }));
    }
  }, [savedKeys]);

  const UploadFile = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (!fileType) {
        e.target.value = "";
        return setUploadMessage({ type: 400, message: 'Please select a file type first.' });
      }
      if (!selectedFile) {
        return setUploadMessage({ type: 400, message: 'Please select a file first.' });
      }
      setSelectedFile(selectedFile)
      const formData = new FormData();
      formData.append('file', selectedFile);
      const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/products/upload-file/${fileType}`, formData, {
        headers: { 'x-access-token': token },
        onUploadProgress: (progressEvent) => {
          const totalLength = progressEvent.lengthComputable ? progressEvent.total : selectedFile.size;
          if (totalLength) {
            const currentProgress = Math.round((progressEvent.loaded * 100) / totalLength);
            setProgress(currentProgress);
          }
        },
      });
      setUploadMessage({ type: 200, message: response.data.message });
      setSelectedFile(''); setFileType('')
      e.target.value = "";
      handlerReloadProducts();
    } catch (error) {
      if (error.response) {
        setUploadMessage({ type: 400, message: error.response.data.message });
        setSelectedFile(''); setFileType('')
        e.target.value = "";
        setProgress(0)
      } else {
        setUploadMessage({ type: 400, message: 'An error occurred while uploading the file.' });
        setSelectedFile(''); setFileType('')
        e.target.value = "";
        setProgress(0)
      }
    }
  };

  const DeleteProduct = async (e) => {
    setLoadingBtn(true)
    try {
      const response = await axios.delete(`${process.env.REACT_APP_SERVER_URL}/products/delete-product/${ModalDelete}`, { headers: { 'x-access-token': token, } });
      Success(response.data.message); setLoadingBtn(false); setModalDelete(false); handlerReloadProducts();
    } catch (error) {
      if (error.response.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    }
  }

  const [data, setData] = useState({});
  const [selectedImage, setSelectedImage] = useState(null);

  function handleImageChange(e) {
    const file = e.target.files[0];
    setSelectedImage(file);
    const reader = new FileReader();
    reader.onload = function (e) {
      const imageUrl = e.target.result;
      document.querySelector('.profile-image').src = imageUrl;
    };
    if (file) {
      reader.readAsDataURL(file);
    }
  }

  function Editproduct(id) {
    setModalEdit(id);
    const filteredProduct = Products.find(item => item._id === id);
    const { _id, ...rest } = filteredProduct;
    setData(rest);
  }
  function handlerInputSChange(e) {
    setData({ ...data, [e.target.name]: e.target.value })
  }

  const UpdateProduct = async (e) => {
    setLoadingBtn(true)
    try {
      const formData = new FormData();
      if (selectedImage) {
        formData.append('image', selectedImage);
      }
      for (const key in data) {
        if (data.hasOwnProperty(key) && key !== 'companyName' && key !== 'image') {
          formData.append(key, data[key]);
        }
      }
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/products/update-product/${ModalEdit}`, formData, { headers: { 'x-access-token': token } });
      Success(response.data.message); setLoadingBtn(false); setModalEdit(false);
      handlerReloadProducts()
      setSelectedImage()
    } catch (error) {
      if (error.response.status === 401) {
        Error_specific(error.response.data.message); setLoadingBtn(false)
      } else {
        An_Error(); setLoadingBtn(false)
      }
    }
  }

  const handleFilterSearch = () => {
    getData(dataSourceFilter, true)
  }

  const allHeaders = Array.from(
    new Set(
      Products.flatMap(item => Object.keys(item))
    )
  );

  // change stock
  const [updatedStock, setUpdatedStock] = useState(new Array(Products).fill(false))
  const [LoadingSave, setLoadingSave] = useState(false)

  const toggleUpdateStock = (index) => {
    setUpdatedStock(prevQuantity => {
      const updatedQuantityCopy = [...prevQuantity];
      updatedQuantityCopy[index] = true;
      return updatedQuantityCopy;
    });
  };

  const changeQuantity = (ID, num) => {
    const temp = Products.map(product =>
      product._id === ID ? { ...product, [savedKeys?.stock]: parseInt(num) } : product
    );
    setProducts(temp)
  }

  const UpdateProductStock = async (productId, qnt, index) => {
    try {
      setLoadingSave(true)
      const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/products/update-product-stock`, { productId, qnt }, { headers: { 'x-access-token': token } });
      Success(response.data.message);
      handlerReloadProducts()
      setUpdatedStock(prevQuantity => { const updatedQuantityCopy = [...prevQuantity]; updatedQuantityCopy[index] = false; return updatedQuantityCopy; })
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    } finally {
      setLoadingSave(false)
    }
  }

  const handlerReloadProducts = () => {
    getData(dataSourceFilter, true)
  }

  return (
    <main class="h-max  pb-16  mb-4 ml-5 mr-5 mt-4">
      <motion.div
        initial={{ opacity: 0, x: -20 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: 20 }}
        transition={{ duration: 0.3 }}
      >
        <header className="px-3 py-2 flex justify-between">
          <h2 className="font-bold text-xl text-gray-800 dark:text-gray-200 mt-2">Products</h2>

        </header>
        <div class="bg-white pb-2 rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div class="py-2 px-3">
            <nav class="flex flex-wrap gap-4  text-gray-600 dark:text-gray-200">
              <div onClick={() => loading ? {} : SwitchData('All')} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${loading ? 'cursor-not-allowed border-b-0 ' : dataSourceFilter === "All" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'} `}> All </div>

              <div onClick={() => loading ? {} : SwitchData('GoogleSheets')} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${loading ? 'cursor-not-allowed border-b-0 ' : dataSourceFilter === "GoogleSheets" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'} `}> Google Sheets </div>

              <div onClick={() => loading ? {} : SwitchData('CSV')} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${loading ? 'cursor-not-allowed border-b-0 ' : dataSourceFilter === "CSV" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'}  `}> CSV Data </div>

              <div onClick={() => loading ? {} : SwitchData('Other')} class={`inline-flex whitespace-nowrap border-b-2 border-transparent py-2 px-3 text-sm font-medium  transition-all duration-200 ease-in-out hover:text-orange-600 cursor-pointer ${loading ? 'cursor-not-allowed border-b-0 ' : dataSourceFilter === "Other" ? 'text-orange-600  font-semibold' : 'hover:border-b-orange-600'} `}> Other </div>

              <Link to='/dashboard/products/add'>
                <button type="button" class="relative mr-auto mr-2 inline-flex  cursor-pointer items-center rounded-full border border-gray-200 bg-transparent px-5 py-2 text-center text-sm font-medium text-gray-800 dark:text-gray-200 hover:scale-110  focus:shadow  lg:ml-0 ">
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="mr-2 h-4 w-4">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 11-18 0 9 9 0 0118 0z" />
                  </svg>
                  Manually
                </button></Link>

              <button onClick={() => setModalImport(true)} type="button" class="relative mr-auto mr-2 inline-flex  cursor-pointer items-center rounded-full border border-gray-200 bg-transparent px-5 py-2 text-center text-sm font-medium text-gray-800 dark:text-gray-200 hover:scale-110  focus:shadow  lg:ml-0 ">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="w-4 h-4 mr-2">
                  <path stroke-linecap="round" stroke-linejoin="round" d="M3 16.5v2.25A2.25 2.25 0 005.25 21h13.5A2.25 2.25 0 0021 18.75V16.5M16.5 12L12 16.5m0 0L7.5 12m4.5 4.5V3" />
                </svg>
                Import Data
              </button>
            </nav>

          </div>
          <div class="flex w-full flex-row items-center justify-between space-y-2 sm:flex-row sm:space-y-0 py-2">
            <div class="relative flex flex-col md:flex-row w-full ml-4 max-w-xl items-center">
              <svg class="absolute left-2 md:left-4 block h-5 w-5 mt-3 md:mt-0 text-gray-400" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
                <circle cx="11" cy="11" r="8" class=""></circle>
                <line x1="21" y1="21" x2="16.65" y2="16.65" class=""></line>
              </svg>
              <input type="name" name="search" value={searchFilter} onChange={(e) => setSearchFilter(e.target.value)} onKeyPress={(event) => event.key === 'Enter' && handleFilterSearch()}
                class=" w-full bg-gray-50 dark:bg-gray-900 dark:text-white border border-gray-200 dark:border-gray-600 rounded-lg bg-transparent py-3 pl-9 md:pl-12 text-sm outline-none focus:shadow" placeholder={`Search by ${keys.Search[0]}, ${keys.Search[1]}, ${keys.Search[2]}`} />
              <button onClick={() => handleFilterSearch()} className="absolute right-2 top-2 rounded-lg px-4 py-1 bg-gray-500 text-white hover:scale-95 transition-all duration-300 text-sm font-normal">
                Search
              </button>
            </div>
          </div>

        </div>

        <div className="flex space-x-4 mt-5">
          {dataSourceFilter === "GoogleSheets" &&
            <button type="button" onClick={() => setModalSetting(true)} class="ml-auto relative mb-2 inline-flex  cursor-pointer items-center rounded-xl  bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700 px-6 py-2 text-center text-sm font-bold text-orange-500 dark:text-orange-900 dark:text-gray-50 hover:scale-110  focus:shadow ">
              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="mr-2 h-4 w-4">
                <path stroke-linecap="round" stroke-linejoin="round" d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.324.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 011.37.49l1.296 2.247a1.125 1.125 0 01-.26 1.431l-1.003.827c-.293.24-.438.613-.431.992a6.759 6.759 0 010 .255c-.007.378.138.75.43.99l1.005.828c.424.35.534.954.26 1.43l-1.298 2.247a1.125 1.125 0 01-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.57 6.57 0 01-.22.128c-.331.183-.581.495-.644.869l-.213 1.28c-.09.543-.56.941-1.11.941h-2.594c-.55 0-1.02-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 01-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 01-1.369-.49l-1.297-2.247a1.125 1.125 0 01.26-1.431l1.004-.827c.292-.24.437-.613.43-.992a6.932 6.932 0 010-.255c.007-.378-.138-.75-.43-.99l-1.004-.828a1.125 1.125 0 01-.26-1.43l1.297-2.247a1.125 1.125 0 011.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.087.22-.128.332-.183.582-.495.644-.869l.214-1.281z" />
                <path stroke-linecap="round" stroke-linejoin="round" d="M15 12a3 3 0 11-6 0 3 3 0 016 0z" />
              </svg>
              Settings
            </button>}
        </div>

        <div className="p-6 bg-white rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className="overflow-x-auto">
            <InfiniteScroll
              dataLength={Products.length}
              next={() => getData(dataSourceFilter)}
              hasMore={hasMore}
              loader={loading && <div className="py-5 px-4  overflow-y-auto  flex justify-center items-center">
                <DotSpinner
                  size={30}
                  speed={0.9}
                  color="gray"
                />
              </div>}
              scrollThreshold={0.8}
            >
              {Products.length > 0 ?
                <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                  <thead className="font-bold  border-b border-gray-200 dark:border-gray-700 lg:table-header-group">
                    <motion.tr initial={{ opacity: 0, x: -50 }}
                      animate={{ opacity: 1, x: 0 }}
                      exit={{ opacity: 0, x: -50 }}
                      transition={{ duration: 0.5, delay: 0.1 }} className="text-gray-600 dark:text-gray-100">
                      {allHeaders && allHeaders.map((key, subIndex) => {
                        if (key !== '_id' && key !== 'companyName' && key !== 'image') {
                          return (
                            <th className={`whitespace-normal py-4 text-sm px-4`} key={subIndex}>
                              <div className="text-left">{key}</div>
                            </th>
                          )
                        }
                      })}
                      <th className=""></th>
                    </motion.tr>
                  </thead>
                  <tbody className="text-sm ">
                    {Products.length > 0 && Products.map((item, index) => {
                      return (
                        <motion.tr initial={{ opacity: 0, x: -50 }}
                          animate={{ opacity: 1, x: 0 }}
                          exit={{ opacity: 0, x: -50 }}
                          transition={{ duration: 0.5, delay: 0.5 }} className={`text-gray-600 font-meduim dark:text-gray-200  ${index !== Products.length - 1 && 'border-b border-gray-200 dark:border-gray-700'} `} key={index}>
                          {allHeaders && allHeaders.map((key, subIndex) => {
                            if (key !== '_id') {
                              const value = item[key] || '';
                              if (key === savedKeys?.product_name) {
                                return (
                                  <td className="whitespace-no-wrap px-3 py-4 text-sm lg:table-cell" key={subIndex}>
                                    <div class="flex-shrink-0 inline-flex ">
                                      <img loading="lazy" class="object-cover w-8 h-8 rounded-full border hover:scale-125 ease-in duration-500"
                                        src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                        onError={(e) => e.target.src = "https://cdns.iconmonstr.com/wp-content/releases/preview/2019/240/iconmonstr-product-3.png"}
                                        alt="product Iamge" />
                                      <div className="ml-4 mr-6 md:mr-0">
                                        <p class="text-sm font-medium dark:text-gray-400">{value}</p>
                                        <p title={item._id} class="text-xs text-gray-500 w-[10vh] truncate dark:text-gray-400">#{item?._id}</p>
                                      </div>

                                    </div>
                                  </td>
                                );
                              } else if (key === savedKeys?.stock) {
                                const oldStock = tempProducts.find((i) => i._id === item._id)?.[savedKeys?.stock]
                                return (
                                  <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3">
                                    <div class="flex flex-row h-10  rounded-lg relative bg-transparent mt-1">
                                      <div className="bg-gray-300 dark:bg-gray-700 text-gray-700 text-center font-normal text-md dark:text-gray-300 h-full w-28 rounded mr-4 flex items-center justify-center">
                                        {oldStock}
                                      </div>
                                      {!updatedStock[index] && <div onClick={() => toggleUpdateStock(index)} className="text-gray-500 text-center font-semibold text-md dark:text-gray-400 h-full rounded flex items-center justify-center cursor-pointer hover:scale-90 hover:text-orange-500 transition duration-300">
                                        <svg id="Layer_1" data-name="Layer 1" fill="currentColor" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 121.51"><title>edit</title><path d="M28.66,1.64H58.88L44.46,16.71H28.66a13.52,13.52,0,0,0-9.59,4l0,0a13.52,13.52,0,0,0-4,9.59v76.14H91.21a13.5,13.5,0,0,0,9.59-4l0,0a13.5,13.5,0,0,0,4-9.59V77.3l15.07-15.74V92.85a28.6,28.6,0,0,1-8.41,20.22l0,.05a28.58,28.58,0,0,1-20.2,8.39H11.5a11.47,11.47,0,0,1-8.1-3.37l0,0A11.52,11.52,0,0,1,0,110V30.3A28.58,28.58,0,0,1,8.41,10.09L8.46,10a28.58,28.58,0,0,1,20.2-8.4ZM73,76.47l-29.42,6,4.25-31.31L73,76.47ZM57.13,41.68,96.3.91A2.74,2.74,0,0,1,99.69.38l22.48,21.76a2.39,2.39,0,0,1-.19,3.57L82.28,67,57.13,41.68Z" /></svg>
                                      </div>}
                                      {updatedStock[index] && <>
                                        <button onClick={() => { item[savedKeys?.stock] > 0 && changeQuantity(item._id, (item[savedKeys?.stock] - 1)) }} title="decrement"
                                          class={`bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-100  hover:bg-gray-400 h-full w-20 rounded-l ${item[savedKeys?.stock] === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}  focus:outline-none`}>
                                          <span class="m-auto text-2xl font-thin">−</span>
                                        </button>
                                        <input type="number" value={item[savedKeys?.stock]} onChange={(e) => changeQuantity(item._id, !e.target.value ? 0 : e.target.value)} class="outline-none border-none focus:outline-none focus:ring-0 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-center w-20 bg-gray-300 dark:bg-gray-700  font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 dark:text-gray-300  outline-none" />
                                        <button onClick={() => changeQuantity(item._id, (parseInt(item[savedKeys?.stock]) + 1))} title="increment" class="bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer focus:outline-none">
                                          <span class="m-auto text-2xl font-thin">+</span>
                                        </button>
                                        <div className="flex flex-row w-48">
                                          <button title="cancel" onClick={() => {
                                            setUpdatedStock(prevQuantity => {
                                              const updatedQuantityCopy = [...prevQuantity];
                                              updatedQuantityCopy[index] = false;
                                              return updatedQuantityCopy;
                                            })
                                            getData(dataSourceFilter, true)
                                          }} class="bg-gray-300 ml-9 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer flex items-center focus:outline-none">
                                            <span class="m-auto text-2xl font-thin">
                                              <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                              </svg>
                                            </span>
                                          </button>
                                          <button onClick={async () => { !LoadingSave && UpdateProductStock(item._id, item[savedKeys?.stock], index) }} title="double click to save" class="bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer flex items-center focus:outline-none">
                                            <span class="m-auto text-2xl font-thin">
                                              {LoadingSave ?
                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 animate-spin">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M16.023 9.348h4.992v-.001M2.985 19.644v-4.992m0 0h4.992m-4.993 0 3.181 3.183a8.25 8.25 0 0 0 13.803-3.7M4.031 9.865a8.25 8.25 0 0 1 13.803-3.7l3.181 3.182m0-4.991v4.99" />
                                                </svg>

                                                : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                  <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                </svg>}
                                            </span>
                                          </button>
                                        </div>
                                      </>}

                                    </div>

                                  </td>
                                )
                              } else if (key !== 'image' && key !== 'companyName') {
                                return (
                                  <td className={`whitespace-no-wrap px-3 py-5 text-sm`} key={subIndex}>
                                    <p className="">{value}</p>
                                  </td>
                                );
                              }
                            }
                            return null
                          })}
                          <td className="px-4 py-2 w-20">
                            <div className="flex items-center text-left">
                              <button
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-orange-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                title="Landing Page"
                                onClick={() => navigate(`/dashboard/products/Landingpage/${item._id}`)}
                              >
                                <svg id="Layer_1" data-name="Layer 1" fill="currentColor" className="w-4 h-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 100.72"><defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill-rule:evenodd;}" }} /></defs><path className="cls-1" d="M111.34,93.69a7.56,7.56,0,0,1-7.52,7H7.54A7.56,7.56,0,0,1,0,93.18V19.51c0-3.69,3.08-6.77,6.58-7.42V30.18H4.79V93.91A2.08,2.08,0,0,0,6.88,96h97.91a2.09,2.09,0,0,0,2.09-2.09v-.22ZM22.94,23.94h87.7V61.11H22.94V23.94ZM19.06,0h96.28a7.56,7.56,0,0,1,7.54,7.54V81.21a7.56,7.56,0,0,1-7.54,7.54H19.06a7.56,7.56,0,0,1-7.54-7.54V7.54A7.56,7.56,0,0,1,19.06,0Zm99.37,18.22H16.31V82A2.07,2.07,0,0,0,18.4,84h97.91A2.07,2.07,0,0,0,118.39,82V18.22ZM108.16,6.54a3.73,3.73,0,1,1-3.72,3.73,3.73,3.73,0,0,1,3.72-3.73Zm-25.23,0a3.73,3.73,0,1,1-3.72,3.73,3.73,3.73,0,0,1,3.72-3.73Zm12.62,0a3.73,3.73,0,1,1-3.73,3.73,3.73,3.73,0,0,1,3.73-3.73ZM72.12,74.33a3.18,3.18,0,0,1,0-6.34h35.93a3.18,3.18,0,0,1,0,6.34Zm-47,0a3.18,3.18,0,0,1,0-6.34H58.39a3.18,3.18,0,0,1,0,6.34Z" /></svg>
                              </button>
                              <button
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-orange-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                title="Offers management"
                                onClick={() => setModalOffers(item._id)}
                              >
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="w-4 h-4" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="121.014px" height="122.878px" viewBox="0 0 121.014 122.878" enableBackground="new 0 0 121.014 122.878" xmlSpace="preserve"><g><path fillRule="evenodd" clipRule="evenodd" d="M63.769,3.827l54.715,59.579c3.695,4.021,3.272,10.385-0.802,14.019 l-48.172,42.95c-4.069,3.63-10.388,3.27-14.018-0.805L4.112,61.934l0.098-1.699L0,0l61.944,3.922 C62.54,3.881,63.147,3.849,63.769,3.827L63.769,3.827z M24.269,11.894c6.141,0.136,11.013,5.227,10.877,11.368 c-0.136,6.142-5.227,11.013-11.368,10.877c-6.141-0.136-11.013-5.227-10.877-11.368C13.036,16.629,18.127,11.758,24.269,11.894 L24.269,11.894z M33.948,53.765l35.36,38.527l-7.048,6.472L26.9,60.233L33.948,53.765L33.948,53.765z M63.797,26.51L99.16,65.04 l-7.052,6.468l-35.36-38.527L63.797,26.51L63.797,26.51z M49.586,39.811l35.363,38.527l-7.052,6.469L42.538,46.279L49.586,39.811 L49.586,39.811z" /></g></svg>
                              </button>
                              <button
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-orange-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                title="Edit Order information"
                                onClick={() => Editproduct(item._id)}
                              >
                                <svg
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    d="M13.586 3.586a2 2 0 112.828 2.828l-.793.793-2.828-2.828.793-.793zM11.379 5.793L3 14.172V17h2.828l8.38-8.379-2.83-2.828z"
                                  ></path>
                                </svg>
                              </button>
                              <button
                                className="flex items-center justify-between px-2 py-2 text-sm font-medium leading-5 text-orange-600 rounded-lg dark:text-gray-400 focus:outline-none focus:shadow-outline-gray"
                                title="Delete order!"
                                onClick={() => setModalDelete(item._id)}
                              >
                                <svg
                                  className="w-5 h-5"
                                  aria-hidden="true"
                                  fill="currentColor"
                                  viewBox="0 0 20 20"
                                >
                                  <path
                                    fillRule="evenodd"
                                    d="M9 2a1 1 0 00-.894.553L7.382 4H4a1 1 0 000 2v10a2 2 0 002 2h8a2 2 0 002-2V6a1 1 0 100-2h-3.382l-.724-1.447A1 1 0 0011 2H9zM7 8a1 1 0 012 0v6a1 1 0 11-2 0V8zm5-1a1 1 0 00-1 1v6a1 1 0 102 0V8a1 1 0 00-1-1z"
                                    clipRule="evenodd"
                                  ></path>
                                </svg>
                              </button>
                            </div>
                          </td>
                        </motion.tr>
                      )
                    })}
                  </tbody>
                </table>
                : !loading &&
                <div className="py-20 animate-pulse flex flex-col items-center justify-center text-gray-400 dark:text-gray-700">
                  <svg xmlns="http://www.w3.org/2000/svg" className="w-44 h-44" fill="currentColor" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 406.8"><path d="M29.27 0h373.81c16.11 0 29.27 13.16 29.27 29.27v90.8c-2.23-1.1-4.48-2.14-6.77-3.13l-2.02-.92c-3.57-1.47-7.18-2.82-10.84-4.02V81.94h.14H20.83v221.61c0 4.5 3.59 8.09 8.09 8.09h193.04c.54 1.42 1.1 2.83 1.69 4.24 1.97 4.78 4.23 9.49 6.73 14.09H29.27C13.16 329.97 0 316.78 0 300.7V29.27C0 13.16 13.16 0 29.27 0zm335.71 140.37c31.07 0 60.75 12.29 82.72 34.27 30.33 30.33 41.62 75.06 29.4 116.14-2.83 9.53-6.85 18.55-11.88 26.89l45.3 49.38c2.09 2.27 1.95 5.82-.33 7.91l-33.26 30.38c-2.27 2.08-5.8 1.92-7.89-.35l-43.34-47.67c-18.35 11.18-39.22 17.02-60.72 17.02-31.06 0-60.75-12.3-82.71-34.27a117.18 117.18 0 0 1-25.39-37.99c-18.13-43.67-7.82-94.22 25.4-127.43a116.814 116.814 0 0 1 37.96-25.4c13.8-5.73 28.92-8.88 44.74-8.88zm70.93 46.04c-28.63-28.63-72.03-37.24-109.31-21.8-66.92 27.71-82.3 113.99-32.58 163.67 28.68 28.61 72 37.26 109.33 21.81 37.54-15.59 61.94-52.1 61.94-92.74 0-13.62-2.69-26.59-7.56-38.37a101.013 101.013 0 0 0-21.82-32.57zm-28.22 97.67-16.4 16.31-26.48-26.49-26.34 26.34-15.82-15.86 26.32-26.32-26.85-26.84 16.4-16.31 26.8 26.81 26.51-26.51 15.81 15.85-26.49 26.49 26.54 26.53zM375.21 32.9c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.98 0-14.46-6.48-14.46-14.46.04-7.99 6.51-14.46 14.46-14.46zm-97.95 0c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.99 0-14.46-6.48-14.46-14.46 0-7.99 6.47-14.46 14.46-14.46zm48.98 0c7.98 0 14.45 6.47 14.45 14.46 0 7.98-6.47 14.46-14.45 14.46-7.99 0-14.47-6.48-14.47-14.46 0-7.99 6.48-14.46 14.47-14.46z" /></svg>
                  <p className="font-normal text-3xl mt-4 text-center">Sad no result!</p>
                  <p className="font-normal text-xl text-center">Currently, there are no products available for display...</p>
                </div>
              }
            </InfiniteScroll>
          </div>

        </div>

        <AnimatePresence>
          {ModalImport &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }}
                class="flex items-center justify-center mx-auto min-h-screen  text-center">
                <div ref={ref} class="sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 sm:max-w-lg ">
                  <div className="relative bg-orange-600 py-6 pl-8 text-xl font-semibold uppercase tracking-wider text-white">
                    Upload Files
                    <svg onClick={() => setModalImport(false)} xmlns="http://www.w3.org/2000/svg" className="absolute top-0 right-0 m-5 h-6 w-6 cursor-pointer" fill="none" viewBox="0 0 24 24" stroke="currentColor" strokeWidth={2}>
                      <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>
                  <div className="space-y-4 px-4 py-5">
                    <h2 class="text-base text-gray-900 font-bold dark:text-gray-100">File Type</h2>
                    <div class="mt-3 flex select-none flex-wrap items-center gap-3 items-center justify-center dark:text-gray-300">
                      <label onClick={() => setFileType('XLSX')}>
                        <p class={`rounded-lg border border-black px-6 py-2 font-bold cursor-pointer ${fileType === 'XLSX' && 'bg-black text-white'}`}>Xlsx File</p>
                      </label>
                      <label onClick={() => setFileType('CSV')}>
                        <p class={`rounded-lg border border-black px-6 py-2 font-bold cursor-pointer ${fileType === 'CSV' && 'bg-black text-white'}`}>CSV File</p>
                      </label>
                      <label onClick={() => setFileType('JSON')}>
                        <p class={`rounded-lg border border-black px-6 py-2 font-bold cursor-pointer ${fileType === 'JSON' && 'bg-black text-white'}`}>JSON File</p>
                      </label>
                    </div>
                    {progress > 0 &&
                      <div>
                        <span className="text-gray-600">{uploadMessage && uploadMessage.type === 200 ? selectedFile.name : 'Downloading...'}</span>
                        <div className="h-2 overflow-hidden rounded-full bg-gray-300">
                          <div className="h-full bg-orange-500" style={{ width: `${progress}%` }} />
                        </div>
                      </div>}
                    <div className="flex flex-col items-center justify-center rounded-lg border-4 border-dashed px-4 py-10">
                      <svg className="h-14 -rotate-90" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 512 512" enableBackground="new 0 0 512 512" xmlSpace="preserve">
                        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="326.4185" y1="382.5204" x2="326.4185" y2="128.8734" className="gradient-element">
                          <stop offset={0} className="primary-color-gradient" style={{ stopColor: '#ABDCFF' }} />
                          <stop offset={1} className="secondary-color-gradient" style={{ stopColor: '#0396FF' }} />
                        </linearGradient>
                        <path fill="url(#SVGID_1_)" d="M466.1,249.8l-122-122c-4.6-4.6-12.4-1.3-12.4,5.1v95.2c0,29.3-23.8,53.1-53.1,53.1H193	c-4.9,0-8.8,4-8.8,8.8v16.8c0,4.8,3.9,8.7,8.7,8.7h91.7c4.8,0,8.7,3.9,8.7,8.7v89.6c0,7.8,9.4,11.6,14.9,6.2l157.9-157.9	C469.5,258.8,469.5,253.2,466.1,249.8z" />
                        <path d="M302,430.6c-2.2,0-4.3-0.4-6.4-1.3c-6.3-2.6-10.3-8.7-10.3-15.4v-89.6c0-0.4-0.3-0.7-0.7-0.7H123.1	c-9.2,0-16.7-7.5-16.7-16.7v-25.7c0-4.4,3.6-8,8-8s8,3.6,8,8v25.7c0,0.4,0.3,0.7,0.7,0.7h161.5c9.2,0,16.7,7.5,16.7,16.7v89.6	c0,0.2,0,0.5,0.4,0.7c0.4,0.2,0.6,0,0.8-0.2l157.9-157.9c0.3-0.3,0.3-0.7,0-1L302.5,97.6c-0.1-0.1-0.3-0.3-0.8-0.2	c-0.4,0.2-0.4,0.5-0.4,0.7v89.6c0,9.2-7.5,16.7-16.7,16.7h-197c-4.4,0-8-3.6-8-8s3.6-8,8-8h197c0.4,0,0.7-0.3,0.7-0.7V98.1	c0-6.8,4-12.8,10.3-15.4c6.3-2.6,13.4-1.2,18.2,3.6l157.9,157.9c6.5,6.5,6.5,17.1,0,23.6L313.8,425.7	C310.6,428.9,306.4,430.6,302,430.6z" />
                        <path fill="#0396FF" className="secondary-color" d="M29.6,128.1V25.5h21.8v102.6H29.6z M51.4,249V146.4H29.6V249H51.4z" />
                        <path fill="#ABDCFF" className="primary-color" d="M484.2,374.2v102.6h-91.4L484.2,374.2z" />
                        <path d="M35.4,281.3c0-4.4,3.6-8,8-8h112.8c4.4,0,8,3.6,8,8s-3.6,8-8,8H43.4C38.9,289.3,35.4,285.7,35.4,281.3z M235.4,372.2	c4.4,0,8-3.6,8-8s-3.6-8-8-8H43.4c-4.4,0-8,3.6-8,8s3.6,8,8,8H235.4z M417.3,45.3c-4.4,0-8,3.6-8,8c0,11.4-9.3,20.8-20.8,20.8	c-4.4,0-8,3.6-8,8s3.6,8,8,8c20.3,0,36.8-16.5,36.8-36.8C425.3,48.8,421.7,45.3,417.3,45.3z M467.3,90c4.4,0,8-3.6,8-8s-3.6-8-8-8	c-11.4,0-20.8-9.3-20.8-20.8c0-4.4-3.6-8-8-8s-8,3.6-8,8C430.5,73.5,447,90,467.3,90z M467.3,95.3c-20.3,0-36.8,16.5-36.8,36.8	c0,4.4,3.6,8,8,8s8-3.6,8-8c0-11.4,9.3-20.8,20.8-20.8c4.4,0,8-3.6,8-8S471.7,95.3,467.3,95.3z M388.5,95.3c-4.4,0-8,3.6-8,8	s3.6,8,8,8c11.4,0,20.8,9.3,20.8,20.8c0,4.4,3.6,8,8,8s8-3.6,8-8C425.3,111.7,408.8,95.3,388.5,95.3z M132.4,105.4c0,4.4,3.6,8,8,8	h112.8c4.4,0,8-3.6,8-8s-3.6-8-8-8H140.4C136,97.4,132.4,101,132.4,105.4z M261.2,154c0,5-4.1,9-9,9s-9-4.1-9-9s4.1-9,9-9	S261.2,149.1,261.2,154z M222.2,145c5,0,9,4.1,9,9s-4.1,9-9,9s-9-4.1-9-9S217.2,145,222.2,145z M192.2,145c5,0,9,4.1,9,9s-4.1,9-9,9	s-9-4.1-9-9S187.2,145,192.2,145z M95,452.9c0,14.6-11.8,26.4-26.4,26.4s-26.4-11.8-26.4-26.4s11.8-26.4,26.4-26.4	S95,438.3,95,452.9z M79,452.9c0-5.7-4.7-10.4-10.4-10.4s-10.4,4.7-10.4,10.4s4.7,10.4,10.4,10.4S79,458.6,79,452.9z M353.7,459.9	H240.8c-4.4,0-8,3.6-8,8s3.6,8,8,8h112.8c4.4,0,8-3.6,8-8S358.1,459.9,353.7,459.9z M92.7,52.1h112.8c4.4,0,8-3.6,8-8s-3.6-8-8-8	H92.7c-4.4,0-8,3.6-8,8S88.2,52.1,92.7,52.1z" />
                      </svg>
                      <p className="mt-4 text-center text-xl font-medium text-gray-800 dark:text-gray-200">
                        Drop Files here or
                        <label className="shadow-orange-100 mt-2 block rounded-full border bg-white px-4 py-0.5 font-normal text-gray-500 shadow hover:bg-blue-50">
                          <input onChange={(e) => UploadFile(e)} className="hidden" type="file" name="file" id="fileInput" />
                          browse</label>
                      </p>
                    </div>
                    {uploadMessage && <p class={`mt-2 text-lg  ${uploadMessage.type === 200 ? 'text-green-600 dark:text-green-500' : 'text-red-600 dark:text-red-500'}`}>
                      {uploadMessage.message}</p>}
                  </div>
                </div>


              </motion.div>
            </motion.div>
          }

          {ModalDelete &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">

                <div ref={ref} class="m-10 flex max-w-lg flex-col items-center border px-8 py-10 text-gray-800  sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 sm:max-w-lg ">
                  <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 rounded-xl bg-red-50 p-2 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                  </svg>
                  <p class="mt-4 text-center text-xl font-bold dark:text-gray-200">Deleting Product</p>
                  <p class="mt-2 text-center text-lg dark:text-gray-400">Are you sure you want to delete the Product with id  <span class="truncate font-bold">`{ModalDelete}`</span>?</p>
                  <div class="mt-8 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                    <button class="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white" onClick={() => DeleteProduct()}>
                      {LoadingBtn ? <div class="flex items-center justify-center ">
                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                          <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                          <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                        </svg>
                        <div class="ml-2"> Processing.... </div>
                      </div> : "Yes, delete this Order"}
                    </button>
                    {!LoadingBtn && <button class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium" onClick={() => setModalDelete(false)}>Cancel, keep this Order</button>}
                  </div>
                </div>

              </motion.div>
            </motion.div>
          }

          {ModalEdit &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30" style={{ backgroundColor: 'rgba(0, 0, 0, 0.5)' }}
            >
              <motion.div ref={ref} initial={{ x: 400, opacity: 0 }}
                animate={{ x: 0, opacity: 1 }}
                exit={{ x: 400, opacity: 0 }}
                transition={{ duration: 0.4, ease: "easeOut" }} class="fixed right-0 top-0 z-30 h-screen w-[100%] md:w-1/4 h-screen max-w-full bg-white dark:bg-gray-900 shadow-xl transition-all overflow-y-auto">

                <div class="modal-header px-5 py-4 bg-orange-600 text-gray-900 w-full">
                  <h5 class="text-white text-2xl uppercase">
                    Product no. <span className="max-w-[25vh] overflow-hidden whitespace-nowrap overflow-ellipsis inline-flex">#{data[savedKeys.id]}</span></h5>
                </div>

                <div class="flex flex-col w-full px-5 mt-8 text-gray-800 dark:text-gray-200">
                  <img loading="lazy" onClick={() => document.querySelector('#file_input').click()} class="w-32 h-32 mx-auto profile-image cursor-pointer rounded-full border-[3px] border-gray-200 p-1"
                    src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${data.companyName.replace(/\s/g, '_')}/${data.image}`}
                    onError={(e) => e.target.src = "https://cdns.iconmonstr.com/wp-content/releases/preview/2019/240/iconmonstr-product-3.png"}
                    alt="productImage" />
                  <input onChange={(e) => handleImageChange(e)} accept="image/*" hidden="true" id="file_input" type="file" />
                  {Object.keys(data).map(key => {
                    if (key !== 'image' && key !== 'companyName' && key !== savedKeys.id) {
                      return (
                        <div class="relative ">
                          <label class="mt-3 mb-2 block text-sm font-medium float-left">{key}</label>
                          <input type="text" defaultValue={data[key]} name={key} onChange={(e) => handlerInputSChange(e)} class="peer w-full rounded-md border border-gray-200 px-4 py-3 pl-11 text-sm shadow-sm dark:bg-gray-900 outline-none focus:z-10 focus:border-orange-500 focus:ring-orange-500" />
                        </div>
                      )
                    }
                    return null;
                  })}
                </div>

                <div class="py-6 px-4 flex flex-col items-center gap-3">
                  <button type="button" onClick={() => setModalEdit(false)} class="w-full py-2.5 text-sm font-medium tracking-wide text-gray-700 capitalize transition-colors duration-300 transform border border-gray-200 rounded-md sm:w-1/2 dark:text-gray-200 dark:border-gray-700 dark:hover:bg-gray-800 hover:bg-gray-100 focus:outline-none focus:ring focus:ring-gray-300 focus:ring-opacity-40">
                    Cancel
                  </button>

                  <button onClick={() => !LoadingBtn && UpdateProduct()} class="w-full py-2.5  text-sm font-medium tracking-wide text-white capitalize transition-colors duration-300 transform bg-orange-600 rounded-md sm:w-1/2  hover:bg-orange-500 focus:outline-none focus:ring focus:ring-orange-300 focus:ring-opacity-40">
                    {LoadingBtn ? <div class="flex items-center justify-center ">
                      <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                        <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                      </svg>
                      <div class="ml-2"> Processing.... </div>
                    </div> : "Save Changes"}
                  </button>
                </div>
              </motion.div>
            </motion.div>
          }

          {ModalSetting &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">
                <div ref={ref} class="relative inline-block  overflow-hidden text-left align-bottom transition-all transform bg-gray-50 rounded-lg shadow-xl dark:bg-gray-900 w-11/12 md:w-2/4  sm:align-middle">
                  <div class="modal-header p-5 bg-orange-700 rounded-t">
                    <h5 class="text-white text-2xl uppercase">Settings</h5>
                    <svg onClick={() => setModalSetting(false)} xmlns="http://www.w3.org/2000/svg" class="absolute top-0 right-0 m-5 h-9 w-9 cursor-pointer text-white hover:text-orange-900" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                    </svg>
                  </div>

                  <div class="col-span-8  bg-gray-50 dark:bg-gray-900 px-8 mb-20 overflow-y-auto " >

                    <SheetsManage collection="products" />

                  </div>
                </div>

              </motion.div>
            </motion.div>
          }

          {modalOffers &&
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
            >
              <motion.div initial={{ opacity: 0, scale: 0.8 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.8 }}
                transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">
                <div ref={ref} class="relative inline-block overflow-hidden text-left align-bottom transition-all transform bg-gray-50 rounded-xl shadow-xl dark:bg-gray-900 w-11/12 md:w-3/5  sm:align-middle">
                  <Offers id={modalOffers} token={token} />
                </div>

              </motion.div>
            </motion.div>
          }
        </AnimatePresence>
      </motion.div>
    </main>
  )
}