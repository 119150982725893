import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { An_Error, Error_specific, Success } from "../../component/alert";
import { format, subYears } from 'date-fns';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';

const token = localStorage.getItem('token') || sessionStorage.getItem('token')
const role = localStorage.getItem('role') || sessionStorage.getItem('role')

export default function Main() {
  const [overallStats, setOverallStats] = useState()
  const [Loading, setLoading] = useState()
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [monthlyAnalytics, setMonthlyAnalytics] = useState([]);
  const [statusesAnalytics, setStatusesAnalytics] = useState([]);

  const getOverallStats = async () => {
    try {
      setLoading(true)
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/statistics/overall-stats`, { headers: { 'x-access-token': token , 'x-access-role': role } });
      setOverallStats(response.data)
    } catch (error) {
      if (error.response?.status === 401) {
        Error_specific(error.response.data.message)
      } else {
        An_Error()
      }
    } finally {
      setLoading(false)
    }
  }

  const getOrdersAnalytics = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/statistics/orders-analytics/${selectedYear}`, { headers: { 'x-access-token': token , 'x-access-role': role } });
      setMonthlyAnalytics(response.data.analytics)
    } catch (error) {
      An_Error()
    } finally {
    }
  }

  const getOrdersStatusAnalytics = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/statistics/orders-status-analytics/${selectedYear}`, { headers: { 'x-access-token': token , 'x-access-role': role  } });
      setStatusesAnalytics(response.data.analytics)
    } catch (error) {
      An_Error()
    } finally {
    }
  }

  useEffect(() => {
    getOverallStats()
  }, [])

  useEffect(() => {
    getOrdersAnalytics()
    getOrdersStatusAnalytics()
  }, [selectedYear])

  const getHexColor = (colorName) => {
    // Use Tailwind CSS classes to generate the color
    const div = document.createElement('div');
    div.className = `bg-${colorName} h-0 w-0`;
    document.body.appendChild(div);
    const hexColor = window.getComputedStyle(div).backgroundColor;
    document.body.removeChild(div);
    return hexColor;
  };
  
  return (
    <main class="mb-5 ml-5 mr-5 mt-8">
      <div className="flex flex-col md:flex-row gap-6">
        <div className="w-full  rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className="flex-auto p-6">
            <div className="flex flex-wrap">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 className="text-gray-800 dark:text-gray-200 font-bold text-lg">Today's Orders <span className='text-xs'>/ Total Orders</span></h5>
                <h3 className="text-gray-500 dark:text-gray-400 mt-4"><span className={`${overallStats?.Orders?.changePercentage > 0 ? "text-green-500" : "text-red-500"} mr-1 font-extrabold`}>
                  <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                    <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                  </svg>
                  {Math.abs(overallStats?.Orders?.changePercentage || 0).toFixed(2)}% </span> {overallStats?.Orders?.changePercentage > 0 ? "More" : "Less"} than yesterday</h3>
              </div>
            </div>
            <div className="flex ">
              <p className="text-xl text-black dark:text-white font-extrabold mt-4">{overallStats?.Orders?.today || 0} <span className='text-xs'>/ {overallStats?.Orders?.total || 0}</span></p>
            </div>
          </div>
        </div>
        <div className="w-full  rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className="flex-auto p-6">
            <div className="flex flex-wrap">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 className="text-gray-800 dark:text-gray-200 font-bold text-lg">Today's Products <span className='text-xs'>/ Total Products</span></h5>
                <h3 className="text-gray-500 dark:text-gray-400 mt-4"><span className={`${overallStats?.Products?.changePercentage > 0 ? "text-green-500" : "text-red-500"} mr-1 font-extrabold`}>
                  {overallStats?.Products?.changePercentage > 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                    </svg>}
                  {Math.abs(overallStats?.Products?.changePercentage || 0).toFixed(2)}% </span> {overallStats?.Products?.changePercentage > 0 ? "More" : "Less"} than yesterday</h3>
              </div>
            </div>
            <div className="flex ">
              <p className="text-xl text-black dark:text-white font-extrabold mt-4">{overallStats?.Products?.today || 0} <span className='text-xs'>/ {overallStats?.Products?.total || 0}</span></p>
            </div>
          </div>
        </div>

        <div className="w-full  rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className="flex-auto p-6">
            <div className="flex flex-wrap">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 className="text-gray-800 dark:text-gray-200 font-bold text-lg">Today's Returns <span className='text-xs'>/ Total Returns</span></h5>
                <h3 className="text-gray-500 dark:text-gray-400 mt-4"><span className={`${overallStats?.Returns?.changePercentage > 0 ? "text-green-500" : "text-red-500"} mr-1 font-extrabold`}>
                  {overallStats?.Returns?.changePercentage > 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                    </svg>}
                  {Math.abs(overallStats?.Returns?.changePercentage || 0).toFixed(2)}% </span> {overallStats?.Returns?.changePercentage > 0 ? "More" : "Less"} than yesterday</h3>
              </div>
            </div>
            <div className="flex ">
              <p className="text-xl text-black dark:text-white font-extrabold mt-4">{overallStats?.Returns?.today || 0} <span className='text-xs'>/ {overallStats?.Returns?.total || 0}</span></p>
            </div>
          </div>
        </div>

        <div className="w-full  rounded-2xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className="flex-auto p-6">
            <div className="flex flex-wrap">
              <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
                <h5 className="text-gray-800 dark:text-gray-200 font-bold text-lg">Today's Revenue <span className='text-xs'>/ Total Revenue</span></h5>
                <h3 className="text-gray-500 dark:text-gray-400 mt-4"><span className={`${overallStats?.Revenue?.changePercentage > 0 ? "text-green-500" : "text-red-500"} mr-1 font-extrabold`}>
                  {overallStats?.Revenue?.changePercentage > 0 ?
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 18L9 11.25l4.306 4.307a11.95 11.95 0 015.814-5.519l2.74-1.22m0 0l-5.94-2.28m5.94 2.28l-2.28 5.941" />
                    </svg> : <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5 mr-2 inline-flex">
                      <path stroke-linecap="round" stroke-linejoin="round" d="M2.25 6L9 12.75l4.286-4.286a11.948 11.948 0 014.306 6.43l.776 2.898m0 0l3.182-5.511m-3.182 5.51l-5.511-3.181" />
                    </svg>}
                  {Math.abs(overallStats?.Revenue?.changePercentage || 0).toFixed(2)}% </span> {overallStats?.Revenue?.changePercentage > 0 ? "More" : "Less"} than yesterday</h3>
              </div>
            </div>
            <div className="flex ">
              <p className="text-xl text-black dark:text-white font-extrabold mt-4">${overallStats?.Revenue?.today || 0} <span className='text-xs'>/ ${overallStats?.Revenue?.total || 0}</span></p>
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col md:flex-row gap-6 mt-6 relative">
        <div className="w-full md:h-[65vh] p-5 bg-white rounded-3xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <div className='flex flex-col gap-2 md:flex-row items-center justify-between'>
            <h5 className="text-gray-800 dark:text-gray-200 font-bold text-xl">Orders Analytics</h5>
            <div className='flex gap-5 items-center'>
              <div className='text-sm text-gray-600 dark:text-gray-300 font-bold flex items-center'><div className='w-2.5 h-2.5 rounded-full bg-orange-600 mr-2' />Orders</div>
              <div className='text-sm text-gray-600 dark:text-gray-300 font-bold flex items-center'><div className='w-2.5 h-2.5 rounded-full bg-gray-300 mr-2' />Returns</div>
              <select onChange={(e) => setSelectedYear(parseInt(e.target.value))} value={selectedYear}
                class="rounded-xl bg-transparent border border-gray-300 dark:border-gray-600 px-4 py-2 text-sm text-black font-bold outline-none focus:border-orange-500 focus:ring-orange-500 dark:text-gray-200">
                {[...Array(5)].map((_, index) => {
                  const year = format(subYears(new Date(), index), 'yyyy');
                  return <option className='text-gray-700' key={year} value={year}>{index === 0 ? "This year" : year}</option>;
                })}
              </select>
            </div>
          </div>
          <ResponsiveContainer width="100%" height="100%" className="-ml-6 py-8 ">
            <LineChart
              width={500}
              height={300}
              data={monthlyAnalytics}
            >
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="month" axisLine={false} tickFormatter={(value) => value.slice(0, 3)} />
              <YAxis axisLine={false} />
              <Tooltip content={({ active, payload, label }) => {
                if (active && payload && payload.length) {
                  return (
                    <div className={`px-4 py-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 shadow`}>
                      <h1 className='text-sm font-bold mb-1 text-black dark:text-white'>{label}</h1>
                      <div className='flex gap-2 items-center text-xs font-bold'>
                        <div className='w-2 h-2 border-2 border-orange-600 rounded-full' />
                        <p className="text-orange-600">{payload[0].value}</p>
                        <p className="text-white-700 dark:text-gray-100">orders</p>
                      </div>
                      <div className='flex gap-2 items-center text-xs font-bold'>
                        <div className='w-2 h-2 border-2 border-gray-400 rounded-full' />
                        <p className="text-gray-400">{payload[1].value}</p>
                        <p className="text-white-700 dark:text-gray-100">returns</p>
                      </div>
                    </div>
                  );
                }
                return null;
              }}
              />
              <Line type="monotone" dataKey="orders" stroke="#FF5C00" strokeWidth={3} activeDot={{ r: 8 }} />
              <Line type="monotone" dataKey="returns" stroke="#d1d5db" strokeWidth={3} activeDot={{ r: 8 }} />
            </LineChart>
          </ResponsiveContainer>

        </div>
        <div className="md:w-[62vh] md:min-h-[65vh] p-5 bg-white rounded-3xl bg-white dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
          <h5 className="text-gray-800 dark:text-gray-200 font-bold text-xl">Orders by status <span className='text-xs text-gray-700 dark:text-gray-300'>({selectedYear})</span></h5>
          {statusesAnalytics.length > 0 ?
            <div className='flex flex-col items-center py-6'>
              <PieChart width={400} height={300}>
                <Pie
                  data={statusesAnalytics}
                  dataKey="totalOrders"
                  nameKey="status"
                  innerRadius={60}
                  outerRadius={120}
                  fill="#8884d8"
                >
                  {statusesAnalytics.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={getHexColor(entry.color)} />
                  ))}
                </Pie>
                <Tooltip content={({ active, payload, label }) => {
                  if (active && payload && payload.length) {
                    return (
                      <div className={`px-4 py-2 rounded-lg bg-white dark:bg-gray-800 border border-gray-300 dark:border-gray-600 shadow flex gap-2 items-center`}>
                        <h1 className='text-sm font-bold text-black dark:text-white text-center flex gap-2 items-center'>
                          {payload[0].name} : </h1>
                        <p className="text-xs font-bold text-black dark:text-white text-center">{payload[0].value}</p>
                      </div>
                    );
                  }
                  return null;
                }}
                />
              </PieChart>
              <div className='grid grid-cols-2 gap-x-28 gap-y-4 px-10'>
                {statusesAnalytics.map((item, index) => (
                  <div key={index} className='flex flex-col'>
                    <h1 className='text-sm text-black/80  dark:text-gray-200 font-semibold whitespace-nowrap'>{item?.status}</h1>
                    <div className='flex gap-2  items-center'>
                      <div className={`w-3 h-3 rounded-full bg-${item?.color}`} />
                      <h1 className='text-md text-black dark:text-white font-semibold'>{item?.totalOrders}</h1>
                    </div>
                  </div>
                ))}
              </div>
            </div> :
            <div className="h-full py-8 animate-pulse flex flex-col items-center justify-center text-gray-400 dark:text-gray-700">
              <svg version="1.1" id="Layer_1" className="w-24 h-24 md:w-44 md:h-44" fill="currentColor" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 122.88 120.5" style={{enableBackground: 'new 0 0 122.88 120.5'}} xmlSpace="preserve"><style type="text/css" dangerouslySetInnerHTML={{__html: ".st0{fill-rule:evenodd;clip-rule:evenodd;}" }} /><g><path className="st0" d="M64.82,68.27l48.4,0.8c0,17.19-8.55,33.26-22.81,42.86L64.82,68.27L64.82,68.27z M59.99,59.92L59.44,3.63 L59.41,0l3.61,0.25h0.01h0.01c4.56,0.32,8.98,1.12,13.21,2.33c4.23,1.21,8.29,2.86,12.13,4.87c19.67,10.34,33.27,30.56,34.34,54.02 l0.16,3.61l-3.61-0.11l-56.02-1.72l-3.23-0.1L59.99,59.92L59.99,59.92z M66.19,7.33l0.48,49.31l49.06,1.5 c-2.1-19.45-13.88-36.02-30.48-44.74c-3.41-1.79-7.04-3.26-10.84-4.35C71.74,8.28,69,7.71,66.19,7.33L66.19,7.33z M55.19,65.31 l27.6,47.8c-8.38,4.84-17.92,7.39-27.6,7.39C24.71,120.5,0,95.78,0,65.31c0-29.57,23.31-53.9,52.86-55.14L55.19,65.31L55.19,65.31z" /></g></svg>
              <p className="font-normal text-xl md:text-3xl mt-4 text-center">No statistics are available.</p>
              <p className="font-normal text-sm md:text-xl text-center">As of {selectedYear}, there are currently no statistics available for display...</p>
            </div>}
        </div>
      </div>


    </main>
  )
}