import CryptoJS from 'crypto-js';


const decryptData = (encryptedData, secretKey) => {
    const bytes = CryptoJS.AES.decrypt(encryptedData, secretKey);
    return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
};

// Function to securely retrieve data from localStorage
export const getSecureLocalStorage = (key, secretKey) => {
    try {
        const encryptedData = localStorage.getItem(key);
        if (encryptedData) {
            const decryptedData = decryptData(encryptedData, secretKey);
            return decryptedData;
        }
    } catch (error) {
        console.error('Error retrieving data from localStorage:', error);
    }
    return null;
};