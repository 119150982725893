import { useEffect, useState } from "react";
import Loading from './component/loading'
import Login from "./Main/login";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Dashboard from './Dashboard/dashboard'
import { Toaster } from 'react-hot-toast';
import axios from "axios";
import Page404 from "./component/page404";
import Setup from "./Main/setup/setup";

export default function App() {
  const [loading, setLoading] = useState(true);
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  useEffect(() => {
    const localStorageToken = localStorage.getItem('token');
    const sessionStorageToken = sessionStorage.getItem('token');
    const role = localStorage.getItem('role') || sessionStorage.getItem('role');

    if (localStorageToken || sessionStorageToken) {
      // Validate the token before setting isLoggedIn to true
      validateToken(localStorageToken || sessionStorageToken, role)
        .then((isValid) => {
          setIsLoggedIn(isValid);
        })
        .catch(() => {
          setIsLoggedIn(false);
        });
    } else {
      setIsLoggedIn(false);
    }

    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  // destroy all storage
  function removeItemsFromStorage(storage, ...keys) {
    keys.forEach(key => storage.removeItem(key));
  }
  // Function to validate the access token and role
  const validateToken = async (token, role) => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/users/check_access`, {
        headers: { 'x-access-token': token, 'x-access-role': role },
      });
      return response.status === 200;
    } catch (error) {
      removeItemsFromStorage(localStorage, 'token', 'role');
      removeItemsFromStorage(sessionStorage, 'token', 'role');
      return false;
    }
  };

  return (
    loading ? (
      <Loading />
    ) : (
      <BrowserRouter>
        <Toaster />
        <Routes>
          <Route path='/login' element={<Login />} />
          <Route path='/setup' element={<Setup />} />
          <Route path="/dashboard/*" element={<ProtectedRoute isLoggedIn={isLoggedIn} />} />
          <Route path="/404" element={<Page404 />} />
          <Route path="/*" element={<Page404 />} />
        </Routes>
      </BrowserRouter>
    )
  );
}

const ProtectedRoute = ({ isLoggedIn }) => {
  if (isLoggedIn) {
    return <Dashboard />;
  } else {
    // Redirect to the login page when not logged in
    return <Navigate to="/login" />;
  }
};