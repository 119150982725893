import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { AnimatePresence, motion } from "framer-motion";
import { An_Error, Error_specific, Success } from "../../../component/alert";
import { useNavigate } from 'react-router-dom'
import InfiniteScroll from "react-infinite-scroll-component";
import { DotSpinner } from '@uiball/loaders'
import { format } from 'date-fns'
import GetLinkedOrders from './component/getLinkedOrders'

const token = localStorage.getItem('token') || sessionStorage.getItem('token')

export default function DeliveryPayments() {
    const [loading, setLoading] = useState(false)
    const [payType, setPayType] = useState();
    const [transaction, setTransaction] = useState([])
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);
    const [modalApprove, setModalApprove] = useState(false);
    const [modalRefuse, setModalRefuse] = useState(false);
    const [LoadingBTN, setLoadingBTN] = useState(false);
    const [modalDetails, setModalDetails] = useState(false);
    const navigate = useNavigate()

    const getData = async (refresh = false) => {
        try {
            setLoading(true)
            if (refresh) {
                setTransaction([]);
                setPage(1);
            }
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/delivery/payment/get-transactions/${refresh ? 1 : page}`,
                { headers: { 'x-access-token': token } });
            setPayType(response.data.payType);
            const newData = response.data.Data;
            if (newData.length === 0) {
                setHasMore(false);
            } else {
                setTransaction(prevTransaction => [...prevTransaction, ...newData]);
                setPage((prev) => prev + 1);
                setHasMore(true)
            }
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData(true)
    }, [])

    const processTransaction = async (id, action) => {
        try {
            setLoadingBTN(true)
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/delivery/payment/process-transaction/${id}`, { action },
                { headers: { 'x-access-token': token } });
            Success(response.data.message);
            getData(true)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setModalApprove(false);
            setModalRefuse(false);
            setLoadingBTN(false)
        }
    }
    return (
        <main class="h-max pb-16 mb-4 ml-5 mr-5 mt-4 px-3">
            <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.3 }}
            >
                <div className="flex flex-col md:flex-row justify-between md:items-center gap-2 py-4">
                    <div className="">
                        <h2 className="font-bold text-lg md:text-xl text-gray-800 dark:text-gray-200 capitalize">Payment History ({transaction?.length})</h2>
                        <h4 className="font-light text-sm md:text-md text-gray-500 dark:text-gray-400">Discover the most recent transactions to effectively manage your finances.</h4>
                    </div>

                    <div className="relative">
                        <button onClick={() => navigate('/dashboard/delivery/payment/generate-commission')} class="w-full rounded-xl bg-orange-600 px-16 py-2 font-medium text-white focus:outline-none hover:bg-orange-500 transition-all duration-500 flex gap-2 items-center justify-center">
                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2" stroke="currentColor" class="size-5">
                                <path stroke-linecap="round" stroke-linejoin="round" d="M12 9v6m3-3H9m12 0a9 9 0 1 1-18 0 9 9 0 0 1 18 0Z" />
                            </svg>
                            Create Payment
                        </button>
                    </div>

                </div>


                <div className="overflow-x-auto mt-2 border border-gray-200 dark:border-gray-700 rounded-xl">
                    <InfiniteScroll
                        dataLength={transaction.length}
                        next={() => getData()}
                        hasMore={hasMore}
                        loader={loading && <div className="py-5 px-4 flex justify-center items-center">
                            <DotSpinner
                                size={30}
                                speed={0.9}
                                color="gray"
                            />
                        </div>}
                        scrollThreshold={0.8}
                    >
                        {transaction.length > 0 ? <table className="min-w-full divide-y divide-gray-200 dark:divide-gray-700">
                            <thead className="bg-gray-50 dark:bg-gray-800">
                                <tr>

                                    <th scope="col" className="py-3.5 px-4 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">
                                        <div className="flex items-center gap-x-3 focus:outline-none">
                                            <span>Transaction From</span>
                                            <svg className="h-3" viewBox="0 0 10 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M2.13347 0.0999756H2.98516L5.01902 4.79058H3.86226L3.45549 3.79907H1.63772L1.24366 4.79058H0.0996094L2.13347 0.0999756ZM2.54025 1.46012L1.96822 2.92196H3.11227L2.54025 1.46012Z" fill="currentColor" stroke="currentColor" strokeWidth="0.1" />
                                                <path d="M0.722656 9.60832L3.09974 6.78633H0.811638V5.87109H4.35819V6.78633L2.01925 9.60832H4.43446V10.5617H0.722656V9.60832Z" fill="currentColor" stroke="currentColor" strokeWidth="0.1" />
                                                <path d="M8.45558 7.25664V7.40664H8.60558H9.66065C9.72481 7.40664 9.74667 7.42274 9.75141 7.42691C9.75148 7.42808 9.75146 7.42993 9.75116 7.43262C9.75001 7.44265 9.74458 7.46304 9.72525 7.49314C9.72522 7.4932 9.72518 7.49326 9.72514 7.49332L7.86959 10.3529L7.86924 10.3534C7.83227 10.4109 7.79863 10.418 7.78568 10.418C7.77272 10.418 7.73908 10.4109 7.70211 10.3534L7.70177 10.3529L5.84621 7.49332C5.84617 7.49325 5.84612 7.49318 5.84608 7.49311C5.82677 7.46302 5.82135 7.44264 5.8202 7.43262C5.81989 7.42993 5.81987 7.42808 5.81994 7.42691C5.82469 7.42274 5.84655 7.40664 5.91071 7.40664H6.96578H7.11578V7.25664V0.633865C7.11578 0.42434 7.29014 0.249976 7.49967 0.249976H8.07169C8.28121 0.249976 8.45558 0.42434 8.45558 0.633865V7.25664Z" fill="currentColor" stroke="currentColor" strokeWidth="0.3" />
                                            </svg>
                                        </div>
                                    </th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Transaction Type</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Number Orders</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Commission</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Commission Amount</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Total Amount</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Transaction Date</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Notes</th>
                                    <th scope="col" className="px-4 py-3.5 text-sm font-normal text-left rtl:text-right text-gray-700 dark:text-gray-200">Status</th>
                                    <th scope="col" className="relative py-3.5 px-4">
                                        <span className="sr-only">Edit</span>
                                    </th>

                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200 dark:divide-gray-700 dark:bg-gray-900">
                                {transaction.map((item, index) => (
                                    <tr key={index}>
                                        <td className="px-4 py-4 text-sm font-medium whitespace-nowrap">
                                            <div>
                                                <h2 className="font-medium text-gray-900 dark:text-white capitalize">
                                                    {item?.senderUser ? `${item?.senderUser?.firstName} ${item?.senderUser?.lastName}` : 'N/A'}</h2>
                                                <p className="text-sm font-normal text-gray-600 dark:text-gray-400">
                                                    {item?.senderUser ? item?.senderUser?.email : 'N/A'}
                                                </p>
                                            </div>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{item?.transactionType || 'N/A'}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{item?.ordersId?.length || ''}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{item?.commission || ''}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{item?.deliveryCommission >= 0 ? `-${item?.deliveryCommission}` : ''}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{item?.amount || 'N/A'}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <h4 className="text-gray-800 dark:text-gray-200">{format(new Date(item?.transaction_date), "dd MMM yyyy 'at' HH:mm")}</h4>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <p className="text-gray-800 dark:text-gray-200 w-[40vh] md:w-[30vh] whitespace-pre-line">{item?.notes || ''}</p>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            <div className="flex gap-2 items-center">
                                                <div className={`w-2 h-2 ${item?.status === 'received' ? 'bg-green-600' : item?.status === 'refused' ? 'bg-red-600' : 'bg-orange-600'} rounded-full`} />
                                                <h4 className="text-gray-800 dark:text-gray-200">{item?.status || 'N/A'}</h4>
                                            </div>
                                        </td>
                                        <td className="px-4 py-4 text-sm whitespace-nowrap">
                                            {payType === 'salary' && item?.status === 'pending' && item?.transactionType === 'salary' ?
                                                <>
                                                    <button onClick={() => setModalRefuse(item?._id)} className="mr-1 px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:border">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="M6 18 18 6M6 6l12 12" />
                                                        </svg>
                                                    </button>
                                                    <button onClick={() => setModalApprove(item?._id)} className="mr-1 px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:border">
                                                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="size-5">
                                                            <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 12.75 6 6 9-13.5" />
                                                        </svg>
                                                    </button>
                                                </> :
                                                null}
                                            {item?.transactionType === 'Commission' && item?.ordersId?.length > 0 ?
                                                <button onClick={() => setModalDetails(item?._id)} className="px-1 py-1 text-gray-500 transition-colors duration-200 rounded-lg dark:text-gray-300 hover:border">
                                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.8" stroke="currentColor" class="size-5">
                                                        <path stroke-linecap="round" stroke-linejoin="round" d="m11.25 11.25.041-.02a.75.75 0 0 1 1.063.852l-.708 2.836a.75.75 0 0 0 1.063.853l.041-.021M21 12a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9-3.75h.008v.008H12V8.25Z" />
                                                    </svg>
                                                </button> : null}
                                        </td>
                                    </tr>
                                ))}


                            </tbody>
                        </table> : !loading &&
                        <div className="py-16 animate-pulse flex flex-col items-center justify-center text-gray-400 dark:text-gray-700">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-44 h-44" fill="currentColor" shapeRendering="geometricPrecision" textRendering="geometricPrecision" imageRendering="optimizeQuality" fillRule="evenodd" clipRule="evenodd" viewBox="0 0 512 406.8"><path d="M29.27 0h373.81c16.11 0 29.27 13.16 29.27 29.27v90.8c-2.23-1.1-4.48-2.14-6.77-3.13l-2.02-.92c-3.57-1.47-7.18-2.82-10.84-4.02V81.94h.14H20.83v221.61c0 4.5 3.59 8.09 8.09 8.09h193.04c.54 1.42 1.1 2.83 1.69 4.24 1.97 4.78 4.23 9.49 6.73 14.09H29.27C13.16 329.97 0 316.78 0 300.7V29.27C0 13.16 13.16 0 29.27 0zm335.71 140.37c31.07 0 60.75 12.29 82.72 34.27 30.33 30.33 41.62 75.06 29.4 116.14-2.83 9.53-6.85 18.55-11.88 26.89l45.3 49.38c2.09 2.27 1.95 5.82-.33 7.91l-33.26 30.38c-2.27 2.08-5.8 1.92-7.89-.35l-43.34-47.67c-18.35 11.18-39.22 17.02-60.72 17.02-31.06 0-60.75-12.3-82.71-34.27a117.18 117.18 0 0 1-25.39-37.99c-18.13-43.67-7.82-94.22 25.4-127.43a116.814 116.814 0 0 1 37.96-25.4c13.8-5.73 28.92-8.88 44.74-8.88zm70.93 46.04c-28.63-28.63-72.03-37.24-109.31-21.8-66.92 27.71-82.3 113.99-32.58 163.67 28.68 28.61 72 37.26 109.33 21.81 37.54-15.59 61.94-52.1 61.94-92.74 0-13.62-2.69-26.59-7.56-38.37a101.013 101.013 0 0 0-21.82-32.57zm-28.22 97.67-16.4 16.31-26.48-26.49-26.34 26.34-15.82-15.86 26.32-26.32-26.85-26.84 16.4-16.31 26.8 26.81 26.51-26.51 15.81 15.85-26.49 26.49 26.54 26.53zM375.21 32.9c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.98 0-14.46-6.48-14.46-14.46.04-7.99 6.51-14.46 14.46-14.46zm-97.95 0c7.99 0 14.46 6.47 14.46 14.46 0 7.98-6.47 14.46-14.46 14.46-7.99 0-14.46-6.48-14.46-14.46 0-7.99 6.47-14.46 14.46-14.46zm48.98 0c7.98 0 14.45 6.47 14.45 14.46 0 7.98-6.47 14.46-14.45 14.46-7.99 0-14.47-6.48-14.47-14.46 0-7.99 6.48-14.46 14.47-14.46z" /></svg>
                            <p className="font-normal text-3xl mt-4 text-center">Sad no result!</p>
                            <p className="font-normal text-xl text-center">Currently, there are no transaction available for display...</p>
                        </div>}
                    </InfiniteScroll>
                </div>



                <AnimatePresence>
                    {modalApprove && <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
                    >
                        <motion.div initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">

                            <div class="m-10 flex max-w-lg flex-col border px-8 py-10 text-gray-800 sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 sm:max-w-lg ">
                                <div className="flex justify-between items-center">
                                    <p class="text-left text-lg font-bold dark:text-gray-200">Approve the transaction.</p>
                                    <svg version="1.1" id="Layer_1" className="w-12 h-12 " xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 111.81 122.88" style={{ enableBackground: 'new 0 0 111.81 122.88' }} xmlSpace="preserve"><style type="text/css" dangerouslySetInnerHTML={{ __html: "\n\t.st0{fill-rule:evenodd;clip-rule:evenodd;fill:#51B53C;}\n\t.st1{fill:#FFFFFF;}\n\t.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}\n" }} /><g><path className="st0" d="M55.71,0c20.85,13.21,39.68,19.47,55.85,17.99c2.82,57.1-18.26,90.82-55.63,104.89 C19.84,109.71-1.5,77.44,0.08,17.12C19.06,18.12,37.67,14.01,55.71,0L55.71,0L55.71,0z" /><path className="st2" d="M55.73,7.05c18.45,11.7,35.13,17.23,49.43,15.92c2.5,50.54-16.17,80.39-49.24,92.85 C23.98,104.16,5.09,75.6,6.49,22.21C23.29,23.09,39.77,19.46,55.73,7.05L55.73,7.05L55.73,7.05z" /><path className="st0" d="M56.24,19.54c14.22,9.01,27.06,13.27,38.08,12.27c1.92,38.94-12.45,61.93-37.94,71.53 c-0.16-0.06-0.32-0.12-0.48-0.18c-0.16,0.06-0.32,0.12-0.48,0.18c-25.48-9.6-39.86-32.59-37.94-71.53 c11.02,1.01,23.87-3.26,38.08-12.27l0,0l0.33,0.25L56.24,19.54L56.24,19.54L56.24,19.54z" /><path className="st1" d="M35.44,58.28l7.47-0.1l0.56,0.14c1.51,0.87,2.93,1.86,4.26,2.99c0.96,0.81,1.87,1.69,2.74,2.65 c2.68-4.31,5.54-8.28,8.56-11.92c3.31-3.99,5.38-6.18,9.06-9.49l0.73-0.28h8.16l-1.65,1.82c-5.05,5.61-8.21,9.99-12.35,15.97 c-4.15,6-7.85,12.18-11.15,18.54l-1.03,1.98l-0.94-2.02c-1.74-3.73-3.82-7.15-6.3-10.21c-2.48-3.06-5.37-5.78-8.74-8.09 L35.44,58.28L35.44,58.28L35.44,58.28L35.44,58.28z" /></g></svg>
                                </div>
                                <p class="text-left text-sm dark:text-gray-400 w-3/4">Approve the payment to ensure timely receipt and processing of the funds.</p>
                                <div class="mt-8 flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                    <button onClick={() => { !LoadingBTN && processTransaction(modalApprove, 'approve') }} class="whitespace-nowrap rounded-md bg-green-600 px-4 py-3 font-medium text-white">
                                        {LoadingBTN ? <div class="flex items-center justify-center ">
                                            <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <div class="ml-2"> Processing.... </div>
                                        </div> : "Yes, approve the transaction."}
                                    </button>
                                    <button onClick={() => { !LoadingBTN && setModalApprove(false) }} class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium">Cancel, approve it later.</button>
                                </div>
                            </div>

                        </motion.div>
                    </motion.div>}

                    {modalRefuse && <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
                    >
                        <motion.div initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">

                            <div class="m-10 flex max-w-lg flex-col border px-8 py-10 text-gray-800 sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 sm:max-w-lg ">
                                <div className="flex justify-between items-center">
                                    <p class="text-left text-lg font-bold dark:text-gray-200">Refuse the transaction.</p>
                                    <svg id="Layer_1" className="w-12 h-12 " data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 122.88"><defs><style dangerouslySetInnerHTML={{ __html: ".cls-1{fill:#f44336;fill-rule:evenodd;}" }} /></defs><title>close-red</title><path className="cls-1" d="M61.44,0A61.44,61.44,0,1,1,0,61.44,61.44,61.44,0,0,1,61.44,0ZM74.58,36.8c1.74-1.77,2.83-3.18,5-1l7,7.13c2.29,2.26,2.17,3.58,0,5.69L73.33,61.83,86.08,74.58c1.77,1.74,3.18,2.83,1,5l-7.13,7c-2.26,2.29-3.58,2.17-5.68,0L61.44,73.72,48.63,86.53c-2.1,2.15-3.42,2.27-5.68,0l-7.13-7c-2.2-2.15-.79-3.24,1-5l12.73-12.7L36.35,48.64c-2.15-2.11-2.27-3.43,0-5.69l7-7.13c2.15-2.2,3.24-.79,5,1L61.44,49.94,74.58,36.8Z" /></svg>
                                </div>
                                <p class="text-left text-sm dark:text-gray-400 w-3/4">By refusing the payment, you declare that you have not received the funds.</p>
                                <div class="mt-8 flex flex-col space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                    <button onClick={() => { !LoadingBTN && processTransaction(modalRefuse, 'refuse') }} class="whitespace-nowrap rounded-md bg-red-600 px-4 py-3 font-medium text-white">
                                        {LoadingBTN ? <div class="flex items-center justify-center ">
                                            <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            <div class="ml-2"> Processing.... </div>
                                        </div> : "Yes, Refuse the transaction."}
                                    </button>
                                    <button onClick={() => { !LoadingBTN && setModalRefuse(false) }} class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium">Cancel, refuse it later.</button>
                                </div>
                            </div>

                        </motion.div>
                    </motion.div>}

                    {modalDetails && <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
                    >
                        <motion.div initial={{ opacity: 0, scale: 0.8 }}
                            animate={{ opacity: 1, scale: 1 }}
                            exit={{ opacity: 0, scale: 0.8 }}
                            transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center px-2">

                            <div class="flex flex-col border px-5 py-5 text-gray-800 overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 w-4/4 md:w-2/4">
                                <div class="flex justify-between items-center pr-1">
                                    <div className="flex flex-col text-left">
                                        <h5 class="text-white text-xl uppercase font-bold text-gray-800 dark:text-gray-100">list of orders (5)</h5>
                                        <p class="antialiased text-base leading-relaxed text-sm font-meduim text-gray-500 dark:text-gray-400 w-4/5 md:w-full">
                                            The entire list of orders was successfully paid for in this transaction.
                                        </p>
                                    </div>
                                    <svg onClick={() => setModalDetails(false)} xmlns="http://www.w3.org/2000/svg" class="size-7 text-gray-700 dark:text-gray-200 cursor-pointer hover:border rounded" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                    </svg>
                                </div>

                                <GetLinkedOrders id={modalDetails} token={token} />
                            </div>

                        </motion.div>
                    </motion.div>}
                </AnimatePresence>
            </motion.div>
        </main>
    )
}
