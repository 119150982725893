import * as XLSX from 'xlsx';


export const exportToExcel = (data) => {
    const UpdatedData = data.map(({ _id, ...rest }) => rest)
    const ws = XLSX.utils.json_to_sheet(UpdatedData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Orders');

    const now = new Date()
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    const filename = `data_${day}_${month}_${year}`

    XLSX.writeFile(wb, `${filename}.xlsx`);
};