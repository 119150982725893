import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import Navbar from "./component/Nav";
import './App.css'
import Orders from "./pages/Admin_pages/comp_orders/orders";
import Main from "./pages/Main";
import Products from "./pages/Admin_pages/comp_products/products";
import Users from "./pages/Admin_pages/comp_users/users";
import { useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import AddNewManuallyOrder from './pages/Admin_pages/comp_orders/addNew'
import AddNewManuallyProduct from './pages/Admin_pages/comp_products/addNew'
import { Chat } from "./component/chat";
import Redirect_to404 from "./component/redirect_to404";
import Menu from "./component/menu";
import Settings from "./pages/settings";
import Delivery from "./pages/Admin_pages/comp_users/delivery";
import Stock from "./pages/Admin_pages/comp_users/stock";
import ConfirmerOrders from "./pages/Confirmer_pages/ConfirmerOrders";
import DeliveryOrders from "./pages/Delivery_pages/DeliveryOrders";
import Trash from "./pages/Admin_pages/comp_trash/Trash";
import DelivredOrders from "./pages/Admin_pages/comp_orders/delivredOrders";
import Payments from "./pages/Admin_pages/comp_payments/payments";
import CommissionPayment from "./pages/Admin_pages/comp_payments/commissionPayment";
import DeliveryPayments from "./pages/Delivery_pages/DeliveryPayments";
import DeliveryCommissionPayment from "./pages/Delivery_pages/DeliveryCommissionPayment";
import ConfirmerPayments from "./pages/Confirmer_pages/ConfirmerPayments";
import Builder from "./pages/Admin_pages/comp_products/component/builder";

function Dashboard() {
  const role = localStorage.getItem('role') || sessionStorage.getItem('role');
  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname
  const protectedRoutes = ['/dashboard/orders/confirmation', '/dashboard/orders/delivery', '/orders/confirmation/add', '/dashboard/products',
   '/dashboard/users', '/dashboard/delivery', '/dashboard/delivery/stock','/payment','/payment/generate-commission','/dashboard/products/Landingpage'] // route for admin only
  const protectedConfirmerRoutes = ['/dashboard/confirmer/orders','/dashboard/confirmer/payment'] // route for confirmer only
  const protectedDeliveryRoutes = ['/dashboard/delivery/orders','/dashboard/delivery/payment','/dashboard/delivery/payment/generate-commission'] // route for delivery user only

  // if user dosent have role admin and navigate to protected route prevent and redirect to 404
  useEffect(() => {
    if ((role !== 'Super_Admin' && role !== 'admin') && protectedRoutes.includes(currentRoute)) {
      navigate('/404');
    } else if (role !== 'confirmer' && protectedConfirmerRoutes.includes(currentRoute)) {
      navigate('/404');
    } else if (role !== 'Delivery_User' && protectedDeliveryRoutes.includes(currentRoute)) {
      navigate('/404');
    }
  }, [currentRoute, role, navigate]);



  // Define a function to conditionally render routes based on the user's role
  const renderRoutesBasedOnRole = () => {
    // Check the user's role and conditionally render routes
    if (role === 'Super_Admin' || role === 'admin') {
      return (
        <>
          <Route path="/orders/confirmation" element={<Orders />} />
          <Route path="/orders/delivery" element={<DelivredOrders />} />
          <Route path="/orders/confirmation/add" element={<AddNewManuallyOrder />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/add" element={<AddNewManuallyProduct />} />
          <Route path="/users" element={<Users />} />
          <Route path="/delivery" element={<Delivery />} />
          <Route path="/delivery/stock/:id" element={<Stock />} />
          <Route path="/payment" element={<Payments />} />
          <Route path="/payment/generate-commission" element={<CommissionPayment />} />
          <Route path="/products/Landingpage/:id" element={<Builder />} />
          {role === 'Super_Admin' && <Route path="/trash" element={<Trash />} />}
        </>
      );
    }
    if (role === 'confirmer') {
      return (
        <>
          <Route path="/confirmer/orders" element={<ConfirmerOrders />} />
          <Route path="/confirmer/payment" element={<ConfirmerPayments />} />
        </>
      )
    }
    if (role === 'Delivery_User') {
      return (
        <>
          <Route path="/delivery/orders" element={<DeliveryOrders />} />
          <Route path="/delivery/payment" element={<DeliveryPayments />} />
          <Route path="/delivery/payment/generate-commission" element={<DeliveryCommissionPayment />} />
        </>
      )
    }
  };

  return (
    <AnimatePresence>
      <motion.div
        initial={{ blur: 40 }}
        animate={{ blur: 0 }}
        exit={{ blur: 40 }}
        transition={{ duration: 0.4 }}
        className="flex max-h-full bg-gray-100 dark:bg-gray-900">
        <Menu />
        <div className="flex flex-col flex-1 w-full min-h-screen  duration-300">
          <Navbar />
          <Chat />
          <Routes>
            <Route path="/" element={<Main />} />
            <Route path="/settings" element={<Settings />} />
            {renderRoutesBasedOnRole()}
            <Route path="*" element={<Redirect_to404 />} />
          </Routes>
        </div>
      </motion.div></AnimatePresence>
  );
}

export default Dashboard;



