import React, { useState } from 'react'
import { motion } from 'framer-motion'
import emailjs from 'emailjs-com';
import axios from 'axios';
import { An_Error, Error_specific } from '../../../component/alert';

// generate code verfication email 
function generateRandomBytes(length) {
    const randomDigitsArray = new Uint8Array(length);
    window.crypto.getRandomValues(randomDigitsArray);
    let digitsString = '';
    for (let i = 0; i < length; i++) {
        const randomDigit = randomDigitsArray[i] % 10;
        digitsString += randomDigit.toString();
    }

    return digitsString;
}

export default function Information({ setPages }) {
    const [data, setData] = useState({})
    const [errors, setErrors] = useState({})
    const [lodingBtn, setLodingBtn] = useState(false)
    const [verificationCode, setVerificationCode] = useState()
    const [loding, setLoding] = useState(false)

    function handlerIputsChange(e) {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    // Email verification by sending the code to his email
    async function Emailverfication() {
        setLodingBtn(true)
        if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(data.email)) {
            const templateParams = {
                code: generateRandomBytes(7),
                to_email: data.email,
            };
            setVerificationCode(templateParams.code)
            const serviceID = process.env.REACT_APP_EMAILJS_SERVICEID;
            const templateID = process.env.REACT_APP_EMAILJS_TEMPLATEID;
            const userID = process.env.REACT_APP_EMAILJS_USERID;
            await emailjs.send(serviceID, templateID, templateParams, userID);
            setLodingBtn(false)
            setErrors({ ...errors, 'email': '' })

        } else {
            setErrors({ ...errors, 'email': ['Invalid email address. Please enter a valid email'] })
            setLodingBtn(false)
        }
    }
    // fuction for register new user
    async function HandlerSaveInformation() {
        try {
            setLoding(true)
            await axios.post(`${process.env.REACT_APP_SERVER_URL}/users/register`, data, {
                headers: {
                    'Authorization': `${verificationCode ? verificationCode : null}`,
                }
            });
            setErrors({})
            setPages(prevPages => ({
                ...prevPages,
                activeTab: 3
            }))
        } catch (error) {
            if (error.response?.status === 412) {
                setErrors(error.response.data.data.errors);
            }
            else if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
            } else {
                An_Error();
            }
        }finally{
            setLoding(false)
        }
    }
    return (
        <motion.div initial={{ x: -600, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 600, opacity: 0 }} class="flex w-full flex-col">
            <h1 class="text-2xl font-semibold dark:text-gray-100">Account Information</h1>
            <p class="mt-2 text-gray-500 dark:text-gray-400">Hello!, please fill in your information to continue.</p>

            <div class="mt-6 px-8 text-left">
                <div class="flex justify-between gap-3 mt-2">
                    <span class="w-1/2">
                        <label for="firstname" class="block text-xs font-semibold text-gray-600 dark:text-gray-300  uppercase">Firstname</label>
                        <input id="firstname" type="text" onChange={(e) => handlerIputsChange(e)} name="firstName" placeholder="John" autocomplete="given-name" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                        <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                            {errors.hasOwnProperty('firstName') ? errors.firstName[0] : ''}</p>
                    </span>
                    <span class="w-1/2">
                        <label for="lastname" class="block text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">Lastname</label>
                        <input id="lastname" type="text" onChange={(e) => handlerIputsChange(e)} name="lastName" placeholder="Doe" autocomplete="family-name" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                        <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                            {errors.hasOwnProperty('lastName') ? errors.lastName[0] : ''}</p>
                    </span>
                </div>
                <label for="company_name" class="block mt-2 text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">company name</label>
                <input id="company_name" type="text" onChange={(e) => handlerIputsChange(e)} name="companyName" placeholder="company x" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('companyName') ? errors.companyName[0] : ''}</p>

                <label for="email" class="block mt-2 text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">E-mail</label>
                <div class="flex rounded-md shadow-sm">
                    <input type="text" name="email" onChange={(e) => handlerIputsChange(e)} id="website" autocomplete="email" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" placeholder="john.doe@company.com" />
                    {!verificationCode && <div onClick={() => !lodingBtn && Emailverfication()} class="inline-flex min-w-fit items-center border p-3 mt-2 border-l-0 border-gray-500 bg-gray-200 dark:bg-gray-900 px-4 hover:outline-none hover:bg-gray-300 cursor-pointer">
                        <span class="text-sm text-gray-500 uppercase">{lodingBtn ? 'Chargement...' : 'Verifier'}</span>
                    </div>}
                </div>
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('email') ? errors.email[0] : ''}</p>
                {verificationCode && <div class="flex">
                    <input type="text" name="code" onChange={(e) => handlerIputsChange(e)} placeholder="activation code" class="block w-2/4 p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                    <div onClick={() => !lodingBtn && Emailverfication()} class="inline-flex min-w-fit items-center border p-3 mt-2 border-l-0 bg-gray-200 dark:bg-gray-900 border-gray-500 px-4 hover:outline-none hover:bg-gray-300 cursor-pointer">
                        <span class="text-sm text-gray-500 uppercase">{lodingBtn ? 'Chargement...' : 'Resend Code'}</span>
                    </div>
                </div>}
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('check_code') ? errors.check_code[0] : ''}</p>
                <label for="phone_number" class="block mt-2 text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">phone number</label>
                <input id="phone_number" type="text" name="phone" onChange={(e) => handlerIputsChange(e)} placeholder="+1 (000) 000-0000" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('phone') ? errors.phone[0] : ''}</p>
                <div class="flex justify-between gap-3 mt-2">
                    <span class="w-1/3">
                        <label for="Address" class="block text-xs font-semibold text-gray-600 dark:text-gray-300  uppercase">Address</label>
                        <input id="Address" type="text" name="address" onChange={(e) => handlerIputsChange(e)} placeholder="7500 66th St" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                        <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                            {errors.hasOwnProperty('address') ? errors.address[0] : ''}</p>
                    </span>
                    <span class="w-1/3">
                        <label for="City" class="block text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">City</label>
                        <input id="City" type="text" name="city" onChange={(e) => handlerIputsChange(e)} placeholder="Pinellas Park" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                        <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                            {errors.hasOwnProperty('city') ? errors.city[0] : ''}</p>
                    </span>
                    <span class="w-1/3">
                        <label for="ZIP" class="block text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">ZIP Code</label>
                        <input id="ZIP" type="number" name="zipCode" onChange={(e) => handlerIputsChange(e)} placeholder="60000" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                        <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                            {errors.hasOwnProperty('zipCode') ? errors.zipCode[0] : ''}</p>
                    </span>
                </div>
                <label for="password" class="block mt-2 text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">Password</label>
                <input id="password" type="password" name="password" onChange={(e) => handlerIputsChange(e)} placeholder="********" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('password') ? errors.password[0] : ''}</p>

                <label for="password-confirm" class="block mt-2 text-xs font-semibold text-gray-600 dark:text-gray-300 uppercase">Confirm password</label>
                <input id="password-confirm" type="password" name="confirmPassword" autoComplete="off" onChange={(e) => handlerIputsChange(e)} placeholder="********" class="block w-full p-3 mt-2 text-gray-700 dark:text-gray-200 bg-gray-200 dark:bg-gray-900 appearance-none focus:outline-none focus:bg-gray-300 focus:shadow-inner" required />
                <p class="mt-1 text-sm text-red-600 dark:text-red-500">
                    {errors.hasOwnProperty('confirmPassword') ? errors.confirmPassword[0] : ''}</p>
                <div class="my-4 space-y-3 mt-6">
                    <label for="terms" class="flex space-x-4">
                        <input id="terms" name="terms" type="checkbox" class="h-6 w-6 shrink-0 accent-gray-900 dark:bg-gray-900" />
                        <span id="terms-description" class="text-sm text-gray-600 dark:text-gray-400">I agree to the <a class="underline" href="#">Terms and Conditions</a>. Learn about our Privacy Policy and our measures to keep your data safe and secure.</span>
                    </label>
                </div>
                <div class="flex flex-col justify-between space-x-5 sm:flex-row">
                    <button onClick={() => {
                        !loding && HandlerSaveInformation()
                    }} type="submit" class="w-full py-3  font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                        {loding ? 'Chargement...' : 'Continue'}
                        <svg xmlns="http://www.w3.org/2000/svg" class="group-hover:translate-x-2 ml-4 h-5 w-5 transition-transform inline-flex" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                            <path stroke-linecap="round" stroke-linejoin="round" d="M14 5l7 7m0 0l-7 7m7-7H3" />
                        </svg>
                    </button>
                </div>
            </div>
        </motion.div >
    )
}
