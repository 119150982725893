import './style.css'
import { motion } from 'framer-motion';

export default function Loading() {
  const loadingVariants = {
    initial: {
      opacity: 0,
      y: '-100%',
    },
    animate: {
      opacity: 1,
      y: '0%',
      transition: {
        duration: 0.5,
        delay: 0.1,
      },
    },
    exit: {
      opacity: 0,
      y: '-100%',
      scale: 0.2, 
      rotate: 360, 
      transition: {
        duration: 0.6,
      },
    },
  };
    return(
        <motion.div  variants={loadingVariants}
        initial="initial"
        animate="animate"
        exit="exit" className='flex max-h-full min-h-screen bg-gray-100 dark:bg-gray-900'>
        <div class="loading-wave">
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
        <div class="loading-bar"></div>
      </div>
      </motion.div>
      

    )
}