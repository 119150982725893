import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { An_Error, Error_specific, Success } from "../../../../component/alert";
import { DotSpinner } from '@uiball/loaders'
import { Link, useParams } from 'react-router-dom';

export default function Stock() {
    const { id } = useParams()
    const [tempStock, setTempStock] = useState([])
    const [stock, setStock] = useState([])
    const [products, setProducts] = useState([])
    const [tempProducts, setTempProducts] = useState([])
    const [dropdownProduct, setDropdownProduct] = useState(false)
    const [loading, setLoading] = useState(true)
    const [ModalDelete, setModalDelete] = useState(false)
    const [LoadingBtn, setLoadingBtn] = useState(false)
    const [LoadingBtnDel, setLoadingBtnDel] = useState(false)
    const ref = useRef(null)
    const token = localStorage.getItem('token') || sessionStorage.getItem('token')


    const handleClickOutside = (event) => {
        if (ref.current && !ref.current.contains(event.target)) {
            setModalDelete(false); setDropdownProduct(false)
        }
    };
    document.addEventListener('mousedown', handleClickOutside);


    const [selectedProduct, setSelectedProduct] = useState()
    const [quantity, setQuantity] = useState()

    async function HandlerAddNew() {
        if (!selectedProduct || !quantity) {
            return Error_specific('All fields are required.')
        }
        setLoadingBtn(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/delivery/add-new-stock/${id}`, { productID: selectedProduct._id, quantity }, { headers: { 'x-access-token': token } });
            Success(response.data.message); getData(); setSelectedProduct(false); setQuantity(false)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message);
            } else {
                An_Error();
            }
        }
        setLoadingBtn(false);
    }


    async function getData() {
        try {
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/delivery/get-stock/${id}`, { headers: { 'x-access-token': token } });
            const response1 = await axios.get(`${process.env.REACT_APP_SERVER_URL}/delivery/get-products/${id}`, { headers: { 'x-access-token': token } });
            setStock(response.data.Data); setTempStock(response.data.Data); setTempProducts(response1.data.Data); setProducts(response1.data.Data); setLoading(false)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }


    useEffect(() => {
        getData()
    }, [])



    const [updatedQuantity, setUpdatedQuantity] = useState(new Array(stock).fill(false))
    const toggleUpdateQuantity = (index) => {
        setUpdatedQuantity(prevQuantity => {
            const updatedQuantityCopy = [...prevQuantity];
            updatedQuantityCopy[index] = true;
            return updatedQuantityCopy;
        });
    };

    const changeQuantity = (ID, num, index) => {
        const temp = stock.map(product =>
            product.productID === ID ? { ...product, quantity: parseInt(num) } : product
        );
        setStock(temp)
    }

    const UpdateQuantity = async (productId, qnt, index) => {
        try {
            const response = await axios.put(`${process.env.REACT_APP_SERVER_URL}/delivery/update-product-quantity/${id}`, { productId, qnt }, { headers: { 'x-access-token': token } });
            Success(response.data.message);
            getData();
            setUpdatedQuantity(prevQuantity => {
                const updatedQuantityCopy = [...prevQuantity];
                updatedQuantityCopy[index] = false;
                return updatedQuantityCopy;
            })
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
    }

    const DeleteStock = async (e) => {
        setLoadingBtnDel(true)
        try {
            const response = await axios.post(`${process.env.REACT_APP_SERVER_URL}/delivery/delete-stock/${id}`, { id: ModalDelete }, { headers: { 'x-access-token': token } });
            Success(response.data.message); getData(); setModalDelete(false)
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        }
        setLoadingBtnDel(false)
    }

    const handlerSearchProduct = (text) => {
        const lowercaseText = text.toLowerCase().replace(/\s/g, '');
        const filteredData = tempProducts.filter((item) =>
        (item['name']?.toLowerCase().replace(/\s/g, '')?.includes(lowercaseText) ||
            item['price']?.toLowerCase()?.includes(lowercaseText) ||
            item['stock']?.toLowerCase()?.includes(lowercaseText))
        );
        setProducts(filteredData);
    }
    return (
        <main class="h-max pb-16 mb-4 ml-5 mr-5 mt-4">
            <motion.div
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: 20 }}
                transition={{ duration: 0.3 }}
            >
                <div className="">
                    <div className="flex gap-2 ml-2 mb-4 items-center">
                        <Link to="/dashboard/delivery" className="font-bold text-lg text-gray-700/80 dark:text-gray-300">Delivery</Link>
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2.5" stroke="currentColor"
                            class="w-4 h-4 text-gray-700/80 dark:text-gray-300">
                            <path stroke-linecap="round" stroke-linejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                        </svg>
                        <h2 className="font-bold text-lg text-gray-700 dark:text-gray-100 ">Stock Management</h2>
                    </div>

                    <div class="bg-white pb-2 rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
                        <div class="py-3 px-3">
                            <nav class="flex flex-col md:flex-row gap-4 pb-3">
                                <div className="w-full relative">
                                    <label class="mt-3 mb-1 block text-gray-600 dark:text-gray-300 text-sm font-bold float-left">Product</label>
                                    <button onClick={() => setDropdownProduct(!dropdownProduct)} class={`inline-flex border-2 w-full px-4 py-2.5 border-gray-300 form-control rounded-md focus:outline-none focus:border-orange-500 focus:ring-orange-500 font-bold text-gray-600 dark:text-gray-300 dark:bg-gray-900 dark:border-gray-700`}>
                                        <label class="flex w-full  text-md font-semibold select-none  text-sm text-gray-600 dark:text-gray-600">{selectedProduct ? selectedProduct.name : 'Choose a product.'} </label>
                                        <svg xmlns="http://www.w3.org/2000/svg" class={`pointer-events-none ml-auto h-5 text-gray-600 transition ${dropdownProduct && "rotate-180"} `} fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                            <path stroke-linecap="round" stroke-linejoin="round" d="M19 9l-7 7-7-7" />
                                        </svg>
                                    </button>
                                    {dropdownProduct &&
                                        <div ref={ref} class={`absolute right-0 mt-1 text-gray-700 dark:text-gray-300 w-full max-h-[35em] overflow-y-auto z-40 rounded-md shadow-lg bg-white dark:bg-gray-900 border-2 border-gray-300 dark:border-gray-700 p-1 space-y-1`}>
                                            <div class="relative w-full">
                                                <div class="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
                                                    <svg aria-hidden="true" class="w-5 h-5 text-gray-500 dark:text-gray-400" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path></svg>
                                                </div>
                                                <input type="text" onChange={(e) => handlerSearchProduct(e.target.value)} class="bg-gray-50 border border-gray-300 text-gray-900 text-sm focus:ring-blue-500 focus:border-blue-500 block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Search Product Name, Price, Quantity..." required="" />

                                            </div>
                                            {loading ? <div className="h-[calc(70vh_-_10.75rem)] px-4  overflow-y-auto  flex justify-center items-center">
                                                <DotSpinner
                                                    size={80}
                                                    speed={0.9}
                                                    color="gray"
                                                />            </div> :
                                                products.length > 0 ? products.map((product, index) => (
                                                    <div key={index} onClick={() => {
                                                        setSelectedProduct(product)
                                                        setDropdownProduct(false)
                                                    }} class="block text-sm  hover:bg-gray-100 hover:text-gray-700  cursor-pointer rounded-md">
                                                        <div class="p-2 flex hover:bg-gray-100 cursor-pointer border-b border-gray-100 dark:border-gray-700">
                                                            <div class=" w-12"><img loading="lazy"
                                                                src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${product.companyName.replace(/\s/g, '_')}/${product.image}`}
                                                                onError={(e) => e.target.src = "https://cdns.iconmonstr.com/wp-content/releases/preview/2019/240/iconmonstr-product-3.png"}
                                                                alt="img product" /></div>
                                                            <div class="flex-auto text-sm ml-4">
                                                                <div class="text-lg font-bold">{product.name}</div>
                                                                <div class="text-gray-400">Qt: {product.stock}</div>
                                                            </div>
                                                            <div class="flex flex-col w-18 text-xl mt-3 font-meduim items-end">${product.price}</div>
                                                        </div>
                                                    </div>
                                                )) :
                                                    <div className="text-gray-700 col-span-full dark:text-gray-400  animate-pulse">
                                                        <p className="font-normal text-3xl mt-4 mb-4 text-center text-gray-400 dark:text-gray-700">There is no Products to display...</p>
                                                    </div>
                                            }

                                        </div>
                                    }

                                </div>
                                <div className="w-full">
                                    <label class="mt-3 mb-1 block text-gray-600 dark:text-gray-300 text-sm font-bold float-left">Quantity</label>
                                    <input type="number" onChange={(e) => setQuantity(parseInt(e.target.value))} value={quantity} placeholder="Choose the quantity of the product." className={`border-2 w-full  border-gray-300 form-control dark:text-gray-300 rounded-md focus:outline-none focus:border-orange-500 focus:ring-orange-500 dark:bg-gray-900 dark:border-gray-700`} />
                                </div>
                                <button onClick={() => { !LoadingBtn && HandlerAddNew() }} class="inline-block w-full uppercase py-2 px-6 mt-3 md:mt-9 text-center text-md leading-6 text-white font-meduim bg-orange-600 hover:bg-orange-700 border-3 border-indigo-900 shadow rounded-md transition duration-200">
                                    {LoadingBtn ? <div class="flex items-center justify-center ">
                                        <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                            <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                        </svg>
                                        <div class="ml-2"> Processing.... </div>
                                    </div> : "Add New Product"}
                                </button>

                            </nav>
                        </div>

                    </div>

                    <div className="p-6 mt-7 bg-white rounded-2xl dark:bg-gray-800 border border-gray-200 dark:border-gray-700">
                        <div className="overflow-x-auto ">
                            {loading ? <div className="h-[calc(70vh_-_10.75rem)] px-4  overflow-y-auto  flex justify-center items-center">
                                <DotSpinner
                                    size={80}
                                    speed={0.9}
                                    color="gray"
                                />            </div> :
                                stock.length > 0 ?
                                    <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2">
                                        <thead className="font-bold  border-b border-gray-200 dark:border-gray-700 lg:table-header-group">
                                            <motion.tr initial={{ opacity: 0 }}
                                                animate={{ opacity: 1 }}
                                                exit={{ opacity: 0 }}
                                                transition={{ duration: 0.5, delay: 0.1 }} className="text-gray-600 dark:text-gray-100">
                                                <th className={`whitespace-normal py-4 text-sm px-3 text-left`} >
                                                    Product Info
                                                </th>
                                                <th className={`whitespace-normal py-4 text-sm px-3 text-left`} >
                                                    Product Stock
                                                </th>
                                                <th className={`whitespace-normal py-4 text-sm px-3 text-left`}>
                                                    Quantity
                                                </th>
                                                <th className=""></th>
                                            </motion.tr>
                                        </thead>
                                        <tbody class="">
                                            {stock.map((item, index) => {
                                                const oldQuantity = tempStock.find((i) => i.productID === item.productID)?.quantity
                                                return (
                                                    <motion.tr initial={{ opacity: 0, x: -50 }}
                                                        animate={{ opacity: 1, x: 0 }}
                                                        exit={{ opacity: 0, x: -50 }}
                                                        transition={{ duration: 0.5, delay: index * 0.3 }} class="w-full" key={index}>
                                                        <td class="whitespace-no-wrap  px-3 py-4 text-left text-sm text-gray-600 dark:text-gray-400 ">
                                                            <div class="flex items-center">
                                                                <img loading="lazy" class="mr-2 h-10 w-10 rounded-md object-cover" src={`${process.env.REACT_APP_SERVER_IMAGE_URL}/images/${item.companyName.replace(/\s/g, '_')}/${item.image}`}
                                                                    onError={(e) => e.target.src = "https://cdns.iconmonstr.com/wp-content/releases/preview/2019/240/iconmonstr-product-3.png"}
                                                                    alt="profile" />
                                                                <div className="w-[10em]">
                                                                    <h4 class="text-base font-bold text-gray-900  dark:text-gray-100">{item.ProductName}</h4>
                                                                    <span class="block text-xs font-normal text-gray-500  dark:text-gray-400">${item.ProductPrice}</span>

                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-700 dark:text-gray-200 px-3 ">
                                                            {item.ProductStock} pieces left in stock
                                                        </td>
                                                        <td class="whitespace-no-wrap py-4 text-left text-sm text-gray-600 dark:text-gray-400 px-3">
                                                            <div class="flex flex-row h-10 rounded-lg relative bg-transparent mt-1">
                                                                <div className="bg-gray-300 dark:bg-gray-700 text-gray-700 text-center font-normal text-md dark:text-gray-300 h-full w-28 rounded mr-4 flex items-center justify-center">
                                                                    {oldQuantity}
                                                                </div>
                                                                {!updatedQuantity[index] && <div onClick={() => toggleUpdateQuantity(index)} className="text-gray-500 text-center font-semibold text-md dark:text-gray-400 h-full rounded flex items-center justify-center cursor-pointer hover:scale-90 hover:text-orange-500 transition duration-300">
                                                                    <svg id="Layer_1" data-name="Layer 1" fill="currentColor" className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 122.88 121.51"><title>edit</title><path d="M28.66,1.64H58.88L44.46,16.71H28.66a13.52,13.52,0,0,0-9.59,4l0,0a13.52,13.52,0,0,0-4,9.59v76.14H91.21a13.5,13.5,0,0,0,9.59-4l0,0a13.5,13.5,0,0,0,4-9.59V77.3l15.07-15.74V92.85a28.6,28.6,0,0,1-8.41,20.22l0,.05a28.58,28.58,0,0,1-20.2,8.39H11.5a11.47,11.47,0,0,1-8.1-3.37l0,0A11.52,11.52,0,0,1,0,110V30.3A28.58,28.58,0,0,1,8.41,10.09L8.46,10a28.58,28.58,0,0,1,20.2-8.4ZM73,76.47l-29.42,6,4.25-31.31L73,76.47ZM57.13,41.68,96.3.91A2.74,2.74,0,0,1,99.69.38l22.48,21.76a2.39,2.39,0,0,1-.19,3.57L82.28,67,57.13,41.68Z" /></svg>
                                                                </div>}
                                                                {updatedQuantity[index] && <>
                                                                    <button onClick={() => { item.quantity > 0 && changeQuantity(item.productID, (item.quantity - 1)) }} title="decrement"
                                                                        class={`bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-100  hover:bg-gray-400 h-full w-20 rounded-l ${item.quantity === 0 ? 'cursor-not-allowed' : 'cursor-pointer'}  focus:outline-none`}>
                                                                        <span class="m-auto text-2xl font-thin">−</span>
                                                                    </button>
                                                                    <input type="number" value={item.quantity} onChange={(e) => changeQuantity(item.productID, !e.target.value ? 0 : e.target.value)} class="outline-none border-none focus:outline-none focus:ring-0 [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none text-center w-20 bg-gray-300 dark:bg-gray-700  font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 dark:text-gray-300  outline-none" />
                                                                    <button onClick={() => changeQuantity(item.productID, (parseInt(item.quantity) + 1))} title="increment" class="bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer focus:outline-none">
                                                                        <span class="m-auto text-2xl font-thin">+</span>
                                                                    </button>
                                                                    <div className="flex flex-row w-48">
                                                                        <button title="cancel" onClick={() => {
                                                                            setUpdatedQuantity(prevQuantity => {
                                                                                const updatedQuantityCopy = [...prevQuantity];
                                                                                updatedQuantityCopy[index] = false;
                                                                                return updatedQuantityCopy;
                                                                            })
                                                                            getData()
                                                                        }} class="bg-gray-300 ml-12 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer flex items-center focus:outline-none">
                                                                            <span class="m-auto text-2xl font-thin">
                                                                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                                    <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12" />
                                                                                </svg>

                                                                            </span>
                                                                        </button>
                                                                        <button onClick={async () => {
                                                                            await UpdateQuantity(item.productID, item.quantity, index)
                                                                        }} title="double click to save" class="bg-gray-300 dark:bg-gray-700 text-gray-600 dark:text-gray-400 hover:text-gray-200 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer flex items-center focus:outline-none">
                                                                            <span class="m-auto text-2xl font-thin"><svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="1.5" stroke="currentColor" class="w-5 h-5">
                                                                                <path stroke-linecap="round" stroke-linejoin="round" d="M4.5 12.75l6 6 9-13.5" />
                                                                            </svg>
                                                                            </span>
                                                                        </button>
                                                                    </div>
                                                                </>}
                                                            </div>
                                                        </td>

                                                        <td className='flex py-4 px-9 gap-4 float-right'>
                                                            <button onClick={() => setModalDelete(item.productID)} class="focus:ring-2 focus:ring-offset-2 focus:ring-red-300 text-sm leading-none text-gray-600 py-3 px-5 bg-gray-100 dark:bg-gray-900 dark:text-gray-100  rounded hover:bg-gray-200 focus:outline-none">Delete</button>
                                                        </td>
                                                    </motion.tr>
                                                )
                                            })}


                                        </tbody>
                                    </table>
                                    :
                                    <div className="text-gray-700 col-span-full dark:text-gray-400  animate-pulse">
                                        <p className="font-normal text-3xl mt-4 mb-4 text-center text-gray-400 dark:text-gray-700">No stock available for display...</p>
                                    </div>
                            }



                        </div>

                    </div>


                </div>

                <AnimatePresence>
                    {ModalDelete &&
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            exit={{ opacity: 0 }}
                            transition={{ duration: 0.3 }} className="fixed inset-0 z-30 bg-black bg-opacity-50"
                        >
                            <motion.div initial={{ opacity: 0, scale: 0.8 }}
                                animate={{ opacity: 1, scale: 1 }}
                                exit={{ opacity: 0, scale: 0.8 }}
                                transition={{ duration: 0.2 }} class="flex items-center justify-center mx-auto min-h-screen  text-center">

                                <div ref={ref} class="m-10 flex max-w-lg flex-col items-center border px-8 py-10 text-gray-800 sm:w-[32rem] overflow-hidden rounded-2xl bg-white dark:bg-gray-900 dark:border-gray-700 sm:max-w-lg ">
                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-16 w-16 rounded-xl bg-red-50 p-2 text-red-500" fill="none" viewBox="0 0 24 24" stroke="currentColor" stroke-width="2">
                                        <path stroke-linecap="round" stroke-linejoin="round" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16" />
                                    </svg>
                                    <p class="mt-4 text-center text-xl font-bold dark:text-gray-200">Deleting Product</p>
                                    <p class="mt-2 text-center text-lg dark:text-gray-400">Are you sure you want to permanently remove this product with the ID <span class="truncate font-bold">`{ModalDelete}`</span> from the inventory?</p>
                                    <div class="mt-8 flex flex-col justify-center space-y-3 sm:flex-row sm:space-x-3 sm:space-y-0">
                                        <button class="whitespace-nowrap rounded-md bg-red-500 px-4 py-3 font-medium text-white" onClick={() => { !LoadingBtnDel && DeleteStock() }}>
                                            {LoadingBtnDel ? <div class="flex items-center justify-center ">
                                                <svg class="h-5 w-5 animate-spin" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                    <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
                                                    <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                </svg>
                                                <div class="ml-2"> Processing.... </div>
                                            </div> : "Yes, delete this Product"}
                                        </button>
                                        {!LoadingBtnDel && <button class="whitespace-nowrap rounded-md bg-gray-200 px-4 py-3 font-medium" onClick={() => setModalDelete(false)}>Cancel, keep this Product</button>}
                                    </div>
                                </div>

                            </motion.div>
                        </motion.div>
                    }
                </AnimatePresence>
            </motion.div>
        </main>
    )
}