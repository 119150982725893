import { saveAs } from 'file-saver';


export const exportToJSON = (data) => {
    const UpdatedData = data.map(({ _id, ...rest }) => rest);
    const jsonData = JSON.stringify(data, null, 2); 
    const blob = new Blob([jsonData], { type: 'application/json;charset=utf-8' });
  
    const now = new Date();
    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    const filename = `data_${day}_${month}_${year}.json`;
  
    saveAs(blob, filename);
};