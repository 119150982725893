import CryptoJS from 'crypto-js';

// Encryption and decryption functions
const encryptData = (data, secretKey) => {
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey);
    return ciphertext.toString();
};

// Function to securely store data in localStorage
export const setSecureLocalStorage = (key, data, secretKey) => {
    try {
        const encryptedData = encryptData(data, secretKey);
        localStorage.setItem(key, encryptedData);
    } catch (error) {
        console.error('Error storing data in localStorage:', error);
    }
};