import React, { useState } from 'react'
import { motion } from 'framer-motion'
import { An_Error } from '../../../component/alert'

export default function Access({ CheckAccses, setPages }) {
    const [accessKey, setAccessKey] = useState()
    const [lodingBtn, setLodingBtn] = useState(false)

    async function handlerCheckCode() {
        try {
            setLodingBtn(true)
            const check = await CheckAccses(accessKey, "access")
            if (check) {
                setPages(prevPages => ({
                    ...prevPages,
                    accessKey: accessKey,
                    activeTab: 2
                }))
            }
        } catch (error) {
            An_Error()
        } finally {
            setLodingBtn(false)
        }

    }

    return (
        <motion.div initial={{ x: -600, opacity: 0 }}
            animate={{ x: 0, opacity: 1 }}
            exit={{ x: 600, opacity: 0 }} class="flex w-full flex-col">
            <div class="w-full mx-auto my-auto flex flex-col justify-center px-4">

                <img src="/images/securityVector.png" className="w-3/4 md:w-2/6 mx-auto -mt-7" />
                <h1 className="text-2xl text-center mx-auto font-bold text-gray-800 dark:text-gray-100">Token Authentication</h1>
                <p class="md:w-2/4 text-lg text-center mx-auto font-normal text-gray-700 dark:text-gray-300">
                    Please enter the access code to proceed with accessing the setup. This code ensures secure entry and configuration.
                </p>

                <div class="flex flex-col">
                    <div className="mt-4 flex flex-col gap-1 md:w-2/4 mx-auto">

                        <div class="focus-within:border-b-gray-500 relative flex overflow-hidden border-b-2 transition">
                            <input type="text" onChange={(e) => setAccessKey(e.target.value)} class="w-full flex-1 appearance-none border-gray-300 bg-white px-4 py-2 text-base text-gray-700 dark:text-gray-200 dark:bg-gray-900 placeholder-gray-400 focus:outline-none" placeholder="xxxxx-xxxxx-xxxxx-xxxxx-xxxxx" />
                            <button onClick={() => { !lodingBtn && handlerCheckCode() }} type="submit" class="px-6 py-3 font-medium tracking-widest text-white uppercase bg-black shadow-lg focus:outline-none hover:bg-gray-900 hover:shadow-none">
                                {lodingBtn ? 'Chargement...' : 'Verfie Code'}
                            </button>
                        </div>
                        <a href="mailto:hizmi.abdelhakim@gmail.com" className="mt-1 text-blue-500 underline text-sm text-left font-bold cursor-pointer">Request Access?</a>
                    </div>


                </div>
            </div>
        </motion.div>
    )
}
