import toast  from 'react-hot-toast';
import { Howl } from 'howler';

const successSound = new Howl({
    src: ['./audio/success.mp3'],
    volume: 0.5,
});
const errorSound = new Howl({
    src: ['./audio/error.mp3'],
    volume: 0.4,
});
export function Success(message) {
    successSound.play();
    toast.success(message, {
        position: "bottom-center",
        duration: 5000,
      });
}

export function An_Error() {
    errorSound.play()
    toast.error('something wrong, try again..',{
        position: "bottom-center",
        duration: 5000, 
        style: {
            background: "#ff5242", // Customize the background color for error toast
            color: "#fff", // Customize the text color for error toast
          }
    });
}
export function Error_specific(message) {
    errorSound.play()
    toast.error(message,
        {
            position: "bottom-center",
            duration: 5000, 
        });
}