import jsPDF from 'jspdf';
import 'jspdf-autotable';

export const exportToPDF = (headers, Data) => {
    const doc = new jsPDF('l', 'pt'); 

    const data = [];
    Data.map((item) => {
        const rowData = [];
        headers.map((key) => {
            rowData.push(item[key] || '')
        })
        data.push(rowData)
    })

    const now = new Date()
    const pageContent = function (data) {
        // HEADER
        doc.setFont('helvetica'); // Set font here
        doc.setFontSize(10);
        doc.setTextColor(40);
        doc.text(`The data was exported on ${now}`, data.settings.margin.left, 50);
    }    
    doc.autoTable({
        head: [headers],
        body: data,
        didDrawPage: pageContent,
        margin: { top: 60 },
        tableWidth: 'full',
    });

    const day = now.getDate().toString().padStart(2, '0');
    const month = (now.getMonth() + 1).toString().padStart(2, '0');
    const year = now.getFullYear().toString();
    const filename = `data_${day}_${month}_${year}`
    doc.save(`${filename}.pdf`);
};
