import React, { useEffect, useState } from 'react'
import { An_Error, Error_specific, Success } from "../../../../component/alert";
import axios from 'axios';
import { DotSpinner } from '@uiball/loaders'
import InfiniteScroll from "react-infinite-scroll-component";
import { motion } from "framer-motion";

export default function GetLinkedOrders({ id, token }) {
    const [Loading, setLoading] = useState(false)
    const [orders, setOrders] = useState([])
    const [page, setPage] = useState(1);
    const [hasMore, setHasMore] = useState(false);

    const getData = async () => {
        try {
            setLoading(true)
            const response = await axios.get(`${process.env.REACT_APP_SERVER_URL}/delivery/payment/get-transaction-orders/${id}/${page}`,
                { headers: { 'x-access-token': token } });
            const newData = response.data.Data;
            if (newData.length === 0) {
                setHasMore(false);
            } else {
                setOrders(prevOrders => [...prevOrders, ...newData]);
                setPage((prev) => prev + 1);
                setHasMore(true)
            }
        } catch (error) {
            if (error.response?.status === 401) {
                Error_specific(error.response.data.message)
            } else {
                An_Error()
            }
        } finally {
            setLoading(false)
        }
    }

    useEffect(() => {
        getData()
    }, [])
    return (
        <div className="py-8 overflow-auto max-h-[60vh]">
            <InfiniteScroll
                dataLength={orders.length}
                next={() => getData()}
                hasMore={hasMore}
                loader={Loading && <div className="py-5 px-4 flex justify-center items-center">
                    <DotSpinner
                        size={30}
                        speed={0.9}
                        color="gray"
                    />
                </div>}
                scrollThreshold={0.8}
            >
                {orders.length > 0 ?
                    <table className="min-w-full border-collapse border-spacing-y-2 border-spacing-x-2 ">
                        <thead className="font-bold bg-gray-100 dark:bg-gray-700 border border-gray-200 dark:border-gray-600 lg:table-header-group">
                            <motion.tr initial={{ opacity: 0, x: -50 }}
                                animate={{ opacity: 1, x: 0 }}
                                exit={{ opacity: 0, x: -50 }}
                                transition={{ duration: 0.5, delay: 0.1 }} className="text-gray-600 dark:text-gray-100">
                                {Array.from(new Set(orders.flatMap(item => Object.keys(item)))).map((key, subIndex) => {
                                    if (key !== '_id') {
                                        return (
                                            <th className={`px-4 py-2 text-sm text-left whitespace-nowrap `} key={subIndex}>
                                                {key}
                                            </th>
                                        )
                                    }
                                })}
                            </motion.tr>
                        </thead>
                        <tbody className="text-sm  border border-gray-200 dark:border-gray-600">
                            {orders.length > 0 && orders.map((item, index) => {
                                return (
                                    <motion.tr initial={{ opacity: 0, x: -50 }}
                                        animate={{ opacity: 1, x: 0 }}
                                        exit={{ opacity: 0, x: -50 }}
                                        transition={{ duration: 0.5, delay: 0.5 }} className={`text-gray-600 font-meduim dark:text-gray-200 ${index !== orders.length - 1 && 'border-b border-gray-200 dark:border-gray-600'} `} key={index}>
                                        {Array.from(new Set(orders.flatMap(item => Object.keys(item)))).map((key, subIndex) => {
                                            if (key !== '_id') {
                                                const value = item[key] || '';
                                                return (
                                                    <td className={`px-4 py-4 text-sm`} key={subIndex}>
                                                        {value}
                                                    </td>
                                                );
                                            }
                                            return null
                                        })}
                                    </motion.tr>
                                )
                            })}
                        </tbody>
                    </table>
                    : !Loading &&
                    <div className="py-5 animate-pulse text-gray-400 dark:text-gray-700">
                        <p className="font-normal text-lg text-center">Currently, there are no orders available for display...</p>
                    </div>
                }
            </InfiniteScroll>
        </div>
    )
}
